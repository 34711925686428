<template>
  <div class="bodyFile">
    <!-- notFiles -->
    <div  v-if="!dataModel?.length" class="eva-preview__label eva-foreground-2"> 
      {{ $eva.$t(`$t.${$options.name}.title.notFile`) }}
    </div>
    <!-- files -->
    <div v-if="label && dataModel?.length > 0" class="label eva-textbox__input"> {{ label }} </div>
    <div v-for="(item, index) in dataModel" :key="index" class="itemFile" @click="fileDownload(item)">

      <div class="blockItemName">
        <div class="icons">
          <span class="mdi" :class="getIcon(item)" v-tooltip="item?.vTooltip"></span>
        </div>
        <div class="nameFile"> {{ item.name }} </div>
      </div>

      <div class="sizeFile"> {{ geSizeFile(item.size) }} </div>
    </div>

    <!-- loader -->
    <div class="loader" v-if="loader">
      <div class="progress"></div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'eva-file-viewer',

  props: {
    label: {
      type: String,
      default: ''
    },

    dataModel: {
      type: Array,
      default: () => { },
    },

  },

  data() {
    return {
      loader: false,
    }
  },

  computed: {
    getIconFile() {
      return 'mdi-file-document'
    }

  },

  methods: {

    getIcon(item) {
      const extensions = {
        imageExtensions: ['jpg', 'jpeg', 'png', 'gif', 'bmp'],
        docExtensions: ['docx', 'doc'],
        tableExtensions: ['xlsx', 'xls', 'csv'],
        videoExtensions: ['mp4', 'avi', 'mov'],
        pdf: ['pdf']
      };

      const extension = item.name.split('.').pop().toLowerCase();

      for (const item in extensions) {
        if (extensions[item].includes(extension)) {
          return getIconsExtension(item);
        }
      }

      function getIconsExtension(extensionFiend) {
        switch (extensionFiend) {
          case 'imageExtensions':
            item.vTooltip = 'Картинка';
            return 'mdi-file-image'
          case 'docExtensions':
            item.vTooltip = 'Документ';
            return 'mdi-file-word'
          case 'tableExtensions':
            item.vTooltip = 'Таблица';
            return 'mdi-file-excel'
          case 'videoExtensions':
            item.vTooltip = 'Видео';
            return 'mdi-file-video'
          case 'pdf':
            item.vTooltip = 'PDF';
            return 'mdi-file-pdf-box'
        }
      }

      return 'mdi-file-document'
    },

    geSizeFile(bytes) {
      const mb = 1024 * 1024,
        gb = 1024 * 1024 * 1024;

      switch (true) {
        case (bytes < 1024):
          return `${bytes} байт`;
        case ((bytes > 1024) && (bytes < mb)):
          return `${(bytes / 1024).toFixed(2)} Кб`;
        case ((bytes > mb) && (bytes < gb)):
          return `${(bytes / mb).toFixed(2)} Мб`;
        case (bytes >= gb):
          return `${(bytes / gb).toFixed(2)} Гб`;
      }
    },

    async fileDownload(item) {
      if (!this.loader) {
        try {
          this.loader = true;
          const { data } = await this.$eva.$http.getBlob(`/api/v1/fileservice/file/${item.uploaded_file_id}`, 'GET'),
            file = data,
            link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = item.name;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          link.remove();

        } catch (err) {
          console.error('err, ', err);
          if (err?.response && err.response?.status === 403) {
            this.$eva.$boxes.notifyError('Сессия закрылась, обновите страницу или авторизируйтесь и попробуйте еще раз.');
          } else {
            this.$eva.$boxes.notifyError(`Произошла ошибка, попробуйте еще раз. ${err.response?.data?.usr_msg}`);
          }
        } finally {
          this.loader = false;
        }
      }

    }
  }


}
</script>

<style lang="less" scoped>
.bodyFile {
  width: 100%;

  .label {
    margin-top: 25px;
  }

  .itemFile {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 5px 0;
    color: #ACBBCB;
    cursor: pointer;

    .blockItemName {
      display: flex;
      align-items: center;
      width: calc(100% - 64px);

      .icons {
        font-size: 20px;
        width: 20px;
      }

      .nameFile {
        margin-left: 5px;
        width: calc(100% - 30px);
        word-wrap: break-word;
      }
    }

    .sizeFile {
      display: flex;
      align-items: center;
      color: #7B8B9D;
      white-space: nowrap;
      width: 65px;
    }
  }
}

.loader {
  width: 100%;
  height: 2px;
  /* Серый */
  position: relative;
  overflow: hidden;
}

.progress {
  width: 0;
  height: 100%;
  background-color: #0094FF;
  /* Синий */
  animation: progress 2s linear infinite;
}

@keyframes progress {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.loaderRW {
  position: absolute;
  background-color: #3e4c5db3;
  width: 100%;
  height: 100%;
  z-index: 10;
}
</style>

<locale lang="ru">
  {
    title:{
      notFile: 'Файлы отсутствуют'
    }
    
  }
</locale>