import { defineStore } from "pinia";

export const regulationsStore = defineStore({
    id: 'regulations',
    state: () => ({
        checkRegulation: null,
        stateEditForm: false
    }),
    actions: {
        setCheckRegulation(object) {
            this.checkRegulation = object;
        },
        setStateEditForm(state) {
            this.stateEditForm = state;
        },

        clear() {
            this.checkRegulation = null;
        }
    }
})