<template>
  <eva-input
    :value="!!currentValue"
    :label="label"
    :sublabel="sublabel"
    :sublabelColor="sublabelColor"
    :placeholder="isFocus ? '' : placeholder"
    :maxlength="maxlength"
    :icon="icon"
    :clearable="clearable"
    :readonly="readonly"
    :preview="preview"
    :depth="depth"
    :error="error"
    @clear="currentValue = ''"
    class="eva-textarea"
  >
    <template v-slot:header>
      <slot name="header"></slot>
    </template>

    <eva-intersect @enter="onEnter"/>
    
    <textarea
      ref="input"
      :value="currentValue != null ? currentValue : ''"
      :readonly="readonly || preview"
      :maxlength="maxlength"
      class="eva-textarea__field"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
    ></textarea>

  </eva-input>
</template>

<script>
import EvaInputMixin from "./EvaInputMixin";

export default {
  name: 'eva-textarea',

  mixins: [
    EvaInputMixin
  ],

  props: {
    autoHeight: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isFocus: false
    }
  },

  watch: {
    currentValue() {
      this.invalidateHeight();
    },
    clearable() {
      this.invalidateHeight();
    },
    readonly() {
      this.invalidateHeight();
    },
    preview() {
      this.invalidateHeight();
    }
  },

  methods: {
    onIconClick(e) {
      this.$emit('icon-click', e);
    },
    onFocus() {
      this.isFocus = true;
    },
    onBlur() {
      this.isFocus = false;
    },
    onInput(e) {
      this.currentValue = e.target.value;
    },
    invalidateHeight() {
      this.$nextTick(() => {
        let area = this.$refs.input;
        area.style.height = '1px';
        area.style.overflow = 'hidden';
        this.$nextTick(() => {
          area.style.height = (area.scrollHeight < 24 ? 24 : area.scrollHeight) + 'px';
        });
      });
    },
    onEnter() {
      this.invalidateHeight();
    }
  },
  mounted() {
    this.$nextTick(() => this.invalidateHeight());
  }
}
</script>

<style lang="less">
.eva-textarea {
  height: unset;
  .eva-textbox__input {
    height: unset;
    overflow: hidden;
    display: flex;
  }
}
</style>
