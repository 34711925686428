
    import localesHash from '@eva/client/plugins/locales/LocalesHash';
    export default function (Component) {
      localesHash.push({ 
        lang: 'ru', 
        data: {
          [Component.options.name]: 
{
  commands: {
    zoomIn: {
      header: 'Увеличить масштаб'
    },
    zoomOut: {
      header: 'Уменьшить масштаб'
    },
    fitToScreen: {
      header: 'Показать всю карту'
    },
    goHome: {
      header: 'Перейти к закрепленному положению карты'
    },
    toggleFullscreen: {
      exit: 'Выйти из полноэкранного режима',
      enter: 'Войти в полноэкранный режим'
    }
  }
}

        } 
      });
    }