<template>
  <div
      class="eva-media-text"
      @click="$emit('click', $event)"
  >
    <eva-icon v-if="icon" :icon="icon" :size="size"/>
    <eva-image v-else :src="image" :size="size"/>
    <eva-text v-if="text" :text="text" v-tooltip="text" clipped/>
  </div>
</template>

<script>
export default {
  name: 'eva-media-text',

  props: {
    icon: {
      type: String
    },
    image: {
      type: String
    },
    text: {
      type: String
    },
    size: {
      type: Number
    }
  }
}
</script>

<style lang="less">
.eva-media-text {
  display: flex;
  gap: @eva-padding;
  flex: 0 0 auto;
  .eva-icon {
    width: 32px;
    height: 32px;
    display: flex;
    margin: auto 0;
    flex: 0 0 auto;
  }
  .eva-image {
    width: 32px;
    height: 32px;
    display: flex;
    margin: auto 0;
    flex: 0 0 auto;
  }
}
</style>
