<template>
  <eva-popup-input
      v-model="modelValue"
      :label="fieldLabel"
      :error="fieldErrors"
      :title="fieldTitle"
      :readonly="readOnly"
      :preview="preview"
      :depth="depth"
      :icon="(readOnly || preview) ? '' : 'mdi-dots-horizontal'"
      @icon-click="showSettings"
      @click="showSettings"
      class="eva-form-field"
      :style="styles">

    <eva-form
      v-if="currentSettings"
      :settings="currentSettings"
      :model="currentModel"
      class="eva-background-3"
    />
    <div v-else>

    </div>

  </eva-popup-input>
</template>

<script>
import MetadataDesignerItemComponent
  from "../../../../../../../core/client/pages/admin/widgets/metadadas/designer/MetadataDesignerItemComponent";

export default {
  name: 'eva-form-field',

  data() {
    return {
      opened: false,
      currentSettings: null,
      currentModel: {}
    }
  },

  computed: {
    styles() {
      let result = this.paddingStyles;
      if (this.settings && this.settings.height) {
        result.height = this.settings.height;
      }
      return result;
    }
  },

  methods: {
    createCurrentSettings(value) {
      this.currentSettings = null;
      this.currentModel = {};
      if (value) {
        this.$nextTick(() => {
          this.currentSettings = this.$eva.$tools.clone(value.settings);
          if (value.locales) {
            this.$eva.$locales.register('ru', value.locales.ru);
          }
        });
      }
    },
    async showSettings() {
      if (this.readOnly) {
        return;
      }
      const model = {
        id: 'CreatedId',
        params: this.$eva.$tools.clone(this.modelValue)
      };
      if (!model.params.fields) {
        model.params.fields = {};
      }
      model.fields = model.params.fields;
      let columns = this.modelValue.settings && this.modelValue.settings.columns || [];
      for (let i = 0; i < columns.length; i++) {
        if (!model.fields[columns[i].name]) {
          model.fields[columns[i].name] = {};
        }
        if (!model.fields[columns[i].name].attrs_type) {
          model.fields[columns[i].name].attrs_type = this.$eva.$metadatas.getFieldDbType({
            type: columns[i].type || 'text'
          });
        }
      }
      this.opened = true;
      await this.$eva.$boxes.show({
        type: 'full-screen',
        header: 'Настройка формы',
        component: MetadataDesignerItemComponent,
        componentProps: {
          model,
          short: true
        },
        commands: {
          ok: () => {
            model.params.fields = model.fields;
            this.modelValue = model.params;
            this.createCurrentSettings(this.modelValue);
          },
          cancel: true
        }
      });
      this.opened = false;
    }
  },

  mounted() {
    this.createCurrentSettings(this.modelValue);
  }
}
</script>

<style lang="less">
.eva-form-field {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  > .eva-textbox__inner {
    > .eva-textbox__input {
      height: unset;
      overflow: auto;
    }
  }

}
</style>
