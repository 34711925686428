<template>
  <div class="eva-number-field-legacy">
    <eva-field-wrapper :title="fieldTitle">
      <v-text-field
          v-model="currentValue"
          :label="fieldLabel"
          :error="hasErrors"
          :messages="fieldErrors"
          :disabled="readOnly"
          clearable
          outlined
          dense
          type="number"
      />
    </eva-field-wrapper>
  </div>
</template>

<script>
export default {
  name: 'eva-number-field',

  data() {
    return {
      currentValue: null
    }
  },

  watch: {
    modelValue: {
      handler(value) {
        this.currentValue = value;
      },
      immediate: true
    },
    currentValue(value) {
      if (value != null) {
        if (typeof value != 'number') {
          value = value.toString();
          value = parseInt(value);
        }
      }
      this.modelValue = value;
    }
  }
}
</script>

<style lang="less">
.eva-number-field-legacy {
  .v-input__slot {
    background-color: #E5F1FF!important;
  }
}
</style>
