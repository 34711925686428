export default {
  name: 'eva-intersect',

  template: '<span style="min-height: 1px; display: flex"></span>',

  props: {
    threshold: {
      required: false,
      default: () => 0
    },
    root: {
      type: typeof HTMLElement !== 'undefined' ? HTMLElement : Object,
      required: false,
      default: () => null
    },
    rootMargin: {
      type: String,
      required: false,
      default: () => '0px 0px 0px 0px'
    }
  },

  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        this.$emit('leave', [entries[0]]);
      } else {
        this.$emit('enter', [entries[0]]);
      }
      this.$emit('change', [entries[0]]);
    }, {
      threshold: this.threshold,
      root: this.root,
      rootMargin: this.rootMargin
    });
    this.$nextTick(() => this.observer.observe(this.$el));
  },

  destroyed() {
    this.$emit('destroyed');
    this.observer.disconnect();
  }
}
