export default {
  props: {
    item: {}
  },
  template: `
      <eva-media-text
        :image="image"
        :text="text"
        :size="72"
      />`,
  computed: {
    image() {
      return (this.item && this.item.id)
        ? `/api/v1/fileservice/file/${this.item.id}`
        : null;
    },
    text() {
      if (this.item && this.item.name) {
        let text = this.item.name;
        if (this.item.extension) {
          text += this.item.extension;
        }
        return text;
      } else {
        return null;
      }
    }
  }
};
