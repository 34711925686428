import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import moment from "moment";

class LogsEvaPlugin extends EvaPlugin {
  constructor(app) {
    super(app);
  }

  error(component, message, error) {
    this.logInternal('error', 'error', component, message);
    if (error) {
      if (error.response && error.response.data) {
        console.error(error.response.data);
      }
      console.error(error);
      if (error.stackTrace) {
        console.log(error.stackTrace);
      }
    }
  }

  info(component, message) {
    this.logInternal('info', 'log', component, message);
  }

  warn(component, message) {
    this.logInternal('warn', 'warn', component, message);
  }

  logInternal(type, method, component, message) {
    console[method](`${moment().format('DD.MM.YYYY HH:mm:ss:SSS')} [${type}] : [${component}] : ${message}`);
  }

  json(...args) {
    for (let i = 0; i < args.length; i++) {
      console.log(JSON.stringify(args[i], null, 4));
    }
  }

  fields(obj, ...names) {
    for (let i = 0; i < names.length; i++) {
      console.log(`${names[i]} = ${JSON.stringify(obj[names[i]], null, 4)}`);
    }
  }
}

export default LogsEvaPlugin;
