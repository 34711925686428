class EvaAppError extends Error {
  constructor(app, code, args) {
    super();
    this.app = app;
    this.code = code;
    this.args = args;
  }

  toString() {
    return this.app.$t(this.code, this.args);
  }
}

export default EvaAppError;
