<template>
  <div class="eva-json-field-legacy">
    <eva-field-wrapper :title="fieldTitle">
      <v-select
        :label="fieldLabel"
        v-model="noopModel"
        :items="noopItems"
        readonly
        dense
        :error="jsonHasErrors"
        :messages="jsonFieldErrors"
        :disabled="readOnly"
        outlined
        ref="current"
      >
        <template v-slot:selection="">
          <vue-json-editor
            ref="json_editor"
            :value="modelValue"
            mode="code"
            :modes="['code']"
            :show-btns="false"
            lang="ru"
            :exapndedOnStart="true"
            @json-change="onJsonChanged"
            @has-error="onJsonError"
          />
        </template>
      </v-select>
    </eva-field-wrapper>
  </div>
</template>

<script>
import vueJsonEditor from "vue-json-editor";

export default {
  name: 'eva-json-field',

  components: {
    vueJsonEditor
  },

  props: {

  },

  data() {
    return {
      noopModel: 1,
      noopItems: [1],
      jsonError: null
    }
  },

  computed: {
    jsonHasErrors() {
      return this.hasErrors || !!this.jsonError;
    },
    jsonFieldErrors() {
      return [this.jsonError, this.fieldErrors]
        .filter((e) => !!e)
        .join(' ');
    }
  },

  methods: {
    onJsonChanged(value) {
      this.settings.customError = false;
      this.jsonError = null;
      this.modelValue = value;
    },

    onJsonError(e) {
      this.settings.customError = true;
      this.jsonError = e.message;
    }
  }
}
</script>

<style lang="less">
.eva-json-field-legacy {
  width: 100%;

  .v-input {
    min-height: 200px;
    background-color: #E5F1FF;
  }
  .v-input__slot {
    background-color: #E5F1FF!important;
  }

  .v-select__selections {
    padding: 8px 0 0!important;
    & > div {
      margin-left: -10px;
      width: calc(100% + 10px);
      height: 100%;
      min-height: 200px;
    }
    input {
      display: none;
    }
  }

  .v-input,
  .v-input__control,
  .v-input__slot,
  .v-select__selections {
    height: 100%!important;
  }

  .v-input__control {
    flex-wrap: nowrap!important;
  }

  .v-input__slot {
    padding-right: 2px!important;
    padding-bottom: 2px!important;
    fieldset {
      background-color: #F5F5F5!important;
    }
  }

  .v-input__append-inner {
    display: none;
  }
  .jsoneditor-menu {
    display: none;
  }
  .jsoneditor {
    border: none;
  }
  .ace_scroller {
    background-color: #F5F5F5!important;
  }
  .jsoneditor-vue {
    height: 100%;
  }
  .jsoneditor-tree {
    min-height: 100%;
  }
  .jsoneditor-outer {
    min-height:100%;
    margin: 0;
    padding: 0;
  }
}
</style>
