<template>
  <div class="eva-video" ref="evaVideo">
    <div class="eva-video-teleport eva-background-2" ref="teleport">
      <eva-video-archive
        v-if="showArchive"
        :settings="settings"
        :event="event"
        :isFullscreen="isFullscreen"
        :showElements="showElements"
        @openOnline="toggleComponent"
        @playerClick="toggleElements"
        @toggleFullscreen="onToggleFullscreen"
      />
      <eva-video-online
        v-else
        :settings="settings"
        :event="event"
        :hideArchive="hideArchive"
        :isFullscreen="isFullscreen"
        @openArchive="onOpenArchive"
        @toggleFullscreen="onToggleFullscreen"
        @removeItemFromPanel="onRemoveItemFromPanel"
      />
    </div>
  </div>
</template>

<script>
import EvaVideoArchive from "./controls/EvaVideoArchive.vue";
import EvaVideoArchiveDialog from "./controls/EvaVideoArchiveDialog.vue";
import EvaVideoOnline from "./controls/EvaVideoOnline.vue";
import EvaFullscreenController from "./fullscreen/EvaFullscreenController";

export default {
  name: "eva-video",
  components: {
    EvaVideoArchive,
    EvaVideoOnline,
  },
  props: {
    settings: {
      type: Object,
    },
    event: {
      type: Object,
    },
    hideArchive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showArchive: false,
      fullscreenController: null,
      isFullscreen: false,
      showElements: true,
      archiveType: null,
      currentMonitoringObject: null,
    };
  },
  watch: {
    settings: {
      handler() {
        this.$set(
          this.$data,
          "currentMonitoringObject",
          this.settings.monitoringObject
        );
      },
      deep: true,
    },
    async "settings.archive"() {
      this.setArchiveMode();
    },
  },
  methods: {
    async clearFullscreen() {
      if (this.isFullscreen) {
        await this.onToggleFullscreen();
      }
    },
    setArchiveMode() {
      this.$set(this.$data, "showArchive", this.settings.archive);
      this.$set(this.$data, "showElements", false);
    },
    toggleComponent() {
      this.$set(this.$data, "showArchive", !this.showArchive);
    },
    toggleElements() {
      if (this.settings.incidents) {
        this.$set(this.$data, "showElements", !this.showElements);
      }
    },
    onOpenArchive() {
      if (this.archiveType && !this.fullscreenController.state.isFullscreen) {
        this.$eva.$boxes.show({
          type: this.archiveType,
          width: this.archiveType === "drawer" ? "60%" : "100%",
          header: this.$$t(`Архив: ${this.currentMonitoringObject.name}`),
          component: EvaVideoArchiveDialog,
          componentProps: {
            settings: this.settings,
          },
          commands: false,
        });
      } else {
        this.toggleComponent();
      }
    },
    async onToggleFullscreen() {
      await this.fullscreenController.toggleFullscreen();
      this.$set(
        this.$data,
        "isFullscreen",
        this.fullscreenController.state.isFullscreen
      );
      this.$set(this.$data, "showElements", this.isFullscreen);
    },
    async onRemoveItemFromPanel() {
      this.$emit("panel:removeItem");
    },
  },
  mounted() {
    this.fullscreenController = new EvaFullscreenController();
    this.fullscreenController.use({
      app: this.$eva,
      container: this.$refs.teleport,
      parent: this.$refs.evaVideo,
    });
    this.$set(
      this.$data,
      "currentMonitoringObject",
      this.settings.monitoringObject
    );
    this.$set(this.$data, "archiveType", this.settings.archiveType);

    if (this.settings.archive) {
      this.setArchiveMode();
    }
  },
};
</script>
<style lang="less">
.eva-video {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
</style>
