import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import User from "./User";
import Platform from "./Platform";

/* TODO
1. Переделать своства объектов application и user в реактивные (например, platformName, зависящее от локали)
2. Переименовать platformName в name
*/

class ContextEvaPlugin extends EvaPlugin {
  constructor(app) {
    super(app);
    this.initReactive({
      data: {
        user: null,
        platform: null
      }
    });
  }

  async init() {
    this.user = new User(
      {}//await this.app.$http.getItem('/api/v1/users/current')
    );
    this.platform = new Platform(
      {}//await this.app.$http.getItem('/api/v1/application/settings')
    );

    this.updateTitle();
    this.updatePlatformSettings();
  }

  updatePlatformSettings() {
    if (this.platform.faviconUrl) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'shortcut icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = this.platform.faviconUrl;
    }
  }

  updateTitle() {
    window.document.title =
      this.platform.platformName &&
      this.platform.platformName[this.app.$locales.current] || '';
  }
}

export default ContextEvaPlugin;
