<template>
  <div
      v-show="!isHide"
      class="eva-checkbox-field"
  >
    <eva-checkbox
      v-model="currentValue"
      :label="fieldLabel"
      :error="fieldErrors"
      :title="fieldTitle"
      :tristate="isTristate"
      :readonly="readOnly"
    />
    <div
      v-if="!!fieldErrors"
      v-tooltip="$eva.$tools.tooltipContent(fieldErrors, 'error')"
      class="eva-textbox__button mdi mdi-alert-circle eva-textbox__button--error"
    ></div>
  </div>

</template>

<script>
export default {
  name: 'eva-checkbox-field',

  dbType: 'bool',

  computed: {
    isTristate() {
      return !!this.settings.tristate;
    },
    currentValue: {
      get() {
        if (this.isTristate && this.modelValue == null) {
          return null;
        } else {
          return this.isRevert
              ? !this.modelValue
              : this.modelValue;
        }
      },
      set(value) {
        if (this.isTristate && value == null) {
          this.modelValue = null;
        } else {
          this.modelValue = this.isRevert
              ? !value
              : !!value;
        }
      }
    },
    isRevert() {
      return this.settings && this.settings.revert === true;
    }
  }
}
</script>

<style lang="less">
.eva-checkbox-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  .eva-textbox__button--error {
    color: #CC1D26;
    border-color: #CC1D26;
  }
}
</style>
