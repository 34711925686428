import EvaValidator from "./EvaValidator";

class EvaEmailValidator extends EvaValidator {
  constructor(app) {
    super(app, 'eva-email-validator');
  }

  async validateInternal(value, args) {
    value = (value || '').toString().trim();
    if (!value || (typeof value === 'string' && !value.length)) {
      return;
    }
    let res = /^[^\s][-\w.]+@([A-z0-9][-A-z\d-.]+\.)+[A-z]+$/g.exec(value);
    return !res ? '$t.core.errors.fields.email' : '';
  }
}

export default EvaEmailValidator;
