<template>
  <div
    :class="getClass"
  >
  </div>
</template>

<script>
export default {
  name: 'eva-border-field',

  computed: {
    getClass() {
      return `eva-border-${this.settings.direction || 'top'}`;
    }
  }
}
</script>