import Vue from "vue";
import { defineStore } from "pinia";
import evaApp from "@eva/client/app";

export const useTasksManagerStore = defineStore('tasksManager', {
  state: () => ({
    tasksManagerStore: {
      filters: {
        calendarFilter: 'month',
        selectedMonth: new Date(),
        selectedDay: null,
        selectedPerformer: null,
      },
      calendar: {
        isLoading: false,
        start_time: null,
        end_time: null,
        offset: 10,
        limit: 10,
        items: [],
        total: 0,
      },
    },
  }),

  getters: {
    getSelectedMonth: (state) => {
      let month = state.tasksManagerStore.filters.selectedMonth.toLocaleString('ru', { month: 'long' });
      return `${month.charAt(0).toUpperCase() + month.slice(1)} ${state.tasksManagerStore.filters.selectedMonth.getFullYear()}`;
    },
    getTotalMonthDays: (state) => {
      const selectedDate = state.tasksManagerStore.filters.selectedMonth;
      return new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDate();
    },
  },

  actions: {
    setCalendarFilter(value) {
      this.$state.tasksManagerStore.filters.calendarFilter = value;
    },
    setDefaultMonthValues() {
      this.setStartMonthTime();
      this.setEndMonthTime();
    },
    setCalendarItems(payload) {
      const { items, total } = payload;
      Vue.set(this.tasksManagerStore.calendar, 'items', items);
      Vue.set(this.tasksManagerStore.calendar, 'total', total);
    },
    setStartMonthTime(time = new Date()) {
      const date = new Date(time);
      date.setUTCDate(1);
      date.setUTCHours(0,0,0,0);
      Vue.set(this.tasksManagerStore.calendar, 'start_time', date.getTime());
    },
    setEndMonthTime(time = new Date()) {
      const date = new Date(time);
      date.setUTCMonth(date.getUTCMonth() + 1);
      date.setUTCDate(0);
      date.setUTCHours(23,59,59, 999);
      Vue.set(this.tasksManagerStore.calendar, 'end_time', date.getTime());
    },

    async changeSelectedMonth(value) {
      const newDate = new Date(this.$state.tasksManagerStore.filters.selectedMonth);
      if (newDate.getMonth() >= 0) {
        newDate.setMonth(newDate.getMonth() + value);
      } else {
        newDate.setFullYear(newDate.getFullYear() + value);
        newDate.setDate(1);
        newDate.setMonth(12)
      }

      Vue.set(this.$state.tasksManagerStore.filters, 'selectedMonth', newDate);
      this.setStartMonthTime(newDate);
      this.setEndMonthTime(newDate);
      await this.getPeriod();
    },
    setDay(day) {
      if (
        day
        && (this.$state.tasksManagerStore.filters.selectedDay === null
        || this.$state.tasksManagerStore.filters.selectedDay
        && this.$state.tasksManagerStore.filters.selectedDay.id
        && this.$state.tasksManagerStore.filters.selectedDay.id !== day.id)
      ) {
        this.setSelectedDay(day);
        this.setSelectedPerformer(day.user);
      } else {
        this.setSelectedDay(null);
        this.setSelectedPerformer(null);
      }
    },
    setSelectedDay(day) {
      Vue.set(this.$state.tasksManagerStore.filters, 'selectedDay', day);
    },
    setSelectedPerformer(performer) {
      Vue.set(this.$state.tasksManagerStore.filters, 'selectedPerformer', performer);
    },
    async getPeriod() {
      Vue.set(this.$state.tasksManagerStore.calendar, 'isLoading', true);
      const { start_time, end_time, limit, offset } = this.$state.tasksManagerStore.calendar;
      const query = `start_time=${start_time}&end_time=${end_time}&limit=${limit}&offset=${offset}&q=is_dispatcher__eq__false`;

      this.setCalendarItems(await evaApp.$http.get(
        `/api/v1/toir/tasksmanager/period?${query}`
      ));
      Vue.set(this.$state.tasksManagerStore.calendar, 'isLoading', false);
    },
  }
});
