import EvaPlugin from "@eva/client/plugins/EvaPlugin";

const THEMES = [
  'light',
  'dark'
];
const DEFAULT_THEME = 'dark';

class ThemesEvaPlugin extends EvaPlugin {

  constructor(app) {
    super(app);

    this.initReactive({
      data: {
        current: DEFAULT_THEME
      },
      computed: {
        cssClass() {
          return `eva-theme-${this.current}`;
        }
      }
    });
  }

  toggle() {
    let index = THEMES.indexOf(this.current);
    if (index < 0 || (index + 1) >= THEMES.length ) {
      this.current = THEMES[0];
    } else {
      this.current = THEMES[index + 1];
    }
  }
}

export default ThemesEvaPlugin;
