class StyleField {

  constructor(value) {
    this.initialize(value);
  }

  initialize(value) {
    this.value = value;
  }

}

export default StyleField;
