import Vue from "vue";
import EvaRepeaterSource from "../EvaRepeaterSource";

class EvaRepeaterFieldSource extends EvaRepeaterSource {

  async loadItems(settings, options) {
    let items = settings.model || [];

    if (settings.columns.index) {
      for (let i = options.filter.offset; i < items.length; i++) {
        Vue.set(items[i], 'index', i);
      }
    }

    return {
      items,
      total: items.length
    }
  }

  async getItem(settings, options, model, index) {
    return this.app.$tools.setDeepDefaults({}, model);
  }

  async addItem(settings, options, model) {
    settings.model.push(model);
  }

  async editItem(settings, options, model, index) {
    Vue.set(settings.model, index ,model);
  }

  async removeItem(settings, options, model, index) {
    settings.model.splice(index, 1);
  }

}

export default EvaRepeaterFieldSource;
