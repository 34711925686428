import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import StyleField from "./fields/StyleField";
import SizeStyleField from "./fields/SizeStyleField";
import UISettings from "@eva/client/plugins/styles/UISettings";

import variables from "@eva/client/styles/variables";

class StylesEvaPlugin extends EvaPlugin {

  constructor(app) {
    super(app);
  }

  install() {
    Object.keys(variables).forEach((key) => {
      let value = variables[key];
      if (/px|%|pt$/.exec(value)) {
        this[this.app.$tools.toCamelCase(key)] = new SizeStyleField(value);
      } else {
        this[this.app.$tools.toCamelCase(key)] = new StyleField(value);
      }
    });

    this.ui = new UISettings();
  }

  getColor(color) {
    let result = this[this.app.$tools.toCamelCase(`eva-${color}-color`)];
    return result && result.value || color;
  }
}

export default StylesEvaPlugin;
