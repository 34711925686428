<template>
  <div class="eva-widget" :class="classes">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'eva-widget',

  props: {
    column: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes() {
      return {
        'eva-widget--column': this.column
      }
    }
  }
}
</script>

<style lang="less">
.eva-widget {
  width: 100%;
  height: 100%;
  display: flex;
  &.eva-widget--column {
    flex-direction: column;
  }
}
</style>
