<template>
  <div :class="$options.name" :style="{ 'min-height': settings.tree.minHeight }">
    <eva-field-wrapper :title="fieldTitle">
      <eva-tree 
        :settings="settings.tree"
        ref="tree"
      >
      </eva-tree>
    </eva-field-wrapper>
  </div>
</template>

<script>
export default {
  name: 'eva-tree-field',

  data() {
    return {}
  },

  watch: {
    model: {
      handler() {
        if (!this.modelValue) {
          this.modelValue = [];
        }
        this.$set(this.settings.tree, 'model', this.modelValue);
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="less">
.eva-tree-field {
  min-height: 0;
  
  .eva-tree {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
