<template>
  <eva-textbox
    v-show="!isHide"
    v-model="currentModelValue"
    :label="fieldLabel"
    :error="fieldErrors"
    :title="fieldTitle"
    :readonly="readOnly"
    :maxlength="maxLength"
    :counter="counter"
  />
</template>

<script>
export default {
  name: 'eva-text-array-field',

  computed: {
    currentModelValue: {
      get() {
        return typeof this.modelValue === 'string'
          ? this.modelValue
          : (this.modelValue || []).join(', ');
      },
      set(value) {
        this.modelValue = (value || '')
          .split(',')
          .map((v) => v.trim())
          .filter((v) => !!v);
      }
    }
  }
}
</script>
