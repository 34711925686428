<template>
  <div v-if="settings" class="eva-form-block-layout" :class="classes" :style="styles">
    <slot>
      <component
        v-for="(item, itemIndex) in settings.fields"
        :key="itemIndex"
        v-if="$eva.$tools.resolveValue(item.settings.visible, item.model) !== false"
        :form-settings="formSettings"
        :is="item.component"
        :settings="item.settings"
        :style="item.style"
        :model="item.model"
        :validators="item.validators"
      />
    </slot>
  </div>
</template>

<script>
export default {
  name: 'eva-form-block-layout',

  props: {
    formSettings: {

    },
    settings: {

    }
  },

  computed: {
    classes() {
      return {
        'eva-form-layout--border': this.settings && this.settings.border
      }
    },
    styles() {
      let result = {};
      if (this.settings.position) {
        let p = this.settings.position;
        result.gridArea = `${p.y + 1} / ${p.x + 1} / ${p.y + 1 + p.h} / ${p.x + 1 + p.w}`;
      }
      if (this.settings.height) {
        result.height = this.settings.height;
      }
      return result;
    }
  }
}
</script>

<style lang="less">
.eva-form-block-layout {
  display: flex;
  flex-direction: column;
  > * {
    margin: @eva-padding!important;
  }
  .eva-input {
    margin: 0;
  }
  &.eva-form-layout--border {
    /*padding: @eva-padding;*/
  }
}
</style>
