<template>
  <div
    class="eva-select-file-field"
    v-show="!isHide"
  >
    <eva-input
      :label="fieldLabel"
      :error="fieldErrors"
      :title="fieldTitle"
      :placeholder="(modelValue && modelValue.id) ? '' : fieldPlaceholder"
      :readonly="readOnly"
      :preview="preview"
      :icon="(modelValue && modelValue.id) ? 'mdi-download' : ''"
      :clearable="modelValue && !readOnly && !preview"
      :depth="depth"
      @clear="modelValue = null"
      @icon-click="download"
      @click="onClick"
    >
      {{ fileName }}
    </eva-input>
    <input
      ref="uploader"
      name="file"
      type="file"
      class="eva-display-none"
      :accept="settings.accept"
      @change="upload($event.target.files)"
    >
  </div>
</template>

<script>
export default {
  name: 'eva-select-file-field',

  dbType: 'ref',

  data() {
    return {
      loading: false
    }
  },

  computed: {
    commands() {
      let result = [];
      if (!this.readOnly && !this.preview) {
        result.push({
          icon: 'mdi-close',
          handler: (item) => this.remove(item)
        });
      }
      return result;
    },
    fileName() {
      return this.modelValue && this.modelValue.id && (this.modelValue.full_name || `${this.modelValue.name}${this.modelValue.extension || ''}`);
    }
  },

  methods: {
    async upload(items) {
      try {
        this.loading = true;
        if (items && items.length) {
          const data = new FormData();
          data.append('file', items[0]);
          data.append('data', JSON.stringify({
            name: items[0].name
          }));
          let res = await this.$eva.$http.post(`/api/v1/fileservice/fileobject?folder=${this.settings.folder}`, data);
          this.modelValue = res;
        }
      } catch (error) {
        this.$eva.$boxes.notifyError('Во время загрузки файла произошла ошибка');
        this.$eva.$logs.error(
          this.$options.name,
          'Во время загрузки файла произошла ошибка',
          error
        );
      } finally {
        this.loading = false;
        this.$refs.uploader.value = null;
      }
    },

    async remove(item) {
      if (this.readOnly || !this.modelValue || this.loading) {
        return;
      }

      if (await this.$eva.$boxes.confirm({
        message: `Удалить файл ${item.full_name || (item.name + item.extension)} ?`
      })) {
        let index = this.modelValue.indexOf(item);
        this.modelValue.splice(index, 1);
      }
    },

    async download() {
      if (this.loading || !this.modelValue) {
        return;
      }
      try {
        const link    = document.createElement('a');
        link.download = this.fileName;
        link.target   ="_blank";
        link.href     = `/api/v1/fileservice/file/${this.modelValue.id}`;
        link.setAttribute('download', this.fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        this.$eva.$logs.error(
          this.$options.name,
          'При скачивании файла произошла ошибка',
          error
        );
      }
    },

    onClick(e) {
      if (!this.readOnly && !this.loading && !this.preview && !this.designMode) {
        this.$refs.uploader.click();
      }
      this.$emit('click', e);
    }
  }
}
</script>

<style lang="less">
.eva-select-file-field {
  cursor: pointer;
}
</style>
