<template>
  <div class="eva-map-preview">
    <div class="eva-map-preview__canvas eva-background-2" :id="state.id"></div>
    <eva-progress v-if="state.isLoading"/>
  </div>
</template>

<script>
import createEvaMapState from "./EvaMapState";

export default {
  name: 'eva-map-preview',

  props: {
    mapSourceId: {
      type: String
    }
  },

  data() {
    return {
      state: createEvaMapState(this.$eva)
    }
  },

  watch: {
    async mapSourceId(value) {
      await this.state.initFromMapSource(value);
    }
  },

  async mounted() {
    await this.state.initFromMapSource(this.mapSourceId);
  },

  async beforeDestroy() {
    await this.state.clear();
  }
}
</script>

<style lang="less">
.eva-map-preview {
  width: 100%;
  height: 300px;
  position: relative;
  border-radius: 6px;
  padding: 0 (@eva-padding * 2) (@eva-padding * 2);
  .eva-map-preview__canvas {
    /*background-color: #E5F1FF;*/
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  canvas {
    border-radius: 6px;
  }
}
</style>
