import { reactive, computed, toRefs } from "vue";

function createRect(settings = {}) {

  const state = reactive(settings);

  const x = computed({
    get() {
      return state.x;
    },
    set(value) {
      state.x = value;
    }
  });
  const y = computed({
    get() {
      return state.y;
    },
    set(value) {
      state.y = value;
    }
  });
  const w = computed({
    get() {
      return state.w;
    },
    set(value) {
      state.w = value;
    }
  });
  const h = computed({
    get() {
      return state.h;
    },
    set(value) {
      state.h = value;
    }
  });
  const left = computed({
    get() {
      return state.x;
    },
    set(value) {
      state.w += state.x - value;
      state.x = value;
    }
  });
  const right = computed({
    get() {
      return state.x + state.w;
    },
    set(value) {
      state.w = value - state.x;
    }
  });
  const top = computed({
    get() {
      return state.y;
    },
    set(value) {
      state.h += state.y - value;
      state.y = value;
    }
  });
  const bottom = computed({
    get() {
      return state.y + state.h;
    },
    set(value) {
      state.h = value - state.y;
    }
  });
  const bounds = computed({
    get() {
      return state.bounds;
    },
    set(value) {
      state.bounds = value;
    }
  });
  const items = computed({
    get() {
      return state.items;
    },
    set(value) {
      state.items = value;
    }
  });

  function getValidatedChanges(changes = {}) {
    let current = {};
    if (bounds.value) {
      if (changes.x != null) {
        if (changes.x < bounds.value.x) {
          changes.x = bounds.value.x;
        }
        if ((changes.x + w.value) > bounds.value.w) {
          changes.x = bounds.value.w - w.value;
        }
        current.x = changes.x;
      }
      if (changes.y != null) {
        if (changes.y < bounds.value.y) {
          changes.y = bounds.value.y;
        }
        if ((changes.y + h.value) > bounds.value.h) {
          changes.y = bounds.value.h - h.value;
        }
        current.y = changes.y;
      }
      if (changes.left != null) {
        if (changes.left < bounds.value.x) {
          changes.left = bounds.value.x;
        }
        if (changes.left >= right.value) {
          changes.left = right.value - 1;
        }
        current.x = changes.left;
        current.w = w.value + left.value - changes.left;
      }
      if (changes.top != null) {
        if (changes.top < bounds.value.y) {
          changes.top = bounds.value.y;
        }
        if (changes.top >= bottom.value) {
          changes.top = bottom.value - 1;
        }
        current.y = changes.top;
        current.h = h.value + top.value - changes.top;
      }
      if (changes.right != null) {
        if (changes.right > bounds.value.w) {
          changes.right = bounds.value.w;
        }
        if (changes.right <= left.value) {
          changes.right = left.value + 1;
        }
        current.w = w.value + changes.right - right.value;
      }
      if (changes.bottom != null) {
        if (changes.bottom <= top.value) {
          changes.bottom = top.value + 1;
        }
        if (changes.bottom > bounds.value.h) {
          changes.bottom = bounds.value.h;
        }
        current.h = h.value + changes.bottom - bottom.value;
      }
    }
    if (items.value) {
      current.x = current.x == null ? x.value : current.x;
      current.y = current.y == null ? y.value : current.y;
      current.w = current.w == null ? w.value : current.w;
      current.h = current.h == null ? h.value : current.h;

      if (items.value.find((item) => intersects(current, item.state))) {
        changes = {};
      }
    }
    return changes;
  }

  function isEmpty() {
    return (
      state.x == null ||
      state.y == null ||
      state.w == null ||
      state.h == null
    );
  }

  function clear() {
    state.x = null;
    state.y = null;
    state.w = null;
    state.h = null;
  }

  function intersects(x, y, w, h, state) {
    if (typeof x === 'object') {
      state = y;
      y = x.y;
      w = x.w;
      h = x.h;
      x = x.x;
    }
    return !(
      x >= (state.x + state.w) ||
      (x + w) <= state.x ||
      y >= (state.y + state.h) ||
      (y + h) <= state.y
    );
  }

  function contains(x, y) {
    return (
      x >= left.value && x < right.value &&
      y >= top.value && y < bottom.value
    );
  }

  function update(changes) {
    changes = getValidatedChanges(changes);
    if (changes.x != null) {
      x.value = changes.x;
    }
    if (changes.y != null) {
      y.value = changes.y;
    }
    if (changes.w != null) {
      w.value = changes.w;
    }
    if (changes.h != null) {
      h.value = changes.h;
    }
    if (changes.left != null) {
      left.value = changes.left;
    }
    if (changes.right != null) {
      right.value = changes.right;
    }
    if (changes.top != null) {
      top.value = changes.top;
    }
    if (changes.bottom != null) {
      bottom.value = changes.bottom;
    }
  }

  return {
    x,
    y,
    w,
    h,
    left,
    right,
    top,
    bottom,
    bounds,

    isEmpty,
    clear,
    update,
    contains,
    intersects
  }
}

export default createRect;
