import EvaValidator from "./EvaValidator";

class EvaPhoneValidator extends EvaValidator {
  constructor(app) {
    super(app, 'eva-phone-validator');
  }

  async validateInternal(value, args) {
    value = (value || '').toString().trim();
    if (!value || (typeof value === 'string' && !value.length)) {
      return;
    }
    let res = /^((\+7|7|8){1,2}([489]){1}([0-9]){9})$/g.exec(value);
    return !res ? '$t.core.errors.fields.phone' : '';
  }
}

export default EvaPhoneValidator;
