<template>
  <div class="eva-grid" :style="gridStyle" ref="self">
    <div
      v-for="(item, i) in items"
      :style="calculateWidgetCellStyle(item)"
      :key="i"
      class="eva-grid__item"
    >
      <component
        v-if="item.meta && item.meta.component"
        :is="item.meta.component"
        :entity="item.entity"
        :meta="item.meta"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'eva-grid',

  props: {
    columns: {
      type: Number,
      default: 1
    },
    rows: {
      type: Number,
      default: 1
    },
    items: {
      type: Array,
      default: () => []
    },
  },

  computed: {
    columnWidth() {
      return this.columns > 0 ? 100 / this.columns : 0;
    },
    rowHeight() {
      return this.rows > 0 ? 100 / this.rows : 0;
    },
    gridStyle() {
      return `grid-template-columns: repeat(${this.columns}, ${this.columnWidth}%);grid-template-rows: repeat(${this.rows}, ${this.rowHeight}%);`;
    }
  },

  methods: {
    calculateWidgetCellStyle(widget) {
      const { x, y, w, h } = widget ? widget.entity : {};
      const wstart = x + 1;
      const hstart = y + 1;
      const wend = wstart + w;
      const hend = hstart + h;
      return `grid-column-start: ${wstart}; grid-column-end: ${wend}; grid-row-start: ${hstart}; grid-row-end: ${hend};`;
    }
  }
}
</script>

<style lang="less">
.eva-grid {
  display: grid;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .eva-grid__item {

  }
}
</style>
