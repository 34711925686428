import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import EvaLocalStorage from "./EvaLocalStorage";
import EvaSessionStorage from "./EvaSessionStorage";
import EvaUserStorage from "./EvaUserStorage";

class StoragesEvaPlugin extends EvaPlugin {
  constructor(app) {
    super(app);
    this.local = new EvaLocalStorage();
    this.session = new EvaSessionStorage();
    this.user = new EvaUserStorage(app);
  }

  async init() {
    await this.local.init();
    await this.session.init();
    await this.user.init();
  }
}

export default StoragesEvaPlugin;
