<template>
  <div class="eva-files-table">
    <eva-table :settings="settings" ref="table">
      <template v-slot:item.name="{ item }">
        <eva-media-text :image="item.download_url" :text="item.name" :size="72"/>
      </template>
      <template v-slot:quick-filter>
        <div v-if="folders" class="eva-images-widget--filter-group">
          <div
              v-for="item in folders"
              @click="currentFolder = item.id"
              class="eva-images-widget--filter-group-item"
              :class="{ 'eva-images-widget--filter-group-item--active' : item.id === currentFolder }"
          >
            {{ item.name }}
          </div>
        </div>
      </template>
    </eva-table>
    <input
      ref="uploader"
      name="file"
      type="file"
      class="eva-display-none"
      :accept="accept"
    >
  </div>
</template>

<script>
export default {
  name: 'eva-files-table',

  props: {
    caption: {
      type: String | Boolean
    },
    folder: {
      type: String
    },
    folders: {
      type: Array
    },
    hideFolder: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String
    }
  },

  data() {
    return {
      currentFolder: null
    };
  },

  computed: {
    settings() {
      return {
        caption: false,
        location: false,
        type: 'drawer',
        prefix: this.$options.name,
        columns: {
          download_url: {
            type: 'image',
            size: 200,
            showInRepeater: false
          },
          name: {
            type: 'text',
            sort: 'ASC'
          },
          extension: {
            type: 'text',
            readOnly: true
          },
          size: {
            readOnly: true
          },
          folder: {
            type: 'text',
            readOnly: true,
            showInRepeater: !this.hideFolder,
            showInAdd: !this.hideFolder,
            showInEdit: !this.hideFolder
          }
        },

        url: '/api/v1/fileservice/fileobject',
        urlFilter: `folder__eq__${this.currentFolder}`,
        onLoadItem: (item) => {
          item.size = !!item.size
            ? `${(item.size / 1024 / 1024).toFixed(2)} Мб`
            : '';
        },

        commands: {
          add: {
            success: false,
            handle: async () => {
              let res = await new Promise((resolve, reject) => {
                let file = null;
                const onChange = (e) => {
                  file = e.target.files && e.target.files[0]
                  this.$refs.uploader.value = null;
                  clear();
                  resolve(file);
                }
                const onFocus = (e) => {
                  setTimeout(() => {
                    if (!file) {
                      clear();
                      resolve(null);
                    }
                  }, 1000);
                }
                const clear = () => {
                  this.$refs.uploader.removeEventListener('change', onChange);
                  window.removeEventListener('focus', onFocus);
                }
                this.$refs.uploader.addEventListener('change', onChange, { once: true });
                window.addEventListener('focus', onFocus, { once: true });
                this.$refs.uploader.click();
              });
              await this.upload(res);
              await this.$refs.table.reload();
            }
          },
          item: {
            copyAddress: {
              icon: 'mdi-link-variant',
              success: true,
              handle: (item) => this.$eva.$tools.copyToClipboard(item.download_url)
            }
          }
        }
      }
    }
  },

  watch: {
    folder: {
      handler(value) {
        this.currentFolder = value;
      },
      immediate: true
    }
  },

  methods: {
    async upload(file) {
      if (!file) {
        return;
      }
      try {
        this.loading = true;
        const data = new FormData();
        data.append('file', file);
        await this.$eva.$http.post(`/api/v1/fileservice/fileobject?folder=${this.currentFolder}`, data);
        this.$eva.$boxes.notifySuccess(`$t.${this.$options.name}.commands.add.success`);
      } catch (error) {
        this.$eva.$boxes.notifyError('Во время загрузки файлов произошла ошибка');
        this.$eva.$logs.error(
            this.$options.name,
            'Во время загрузки файлов произошла ошибка',
            error
        );
      } finally {
        this.loading = false;
        this.$refs.uploader.value = null;
      }
    },
  }
}
</script>

<style lang="less">
.eva-files-table {
  display: flex;
  width: 100%;
  height: 100%;
  .eva-images-widget--filter-group {
    margin-right: (@eva-padding * 4);
    display: flex;
    flex-direction: row;
    gap: @eva-padding;
    .eva-images-widget--filter-group-item {
      height: 40px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: @eva-padding;
      border: 2px solid transparent;
      cursor: pointer;
      &:hover {
        border-color: #E4E9EF;
      }
      &.eva-images-widget--filter-group-item--active {
        border: 2px solid #0260CF;
      }
    }
  }
}
</style>
