class EvaStorage {

  constructor() {
    this.supported = false;
  }

  async init() {
    let supported;
    try {
      supported = await this.initInternal();
    } catch (error) {
      this.logError(error);
      supported = false;
    }
    this.supported = supported;
    if (!this.supported) {
      this.logError('not supported');
    }
  }

  get(key) {
    if (!this.supported) {
      return;
    }
    try {
      return this.getInternal(key);
    } catch (error) {
      this.logError(error);
      return null;
    }
  }

  set(key, value) {
    if (!this.supported) {
      return;
    }
    try {
      this.setInternal(key, value);
    } catch (error) {
      this.logError(error);
    }
  }

  remove(key) {
    if (!this.supported) {
      return;
    }
    try {
      this.removeInternal(key);
    } catch (error) {
      this.logError(error);
    }
  }

  logError(error) {
    console.log(`${this.constructor.name}:`, error);
  }

  async initInternal() {
    return true;
  }

  getInternal(key) {

  }

  setInternal(key, value) {

  }

  removeInternal(key) {

  }
}

export default EvaStorage;
