<template>
  <div class="eva-overlay" :style="styles" @click.stop="onClick">
    <slot/>
  </div>
</template>

<script>
import EvaToggleable from "../mixins/EvaToggleable";

export default {
  name: 'eva-overlay',

  mixins: [
    EvaToggleable
  ],

  props: {
    settings: {
      type: Object
    }
  },

  computed: {
    styles() {
      return {
        zIndex: this.settings && this.settings.zIndex
      }
    }
  },

  methods: {
    onClick(...args) {
      this.$emit('click', ...args);
    }
  }
}
</script>

<style lang="less">
.eva-overlay {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2001;
  background-color: rgb(33, 33, 33);
}
</style>
