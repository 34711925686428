import { defineStore } from "pinia";
import Vue from "vue";

export const treeRemoveStore = defineStore('treeRemove', {
    state: () => ({
        checkDevice: null,
        deviceModel: null,
        newDeviceModel: null,
        storeTabs: [],
        tabsState: {},
        parendFolder: {},
        currentTab: 'info',
        filterCheck: false
        // deviceFromOM: null
    }),
		getters: {
			getCurrentTabState() {
				return this.tabsState[this.currentTab]?.isEdit;
			},
      getTabsStateIsEditing() {
        const keys = Object.keys(this.tabsState);
        return keys.length > 0 && keys.some((key) => this.tabsState[key]?.isEdit);
      }
		},
    actions: {
        setCheckDevice(object) {
          Vue.set(this.$state, 'checkDevice', object);
        },
        setDeviceModel(object) {
          Vue.set(this.$state, 'deviceModel', object);
        },
        clearTabs() {
					Vue.set(this.$state, 'storeTabs', null);
					Vue.set(this.$state, 'tabsState', {});
        },
        setTabs(array) {
					Vue.set(this.$state, 'storeTabs', array);
					array.forEach((tab) => {
						this.setTabState({ tab });
					})
        },
        setNewDeviceModel(object) {
					Vue.set(this.$state, 'newDeviceModel', object);
        },
        setParenFolder(object) {
          Vue.set(this.$state, 'parendFolder', object);
        },
        clearParenFolder() {
          Vue.set(this.$state, 'parendFolder', {});
        },
        replaceNewOnOld() {
          Vue.set(this.$state, 'newDeviceModel', this.deviceModel);
        },
        setCurrentTab(text) {
					Vue.set(this.$state, 'currentTab', text);
        },
        setFilterCheck(check) {
          Vue.set(this.$state, 'filterCheck', check);
        },
        /*         setDeviceFromOM(object) {
                    this.deviceFromOM = object;
                    console.log('object', this.deviceFromOM);
                }, */
        clear() {
          Vue.set(this.$state, 'checkDevice', null);
        },
			/**
			 * Выставляем состояние табу (preview, edit)
			 * @param {*} tab - имя таба
			 * @param {*} isEdit - флаг обозначающий редактирование
       * @param {*} selectedItem - флаг обозначающий выбран ли объект в таблице
			 */
			setTabState(
        {
          tab = this.currentTab,
          isEdit = false,
          selectedItem
        } = {}
      ) {

        if (
          selectedItem
          && typeof this.tabsState[tab].selectedItem === undefined
        ) {
          this.setTabSelectedItem(selectedItem)
        }

				Vue.set(
          this.tabsState,
          tab,
          {
            ...this.tabsState[tab],
            isEdit,
          }
        );
			},

      setTabSelectedItem(selectedItem) {
        if (
          selectedItem
          && selectedItem.id
          && selectedItem.id !== this.tabsState[this.currentTab].selectedItem?.id
        ) {
          this.setTabState({ isEdit: false });
        } else if (!selectedItem?.id && !this.getCurrentTabState) {
          this.setTabState({ isEdit: true });
        }

        Vue.set(this.tabsState[this.currentTab], 'selectedItem', selectedItem);
      }
    }
})