<template>
  <eva-jsonarea
    v-show="!isHide"
    v-model="modelValue"
    :label="fieldLabel"
    :error="jsonFieldErrors"
    :title="fieldTitle"
    :readonly="readOnly || preview"
    :clearable="!!modelValue && !readOnly && !preview"
    @json-error="onJsonError"
  />
</template>

<script>
export default {
  name: 'eva-jsonarea-field',

  dbType: 'dict',

  data() {
    return {
      jsonError: null
    }
  },

  computed: {
    jsonFieldErrors() {
      return [this.jsonError, this.fieldErrors]
        .filter((e) => !!e)
        .join(' ');
    },
    hasValue() {
      return !!this.modelValue && !!Object.keys(this.modelValue).length;
    }
  },

  watch: {
    modelValue() {
      this.settings.customError = false;
      this.jsonError = null;
    }
  },

  methods: {
    onJsonError(error) {
      this.settings.customError = true;
      this.jsonError = error;
    }
  }
}
</script>

<style lang="less">
.eva-jsonarea-field {
  width: 100%;

  .v-input {
    min-height: 200px;
    background-color: #E5F1FF;
  }
  .v-input__slot {
    background-color: #E5F1FF!important;
  }

  .v-select__selections {
    padding: 8px 0 0!important;
    & > div {
      margin-left: -10px;
      width: calc(100% + 10px);
      height: 100%;
      min-height: 200px;
    }
    input {
      display: none;
    }
  }

  .v-input,
  .v-input__control,
  .v-input__slot,
  .v-select__selections {
    height: 100%!important;
  }

  .v-input__control {
    flex-wrap: nowrap!important;
  }

  .v-input__slot {
    padding-right: 2px!important;
    padding-bottom: 2px!important;
    fieldset {
      background-color: #F5F5F5!important;
    }
  }

  .v-input__append-inner {
    display: none;
  }
  .jsoneditor-menu {
    display: none;
  }
  .jsoneditor {
    border: none;
  }
  .ace_scroller {
    background-color: #F5F5F5!important;
  }
  .jsoneditor-vue {
    height: 100%;
  }
  .jsoneditor-tree {
    min-height: 100%;
  }
  .jsoneditor-outer {
    min-height:100%;
    margin: 0;
    padding: 0;
  }
}
</style>
