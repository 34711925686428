
    import localesHash from '@eva/client/plugins/locales/LocalesHash';
    export default function (Component) {
      localesHash.push({ 
        lang: 'ru', 
        data: {
          [Component.options.name]: 
{
  commands: {
    remove: {
      header: 'Удалить изображение',
      confirm: 'Удалить изображение ?',
      success: 'Изображение удалено успешно',
      error: 'При удалении изображения произошла ошибка'
    }
  }
}

        } 
      });
    }