<template>
  <div class="eva-loader">
    <eva-progress />
  </div>
</template>

<script>
export default {
  name: 'eva-loader-default',
}
</script>

<style lang="less" scoped>
.eva-loader {
  z-index: 10000;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #212d3986;
  display: flex;
  align-items: center;
  justify-content: space-around;

}
</style>