<template>
  <eva-layout column class="eva-map-source-view-list">
    <eva-layout v-if="!readonly" row fill style="flex-shrink: 0; align-items: center;">
      <eva-textbox
        v-model="newName"
        placeholder="Укажите название"
        :error="newNameError"
        style="width: 100%; margin-top: 0"
      />
      <eva-btn
        label="Сохранить"
        style="margin-bottom: 8px"
        @click="create"
      />
    </eva-layout>
    <eva-layout column fill scroll>
      <eva-layout
        v-for="(item, index) in items"
        :key="index"
        row
        fill
        style="flex-shrink: 0; align-items: center;"
      >
        <eva-btn
          :label="item.name"
          :tooltip="item.name"
          type="text--secondary"
          style="flex-shrink: 1"
          @click="goTo(item)"
        />
        <eva-btn
          icon="mdi-trash-can-outline"
          type="icon--flat"
          @click="remove(item)"
        />
      </eva-layout>
    </eva-layout>
  </eva-layout>
</template>

<script>
export default {
  name: 'eva-map-source-view-list',

  props: {
    state: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: false
    },
    box: {
      type: Object
    }
  },

  data() {
    return {
      newName: '',
      newNameError: '',
      items: []
    }
  },

  watch: {
    newName() {
      let { error } = this.validateName();
      this.newNameError = error;
    }
  },

  methods: {
    async loadItems() {
      this.items = await this.state.loadMapSourceViewList();
    },
    async create() {
      let { name, error } = this.validateName();
      if (error) {
        this.newNameError = error;
        return;
      }
      await this.state.createMapSourceView(name);
      this.newName = '';
      this.$nextTick(() => this.newNameError = '');
      await this.loadItems();
    },
    async remove(item) {
      if (await this.$eva.$boxes.confirm({
        message: 'Удалить быстрый переход ?'
      })) {
        await this.state.removeMapSourceView(item.id);
        await this.loadItems();
      }
    },
    validateName() {
      let name = (this.newName || '').trim();
      let error = '';
      if (!name) {
        error = 'Укажите название';
      } else if (name.length > 100) {
        error = 'Длина названия не может превышать 100 символов';
      }
      return {
        name,
        error
      }
    },
    goTo(item) {
      this.state.openMapSourceView(item.id);
      this.box.hide();
    }
  },

  async mounted() {
    await this.loadItems();
  }
}
</script>

<style lang="less">
.eva-map-source-view-list {
  min-width: 500px;
  max-width: 500px;
  max-height: 300px;
  padding: (@eva-padding * 2);
  .eva-btn {
    height: 40px;
    flex-shrink: 0;
  }
  .eva-btn--secondary {
    /*background-color: #F6F8FF;
    border-color: #F6F8FF;*/
    justify-content: start;
    flex-grow: 1;
    &:hover {
      /*background-color: #E5F1FF;
      border-color: #E5F1FF;*/
    }
  }
}
</style>
