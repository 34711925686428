<template>
  <div class="eva-file-field-legacy">
    <eva-field-wrapper :title="fieldTitle">
      <v-file-input
        :value="modelValue"
        :label="fieldLabel"
        :error="hasErrors"
        :messages="fieldErrors"
        small-chips
        outlined
        multiple
        dense
        append-icon=""
        prepend-icon=""
        @change="onInput"
        :disabled="loading"
        :loading="loading"
        @click="onFileClick($event)"
        :clearable="!readOnly"
      >
        <template v-slot:selection="item">
          <v-chip
            dense
            :close="!readOnly && !loading"
            app
            @click="download(item.file, $event)"
            @click:close="remove(item.index, $event)"
            style="cursor: pointer"
          >
            {{ item.file.full_name || `${item.file.name}${item.file.extension}` }}
          </v-chip>
        </template>
      </v-file-input>
    </eva-field-wrapper>
  </div>
</template>

<script>
export default {
  name: 'eva-file-field',

  data() {
    return {
      loading: false
    }
  },
  
  methods: {
    async onInput(items) {
      if (this.readOnly || this.loading) {
        return;
      }

      if (items) {
        try {
          this.loading = true;
          for (let i = 0; i < items.length; i++) {
            if (items[i] instanceof File) {
              const data = new FormData();
              data.append('file', items[i]);
              data.append('data', JSON.stringify({
                name: items[i].name
              }));
              items[i] = await this.$eva.$http.post(`/api/v1/fileservice/file?folder=${this.settings.folder}`, data);
            }
          }
          this.modelValue = items;
        } catch (error) {
          this.$eva.$boxes.notifyError('Во время загрузки файлов произошла ошибка');
          this.$eva.$logs.error(
            this.$options.name,
            'Во время загрузки файлов произошла ошибка',
            error
          );
        } finally {
          this.loading = false;
        }
      }
    },

    remove(index, $event) {
      if (this.readOnly || this.loading) {
        return;
      }
      this.model[this.settings.name].splice(index, 1);
    },

    onFileClick($event) {
      if (this.readOnly) {
        $event.preventDefault();
        $event.stopPropagation();
      }
    },

    async download(item, $event) {
      $event.preventDefault();
      $event.stopPropagation();
      if (this.loading) {
        return;
      }
      try {
        const link    = document.createElement('a');
        link.download = item.full_name;
        link.target   ="_blank";
        link.href     = `/api/v1/fileservice/file/${item.id}`;
        link.setAttribute('download', item.full_name || item.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }
}
</script>

<style lang="less">
.eva-file-field-legacy {
  .v-input__slot {
    background-color: #E5F1FF!important;
  }
}
</style>
