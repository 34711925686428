<template>
  <div
    class="eva-image"
    :class="classes"
    :style="styles"
  >
    <img
      :src="src || '/assets/images/no_image.png'"
      :style="imgStyles"
      onerror="this.src = '/assets/images/no_image.png';"
    />
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'eva-image',

  props: {
    src: {
      type: String
    },
    size: {
      type: Number
    },
    round: {
      type: Boolean,
      default: false
    },
    cover: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes() {
      return {
        'eva-image--round': this.round,
        'eva-image--cover': this.cover,
        'eva-background-1': !this.transparent
      }
    },
    styles() {
      if (this.size) {
        return {
          width: `${this.size}px`,
          height: `${this.size}px`
        };
      }
    },
    imgStyles() {
      if (this.size) {
        return {
          /*maxWidth: `${this.size}px`,
          maxHeight: `${this.size}px`*/
        };
      }
    }
  }
}
</script>

<style lang="less">
.eva-image {
  display: flex;
  margin: auto;
  width: 100%;
  height: 100%;
  img {
    margin: auto;
    object-fit: fill;
    max-width: 100%;
    max-height: 100%;
  }
  &.eva-image--cover {
    img {
      margin: auto;
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
    }
  }
  &.eva-image--round {
    border-radius: 50%;
    img {
      border-radius: 50%;
    }
  }
}
</style>
