<template>
  <div class="eva-date-field-legacy">
    <eva-field-wrapper :title="fieldTitle">
      <v-menu
        ref="dateMenu"
        :close-on-content-click="true"
        offset-y
        nudge-top="30px"
        min-width="0"
        attach
        :disabled="readOnly"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            ref="dateInput"
            :key="inputKey"
            v-model="currentDate"
            v-mask="mask"
            :label="fieldLabel"
            :placeholder="placeholder"
            :error="hasErrors"
            :messages="fieldErrors"
            outlined
            clearable
            dense
            append-icon="mdi-calendar-range"
            @click:clear="clearField"
            @click:append="on.click"
            v-bind="attrs"
            :disabled="readOnly"
            @blur="changeDate"
            @keydown.enter="changeDate"
          />
        </template>
        <v-date-picker
          :value="formattedCalendarDate"
          :locale="$locale.current"
          :min="formattedMinDate"
          :max="formattedMaxDate"
          :first-day-of-week="1"
          no-title
          @change="selectDate"
        />
      </v-menu>
    </eva-field-wrapper>
  </div>
</template>

<script>
import { isNil } from "lodash";
import moment from "moment";
import { v4 as uuid } from "uuid";

import { formatDateTime, formatDateToUnix } from "../formatters";

export default {
  name: 'eva-date-field',
  data() {
    return {
      currentDate: "", // нужен для поддержки реактивности при вводе значений
      inputKey: uuid(),
      calendarFormat: "YYYY-MM-DD",

      format: "DD.MM.YYYY",
      mask: "##.##.####",
      maskChecker: /^\d\d.\d\d.\d\d\d\d$/,
      placeholder: "__.__.____",
    };
  },
  computed: {
    startDate() {
      return this.settings?.startDate !== false;
    },
    min() {
      const min = this.settings?.min;
      const lowestMin = moment("01-01-1900", "DD-MM-YYYY").valueOf();
      if (!min) {
        return lowestMin;
      }
      if (typeof min === 'string' && min.startsWith('$current')) {
        return Date.now();
      } else {
        return min > lowestMin ? min : lowestMin;
      }

    },
    max() {
      const max = this.settings?.max;
      const highestMax = moment("01-01-2100", "DD-MM-YYYY").valueOf();
      if (!max) {
        return highestMax;
      }
      if (typeof max === 'string' && max.startsWith('$current')) {
        return Date.now();
      } else {
        return max < highestMax ? max : highestMax;
      }
    },
    formattedCalendarDate() {
      // todo: use entered date?
      const value = parseInt(this.model[this.settings.name]);
      const date =
        !isNil(value) && moment.utc(value).isValid()
          ? moment.utc(value, 'x')
          : moment.utc();
      return formatDateTime(date, this.calendarFormat);
    },
    formattedMinDate() {
      return this.formatEnteredDate(this.min);
    },
    formattedMaxDate() {
      return this.formatEnteredDate(this.max);
    },
  },
  mounted() {
    this.setValue();
    // inputKey позволяет гарантированно применить маску к новому значению
    // https://github.com/probil/v-mask/issues/518 и другие
    this.inputKey = uuid();
  },
  methods: {
    // todo: rename or remove
    formatEnteredDate(newDate) {
      if (isNil(newDate)) {
        return;
      }
      const date =
        moment.utc(parseInt(newDate)).isValid()
          ? moment.utc(parseInt(newDate), 'x')
          : undefined;
      if (isNil(date)) {
        return;
      }
      return formatDateTime(date, this.calendarFormat);
    },
    selectDate(date) {
      this.setModelValue(date, this.calendarFormat);
      this.currentDate = formatDateTime(this.model[this.settings.name], this.format);
      this.$emit('changed', this.model[this.settings.name]);
    },

    changeDate() {
      let date = this.currentDate;
      const isMatchingMask = this.maskChecker.test(date);
      if (isNil(date) || !moment.utc(date, this.format).isValid() || !isMatchingMask) {
        this.$refs.dateInput.reset();
        this.clearField();
        return;
      }

      const formattedDate = formatDateToUnix(moment.utc(date, this.format));
      if (formattedDate < this.min || !isNil(this.max) && formattedDate > this.max) {
        this.$refs.dateInput.reset();
        this.clearField();
        return;
      }

      this.setModelValue(date, this.format);
      this.currentDate = formatDateTime(formattedDate, this.format);
      this.$emit('changed', this.model[this.settings.name]);
    },
    setValue() {
      const value = this.model[this.settings.name]
      this.currentDate = value ? formatDateTime(value, this.format) : '';
    },
    clearField() {
      this.$set(this.model, this.settings.name, null);
      this.currentDate = '';
      this.$emit('changed', null);
    },
    setModelValue(value, format) {
      if (this.startDate) {
        this.$set(this.model, this.settings.name, formatDateToUnix(moment.utc(
          `${value} 00:00:00`,
          `${format} HH:mm:ss`
        )));
      } else {
        this.$set(this.model, this.settings.name, formatDateToUnix(moment.utc(
          `${value} 23:59:59`,
          `${format} HH:mm:ss`
        )));
      }
    }
  },
}
</script>

<style lang="less">
.eva-date-field-legacy {
  position: relative;
  flex: 1;
  .v-input__slot {
    background-color: #E5F1FF!important;
  }
}
</style>
