<template>
  <eva-video-archive
    :settings="settings"
    :event="event"
    :isFullscreen="isFullscreen"
    @openOnline="onOpenOnline"
    @toggleFullscreen="onToggleFullscreen"
  />
</template>

<script>
import EvaVideoArchive from "./EvaVideoArchive.vue";
import EvaFullscreenController from "../fullscreen/EvaFullscreenController";

export default {
  name: "eva-video-archive-dialog",

  components: {
    EvaVideoArchive,
  },
  props: {
    box: {
      type: Object,
    },
    event: {
      type: Object,
    },
    settings: {
      type: Object,
    },
  },
  data() {
    return {
      fullscreenController: null,
      isFullscreen: false,
    };
  },

  methods: {
    async onOpenOnline() {
      if (this.isFullscreen) {
        await this.onToggleFullscreen();
      }
      this.box.hide();
    },
    async onToggleFullscreen() {
      await this.fullscreenController.toggleFullscreen();
      this.$set(
        this.$data,
        "isFullscreen",
        this.fullscreenController.state.isFullscreen
      );
    },
  },

  mounted() {
    this.fullscreenController = new EvaFullscreenController();
    this.fullscreenController.use({
      app: this.$eva,
      container: this.$el,
      parent: this.$el.parentElement,
    });
  },
};
</script>
