<template>
  <div class="eva-toast">
    <v-snackbar
      v-model="showToast"
      :color="color"
      transition="scale-transition"
      multi-line
    >
      <div>{{ $eva.$t(header) }}</div>
      <div>{{ $eva.$t(message) }}</div>

      <template v-slot:action="{ attrs }">
        <eva-btn
          icon="mdi-close"
          type="icon--flat"
          color="white"
          @click="showToast = false"/>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'eva-toast',

  props: {
    header: {
    },
    message: {
    },
    color: {
    }
  },

  data() {
    return {
      showToast: true
    }
  },

  watch: {
    showToast() {
      if (!this.showToast) {
        this.$emit('hide');
      }
    }
  }
}
</script>

<style lang="less">
.eva-toast {
  z-index: 10002;
}
</style>
