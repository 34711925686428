
    import localesHash from '@eva/client/plugins/locales/LocalesHash';
    export default function (Component) {
      localesHash.push({ 
        lang: 'ru', 
        data: {
          [Component.options.name]: 
{
  commands: {
    addColumn: {
      header: 'Добавить поле'
    },
    removeColumn: {
      header: 'Удалить поле',
      confirm: 'Удалить поле ?'
    },
    moveUp: {
      header: 'Переместить выше'
    },
    moveDown: {
      header: 'Переместить ниже'
    }
  },
  metadataFormSettings: {
    columns: {
      base_settings: {
        header: 'Основные'
      },
      serviceAlias: {
        header: 'Сервис'
      },
      service: {
        header: 'Сервис'
      },
      object: {
        header: 'Базовый класс'
      },
      name: {
        header: 'Новый класс'
      },
      meta_name: {
        header: 'Новый класс'
      },
      description: {
        header: 'Описние'
      },
      variant: {
        header: 'Тип класса',
      },
      form_settings: {
        header: 'Форма'
      },
      widthValue: {
        header: 'Ширина'
      },
      widthUnit: {
        header: 'Единицы'
      },
      w: {
        header: 'Столбцы'
      },
      h: {
        header: 'Строки'
      }
    }
  },
  fieldFormSettings: {
    columns: {
      name: {
        header: 'Название'
      },
      description: {
        header: 'Описание'
      },
      attrs_type: {
        header: 'Тип данных'
      },
      type: {
        header: 'Тип визуального компонента'
      },
      show_flag: {
        header: 'Показывать в объекте'
      },
      type_service: {
        header: 'Сервис'
      },
      service_version: {
        header: 'Версия сервиса'
      },
      type_object: {
        header: 'Класс'
      },
      showInRepeater: {
        header: 'Показывать в таблице'
      },
      x: {
        header: 'X'
      },
      y: {
        header: 'Y'
      },
      w: {
        header: 'Ширина'
      },
      h: {
        header: 'Высота'
      },
      readOnly: {
        header: 'Только для чтения'
      },
      columnRaw: {
        label: {
          type: {
            header: 'Тип заголовка'
          }
        },
        command: {
          header: 'Команда'
        },
        password: {
          header: 'Пароль'
        },
        revert: {
          header: 'Инвертировать'
        },
        tristate: {
          header: 'Три состояния'
        },
        utc: {
          header: 'Utc'
        },
        min: {
          header: 'Минимальное значение'
        },
        max: {
          header: 'Максимальное значение'
        },
        fraction: {
          header: 'Количество нулей'
        },
        startDate: {
          header: 'Начало дня'
        },
        items: {
          header: 'Элементы списка'
        },
        multiple: {
          header: 'Множественный выбор'
        },
        url: {
          header: 'Url'
        },
        q: {
          header: 'Фильтр Url'
        }
      },
      placeholder: {
        header: 'Подсказка'
      },
      title: {
        header: 'Заголовок'
      },
      validatorRequire: {
        header: 'Обязательное поле'
      },
      validatorMin: {
        header: 'Минимальное значение'
      },
      validatorMax: {
        header: 'Максимальное значение'
      }
    },
    errors: {
      minMoreMax: 'Минимальное значение не может быть больше максимального'
    }
  },
  selectItemsFormSettings: {
    columns: {
      id: {
        header: 'ID'
      },
      label: {
        header: 'Значение'
      }
    },
    commands: {
      add: {
        header: 'Добавить элемент списка',
        success: 'Элемент списка успешно добавлен',
        error: 'Во время добавления элемента списка произошла ошибка'
      },
      edit: {
        header: 'Редактировать элемент списка',
        success: 'Элемент списка отредактирован успешно',
        error: 'При редактировании элемента списка произошла ошибка'
      },
      remove: {
        header: 'Удалить элемент списка',
        confirm: 'Удалить элемент списка?',
        success: 'Элемент списка удален успешно',
        error: 'При удалении элемента списка произошла ошибка'
      }
    }
  }
}

        } 
      });
    }