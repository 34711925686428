<template>
  <div
      class="eva-path-table-cell"
      :class="{ 'eva-text-clipped': isClipped }"
      :style="foregroundColorStyle"
  >
    <span v-tooltip="$eva.$tools.tooltipContent(currentValueInternal)">
      {{ currentValueInternal }}
    </span>
  </div>
</template>

<script>
import EvaTableCellMixin from "./EvaTableCellMixin";

export default {
  name: 'eva-path-table-cell',

  mixins: [
    EvaTableCellMixin
  ],

  computed: {
    currentValueInternal() {
      if (Array.isArray(this.currentValue)) {
        return this.currentValue
          .map((v) => this.$eva.$tools.getNestedValue(v, this.column.path))
          .join(', ');
      } else {
        return this.$eva.$tools.getNestedValue(this.currentValue, this.column.path);
      }
    }
  }
}
</script>

<style lang="less">
.eva-path-table-cell {

}
</style>
