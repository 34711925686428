import EvaPlugin from "@eva/client/plugins/EvaPlugin";

class DragndropsEvaPlugin extends EvaPlugin {

  constructor(app) {
    super(app);
  }

  beginDragMove(e, types, data) {
    if (!types) {
      return;
    }
    let keys = Object.keys(types);
    data = JSON.stringify(data);
    for (let i = 0; i < keys.length; i++) {
      e.dataTransfer.setData(keys[i], data);
    }
    e.dataTransfer.effectAllowed = 'move';
  }

  allowDrop(e, types) {
    if (!types) {
      return;
    }
    let keys = Object.keys(types);
    for (let i = 0; i < keys.length; i++) {
      if (e.dataTransfer.types.indexOf(keys[i]) >= 0 && !!types[keys[i]]) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
    }
  }

  endDrop(e, types) {
    e.preventDefault();
    e.stopPropagation();
    if (!types) {
      return;
    }
    let keys = Object.keys(types);
    let result = {};
    for (let i = 0; i < keys.length; i++) {
      const str = e.dataTransfer.getData(keys[i]);
      let data = null;
      if (str && typeof str === 'string') {
        try {
          data = JSON.parse(str);
        } catch (error) {
          this.app.$logs.error(
            'DragndropsEvaPlugin',
            'При парсинге данных произошла ошибка',
            error
          );
        }
      }
      if (data) {
        result[keys[i]] = data;
      }
    }
    return result;
  }
}

export default DragndropsEvaPlugin;

