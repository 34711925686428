import { render, staticRenderFns } from "./EvaDialog2.vue?vue&type=template&id=4f352628&scoped=true&"
import script from "./EvaDialog2.vue?vue&type=script&lang=js&"
export * from "./EvaDialog2.vue?vue&type=script&lang=js&"
import style0 from "./EvaDialog2.vue?vue&type=style&index=0&id=4f352628&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f352628",
  null
  
)

export default component.exports