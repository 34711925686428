<template>
  <eva-textbox
    v-show="!isHide"
    v-model="modelValue"
    :label="fieldLabel"
    :error="fieldErrors"
    :title="fieldTitle"
    :placeholder="fieldPlaceholder"
    :readonly="readOnly"
    :preview="preview"
    :depth="depth"
    :maxlength="maxLength"
    :counter="counter"
    :type="currentType"
    :icon="currentIcon"
    :iconlabel="iconLabel"
    @icon-click="toggleShowPassword"
  />
</template>

<script>
export default {
  name: 'eva-text-field',

  dbType: 'str',

  data() {
    return {
      showPassword: false
    }
  },

  computed: {
    currentType() {
      if (this.settings.password && !this.showPassword) {
        return 'password';
      }
    },
    currentIcon() {
      if (this.settings.password) {
        return this.showPassword
          ? 'mdi-eye'
          : 'mdi-eye-off';
      }
    },
    iconLabel() {
      if (this.settings.password) {
        return this.showPassword
          ? 'Скрыть пароль'
          : 'Показать пароль';
      }
    }
  },

  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    }
  }
}
</script>
