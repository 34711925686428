import EvaValidator from "./EvaValidator";

class EvaUniqueValidator extends EvaValidator {
  constructor(app) {
    super(app, 'eva-unique-validator');
  }

  async validateInternal(value, args, model, settings) {
    value = (value || '').toString().trim();
    if (!value) {
      return;
    }
    let q = `${settings.name}__eq__'${value}'`;
    if (model.id) {
      let id = args.id || '_id';
      q += `&q=${id}__ne__${model.id}`;
    }
    if (args.q) {
      q += `&q=${args.q}`;
    }
    let { items } = await this.app.$http.getList(`${args.url}?q=${q}&limit=1`);
    return items.length ? '$t.core.errors.fields.unique' : '';
  }
}

export default EvaUniqueValidator;
