<template>
  <div
    class="eva-drawer"
    :class="mainClasses"
    :style="mainStyles"
  >
    <div
      class="eva-drawer__inner"
      :class="innerClasses"
      :style="innerStyles"
      @mouseover="onMouseOver"
      @mouseleave="onMouseLeave"
    >
      <eva-layout column fill no-gap transparent>
        <eva-layout v-if="showActions" row no-gap transparent class="eva-drawer__actions">
          <div v-if="isOpen" class="eva-drawer__actions-custom" style="background-color: transparent">
            <slot name="actions"/>
          </div>
          <eva-spacer/>
          <slot v-if="isOpen" name="right-actions"/>
          <eva-btn v-if="!manual" type="icon--flat" :icon="isOpenIcon" :label="isOpenTooltip" @click="toggle"/>
          <eva-btn v-if="!manual" type="icon--flat" :icon="isFixedIcon" :label="isFixedTooltip" @click="fix"/>
        </eva-layout>
        <eva-layout column fill no-gap transparent>
          <slot/>
        </eva-layout>
      </eva-layout>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";

const OPENED_DRAWERS = ref([]);
const START_Z_INDEX = 101;

export default {
  name: 'eva-drawer',

  model: {
    prop: 'model',
    event: 'model:update'
  },

  props: {
    model: {
      type: Boolean,
      default: null
    },
    size: {
      type: [String, Number],
      default: '300px'
    },
    mini: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Boolean,
      default: false
    },
    storage: {
      type: String
    },
    manual: {
      type: Boolean,
      default: false
    }
  },

  data() {
    let miniSize = this.mini ? this.$eva.$styles.evaHeader.formatValue() : 0;
    return {
      open: false,
      fixed: false,
      innerClasses: null,
      mainSize: miniSize,
      innerSize: miniSize,
      miniSize,
      isOpenTooltip: computed(() => this.$eva.$t(`$t.${this.$options.name}.isOpen.${this.isOpen}`)),
      isFixedTooltip: computed(() => this.$eva.$t(`$t.${this.$options.name}.isFixed.${this.isFixed}`))
    }
  },

  computed: {
    showActions() {
      return this.actions;
    },
    isOpen: {
      get() {
        if (this.model == null) {
          return this.open;
        } else {
          return this.model;
        }
      },
      set(value) {
        if (this.isOpen === value) {
          return;
        }
        if (this.model == null) {
          this.open = value;
        } else {
          this.$emit('model:update', value);
        }
      }
    },
    isFixed: {
      get() {
        return this.fixed;
      },
      set(value) {
        this.fixed = value;
      }
    },
    isHorizontal() {
      return this.left || this.right;
    },
    mainClasses() {
      return {
        'eva-drawer--left': this.left,
        'eva-drawer--right': this.right,
        'eva-drawer--top': this.top,
        'eva-drawer--bottom': this.bottom,
        'eva-drawer--open': this.isOpen,
        'eva-drawer--close': !this.isOpen,
        'eva-drawer--fixed': this.isFixed
      }
    },
    mainStyles() {
      let z = START_Z_INDEX + OPENED_DRAWERS.value.indexOf(this._uid);
      return this.isHorizontal ? {
        'width': this.mainSize,
        'z-index': z
      } : {
        'height': this.mainSize,
        'z-index': z
      }
    },
    innerStyles() {
      return this.isHorizontal ? {
        'width': this.innerSize
      } : {
        'height': this.innerSize
      }
    },
    isOpenIcon() {
      if (this.left) {
        return this.isOpen ? 'mdi-chevron-left' : 'mdi-chevron-right';
      } else if (this.right) {
        return this.isOpen ? 'mdi-chevron-right' : 'mdi-chevron-left';
      } else if (this.top) {
        return this.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down';
      } else if (this.bottom) {
        return this.isOpen ? 'mdi-chevron-down' : 'mdi-chevron-up';
      }
    },
    isFixedIcon() {
      return this.isFixed
        ? 'mdi-pin'
        : 'mdi-pin-off';
    }
  },

  watch: {
    isOpen(value) {
      if (value) {
        OPENED_DRAWERS.value.push(this._uid);
      } else {
        let index = OPENED_DRAWERS.value.indexOf(this._uid);
        OPENED_DRAWERS.value.splice(index, 1);
      }
      this.calcSizes();
      this.saveState();
    },
    isFixed() {
      this.calcSizes();
      this.saveState();
    }
  },

  methods: {
    onMouseOver() {
      if (this.hover && !this.fixed && !this.manual) {
        this.isOpen = true;
      }
    },
    onMouseLeave() {
      if (this.hover && !this.fixed && !this.manual) {
        this.isOpen = false;
      }
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    fix() {
      this.isFixed = !this.isFixed;
    },
    calcSizes() {
      if (this.isFixed) {
        if (this.isOpen) {
          this.mainSize = this.size;
          this.innerSize = this.size;
        } else {
          this.mainSize = this.miniSize;
          this.innerSize = this.miniSize;
        }
      } else {
        if (this.isOpen) {
          this.mainSize = this.miniSize;
          this.innerSize = this.size;
        } else {
          this.mainSize = this.miniSize;
          this.innerSize = this.miniSize;
        }
      }
    },
    saveState() {
      if (!this.storage) {
        return;
      }
      this.$eva.$storages.local.set(this.storage, {
        open: this.isOpen,
        fixed: this.isFixed,
      });
    },
    loadState() {
      if (!this.storage || this.manual) {
        return;
      }
      let state = this.$eva.$storages.local.get(this.storage);
      if (state) {
        let { open, fixed } = state;
        this.isFixed = !!fixed;
        if (!this.hover || this.isFixed) {
          this.isOpen = !!open;
        }
      }
    }
  },

  mounted() {
    this.loadState();
    this.calcSizes();

    this.innerClasses = (this.$el && this.$el.className || '')
        .split(' ')
        .find((c) => !!c.startsWith('eva-background'));
  }
}
</script>

<style lang="less">
.eva-drawer {
  display: flex;
  flex-direction: column;
  z-index: 100;
  position: relative;
  transition-property: width, height;
  transition-duration: 0.2s;
  .eva-drawer__inner {
    display: flex;
    flex-direction: column;
    position: absolute;
    min-height: 0;
    z-index: 1000;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    transition-property: width, height;
    transition-duration: 0.2s;
    cursor: pointer;
    &:hover {
      overflow-y: auto;
    }
  }
  &.eva-drawer--left {
    height: 100%;
    .eva-drawer__inner {
      left: 0;
    }
  }
  &.eva-drawer--right {
    height: 100%;
    .eva-drawer__inner {
      right: 0;
    }
  }
  &.eva-drawer--top {
    width: 100%;
    .eva-drawer__inner {
      top: 0;
    }
  }
  &.eva-drawer--bottom {
    width: 100%;
    .eva-drawer__inner {
      bottom: 0;
    }
  }
  .eva-drawer__actions {
    /*padding-left: 5px;*/
    height: @eva-header;
    .eva-drawer__actions-custom {
      display: flex;
      align-items: center;
    }
  }
  &.eva-drawer--close {
    .eva-drawer__actions {
      padding-left: 5px;
    }
  }
}
</style>

<locale lang="ru">
{
  isOpen: {
    true: 'Скрыть',
    false: 'Показать'
  },
  isFixed: {
    true: 'Открепить',
    false: 'Закрепить'
  }
}
</locale>
