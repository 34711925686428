export default {
  props: {
    column: {
      type: Object
    },
    model: {
      type: Object
    }
  },

  computed: {
    currentValue() {
      return this.$eva.$metadatas.getModelValue(this.column, this.model);
    },
    foregroundColor() {
      if (!this.column || !this.model) {
        return null;
      }
      if (typeof this.column.color === 'string') {
        return this.$eva.$tools.getNestedValue(this.model, this.column.color);
      }
      if (typeof this.column.color === 'object') {
        return this.switchValue(this.column.color);
      }
    },
    foregroundColorStyle() {
      let color = this.foregroundColor;
      return color ? { color } : null;
    },
    isClipped() {
      return this.column && this.column.clipped !== false;
    }
  },

  methods: {
    switchValue(settings) {
      return settings.cases[this.model[settings.field || this.column.name]];
    }
  }
}
