class EvaValidator {

  constructor(app, name) {
    this.app = app;
    this.name = name;
  }

  async validate(value, args, model, settings) {
    let result = await this.validateInternal(value, args, model, settings);
    if (!result) {
      return;
    }
    return this.app.$t(result);
  }

  async validateInternal(value, args, model, settings) {

  }
}

export default EvaValidator;

