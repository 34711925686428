<template>
  <eva-table
    :settings="settings"
    v-model="selectedStyle"
    class="eva-map-draw-style-list"
  >
    <template v-slot:item.name="{ item }">
      <eva-media-text :icon="getIcon(item)" :image="item.download_url" :text="item.name" :size="72"/>
    </template>
  </eva-table>
</template>

<script>
import FileObjectDropDownItem from "./FileObjectDropDownItem";

export default {
  name: 'eva-map-draw-style-list',

  props: {
    box: {
      type: Object
    }
  },

  data() {
    return {
      selectedStyle: null,
      types: [],
      settings: {
        prefix: this.$options.name,
        caption: false,

        location: false,
        selectable: true,
        type: 'drawer',

        columns: {
          name: {
            type: 'text',
            validators: [
              'require'
            ],
            sort: 'ASC'
          },
          ref_feature_type: {
            type: 'catalog-ref',
            path: 'name.ru',
            url: '/api/v1/core/catalog?q=ref_catalog_type__eq__CatalogType:FeatureType&sorting=name%-1',
            validators: [
              'require'
            ],
          },
          ref_fileobject: {
            type: 'catalog-ref',
            url: '/api/v1/fileservice/fileobject',
            q: `folder__eq__/home/work/icon_files`,
            listItemComponent: FileObjectDropDownItem,
            selectionItemComponent: FileObjectDropDownItem,
            showInRepeater: false
          },
          sort_order: {
            type: 'number',
            showInRepeater: false
          }
        },

        url: '/api/v1/mapsservice/drawstyle',

        commands: {
          edit: {
            visible: true
          }
        }
      }
    }
  },

  watch: {
    selectedStyle(value) {
      if (value) {
        this.box.hide(value);
      }
    }
  },

  methods: {
    getIcon(item) {
      if (item.download_url || !item.ref_feature_type) {
        return null;
      }
      let type = this.types.find((t) => t.id === item.ref_feature_type.id);
      return type && type.params && type.params.icon;
    }
  },

  async mounted() {
    let { items } = await this.$eva.$http.getList('/api/v1/core/catalog?q=ref_catalog_type__eq__CatalogType:FeatureType');
    this.types = items;
  }
}
</script>

<style lang="less">
.eva-map-draw-style-list {
  max-height: 420px;
  max-width: 580px;
}
</style>

<locale lang="ru">
{
  caption: 'Стили рисования',
  columns: {
    name: {
      header: 'Название'
    },
    ref_feature_type: {
      header: 'Тип стиля'
    },
    sort_order: {
      header: 'Сортировка'
    },
    ref_fileobject: {
      header: 'Файл изображения'
    }
  },
  commands: {
    add: {
      header: 'Создать стиль рисования',
      success: 'Стиль рисования создан успешно',
      error: 'При создании стиля рисования произошла ошибка'
    },
    edit: {
      header: 'Редактировать стиль рисования',
      success: 'Стиль рисования отредактирован успешно',
      error: 'При редактировании стиля рисования произошла ошибка'
    },
    remove: {
      header: 'Удалить стиль рисования',
      confirm: 'Удалить стиль рисования ?',
      success: 'Стиль рисования удален успешно',
      error: 'При удалении стиля рисования произошла ошибка'
    }
  }
}
</locale>
