<template>
  <div
    class="eva-icon mdi"
    v-tooltip="label"
    :class="classes"
    :style="styles"
    @click="onClick"
  >
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'eva-icon',

  props: {
    color: {
      type: String
    },
    icon: {
      type: String
    },
    label: {
      type: String
    },
    spin: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 34
    }
  },

  computed:{
    hasClickListener(){
      return !!(this.$listeners && this.$listeners.click);
    },
    classes() {
      return {
        [this.icon]: true,
        'eva-icon--clickable': this.hasClickListener,
        'mdi-spin': this.spin
      }
    },
    styles() {
      return {
        width: `${this.size}px`,
        color: this.color
      }
    }
  },

  methods: {
    onClick(...args) {
      this.$emit('click', ...args);
    }
  }
}
</script>

<style lang="less">
.eva-icon {
  font-size: 18px;
  justify-content: center;
  letter-spacing: normal;
  &:before {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  &--clickable {
    cursor: pointer;
  }
}
</style>
