<template>
  <div
    class="eva-radio-button-field eva-foreground-1"
    v-show="!isHide"
  >
    <div
      v-if="isRow && showLabel"
      class="eva-radio-button-field-label"
      :class="{'mt-4': isRow}"
    >
      {{ fieldLabel }}
    </div>
    <v-radio-group
      v-model="currentValue"
      :class="{'mt-0' : isRow}"
      :row="isRow"
      :disabled="readOnly"
      :error="!!fieldErrors"
    >
      <template
        v-if="!isRow && showLabel"
        #label
      >
        {{ fieldLabel }}
      </template>
      <v-radio
        v-for="item in items"
        :key="item.id"
        :value="getItemValue(item)"
        :label="item.label"
      >
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'eva-radio-button-field',

  dbType: 'str',

  data() {
    return {
      items: null,
    }
  },

  computed: {
    isRow() {
      return this.settings.direction === 'row';
    },
    showLabel() {
      return this.fieldLabel && this.fieldLabel.length > 0;
    },
    currentValue: {
      get() {
        let value = this.modelValue;
        if (!value || !this.items) {
          return null;
        }
        return this.items.find((i) => typeof value !== 'object'
          ? i.id === value
          : i.id === value.id);
      },
      set(value) {
        this.modelValue = this.settings.returnType === 'object'
          ? value
          : value.id;
      }
    }
  },

  watch: {
    'settings.items': {
      async handler(value) {
        if (!value) {
          return;
        }
        if (typeof value === 'function') {
          value = value();
        }
        if (typeof value === 'string') {
          value = this.$eva.$http.getItems(value);
        }
        if (value instanceof Promise) {
          value = await value();
        }
        if (value && value.items) {
          value = value.items;
        }
        this.items = value;
      },
      immediate: true
    }
  },

  methods: {
    getItemValue(item) {
      if (item.value !== undefined) {
        return item.value;
      }
      return item;
    }
  },
}
</script>

<style lang="less">
.eva-radio-button-field {
  .v-input--selection-controls {
    margin: 4px!important;
  }
  .v-messages {
    display: none;
  }
  .v-label {
    color: #E4E9EF;
  }
  &-label {
    font-size: 14px;
  }
}
</style>
