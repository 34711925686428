import EvaValidator from "./EvaValidator";

class EvaLoginValidator extends EvaValidator {
  constructor(app) {
    super(app, 'eva-login-validator');
  }

  async validateInternal(value, args) {
    value = (value || '').toString().trim();
    if (!value) {
      return;
    }
    let res = /^(?=.*[a-zA-Z])[a-zA-Z0-9]{5,12}$/ig.exec(value);
    return !res ? '$t.core.errors.fields.login' : '';
  }
}

export default EvaLoginValidator;
