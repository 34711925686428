
export function bindOutside (el, binding, vnode) {
  el.clickOutsideEvent = function (event) {
    if (!(el == event.target || el.contains(event.target))) {
      vnode.context[binding.expression](event);
    }
  };
  document.body.addEventListener('click', el.clickOutsideEvent)
}

export function unbindOutside (el) {
  document.body.removeEventListener('click', el.clickOutsideEvent)
}


export const OutsideDirective ={
  bind: bindOutside,
  unbind: unbindOutside
}
