function getQuery(rawParams) {
  const {pageSize, page, limit, offset = 0, typeId, q = [], context, sorting, filters} = rawParams;
  const params = {
    // todo: remove hardcode value, remove from params limit and offset if they is not calculated
    offset  : offset || ((!page || page === 1) ? 0 : (page-1) * pageSize),
    limit   : limit || pageSize || 20,
    sorting,
    typeId  : typeId,
    q       : q.join('&q='),
    context : (context || '').toLowerCase(),
    filters,
  };
  let result = `limit=${params.limit}&offset=${params.offset}`;
  if (params.sorting) {
    result += `&sorting=${params.sorting}`;
  }
  if (params.ids) {
    result += `&ids=${params.ids}`;
  }
  if (params.typeId) {
    result += `&typeId=${params.typeId}`;
  }
  if (params.roles) {
    result += `&roles=${params.roles}`;
  }
  if (params.q) {
    result += `&q=${params.q}`;
  }
  if (params.context) {
    result += `&context=${params.context}`;
  }
  if (params.filters) {
    result += `&filters=${params.filters}`;
  }
  return result;
}

export default getQuery;
