import EvaRepeaterUrlSource from "./EvaRepeaterUrlSource";
import EvaRepeaterFieldSource from "./EvaRepeaterFieldSource";

export default {
  url() {
    return new EvaRepeaterUrlSource();
  },
  field() {
    return new EvaRepeaterFieldSource();
  }
}
