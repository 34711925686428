<template>
  <eva-textarea
    v-show="!isHide"
    v-model="modelValue"
    :label="fieldLabel"
    :error="fieldErrors"
    :title="fieldTitle"
    :placeholder="fieldPlaceholder"
    :readonly="readOnly"
    :preview="preview"
    :depth="depth"
    :maxlength="maxLength"
    :counter="counter"
    auto-height
  />
</template>

<script>
export default {
  name: 'eva-text-area-field',

  dbType: 'str'
}
</script>
