<template>
  <eva-input
    :label="label"
    :placeholder="placeholder"
    :maxlength="maxlength"
    :icon="icon"
    :clearable="clearable"
    :error="error"
    :depth="depth"
    class="eva-color-input"
    @clear="currentValue = ''"
    @icon-click="onIconClick"
  >
    <div class="eva-color-input__color" :style="`background-color: ${currentValue}`">
    </div>
    <input
      :value="currentValue"
      :readonly="readonly"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :type="type"
      class="eva-textbox__input"
      @input="currentValue = $event.target.value"
    >
  </eva-input>
</template>

<script>
import EvaInputMixin from "./EvaInputMixin";

export default {
  name: 'eva-color-input',

  props: {
    type: {}
  },

  mixins: [
    EvaInputMixin
  ],

  methods: {
    onIconClick(e) {
      this.$emit('icon-click', e);
    }
  }
}
</script>

<style lang="less">
.eva-color-input {
  .eva-textbox__input {
    display: flex;
    gap: @eva-padding;
  }
  .eva-color-input__color {
    width: (@eva-padding * 3);
    height: (@eva-padding * 3);
    border-radius: 50%;
  }
}
</style>
