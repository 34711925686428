<template>
  <eva-color-input
    v-show="!isHide"
    v-model="modelValue"
    :label="fieldLabel"
    :error="fieldErrors"
    :title="fieldTitle"
    :readonly="readOnly"
    :maxlength="maxLength"
  />
</template>

<script>
export default {
  name: 'eva-select-color-field'
}
</script>

<style lang="less">
.eva-select-color-field {

}
</style>
