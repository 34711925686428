<template>
  <v-app class="eva-page--obsolete" :class="classes">
    <div class="eva-page eva-background-1">
      <slot/>
      <div class="eva-page__fullscreen-portal disabled"></div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'eva-page',

  computed: {
    classes() {
      return this.$eva.$themes.cssClass;
    }
  }
}
</script>

<style lang="less">
.eva-page--obsolete {
  width: 100%;
  height: 100%;
}
.eva-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .eva-page__fullscreen-portal {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2002;
    &.disabled {
      display: none;
    }
  }
}
</style>
