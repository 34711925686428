<template>
  <div
    v-show="!isHide"
    class="eva-time-field"
  >
    <eva-field-wrapper :title="fieldTitle">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        offset-y
        nudge-top="30px"
        min-width="0"
        :disabled="readOnly"
      >
        <template v-slot:activator="{ on, attrs }">
<!--           <v-text-field
            ref="timeInput"
            :key="inputKey"
            v-model="currentTime"
            v-mask="mask"
            :label="fieldLabel"
            :placeholder="placeholder"
            :error="hasErrors"
            :messages="fieldErrors"
            outlined
            clearable
            dense
            append-icon="mdi-clock-time-eight-outline"
            @change="changeTime"
            @click:clear="clearField"
            @click:append="on.click"
            v-bind="attrs"
            :disabled="readOnly"
            @click="$emit('click', $event)"
          /> -->
          <eva-input
          :value="!!modelValue"
          :label="fieldLabel"
          :placeholder="fieldPlaceholder"
          :error="fieldErrors"
          :title="fieldTitle"
          :readonly="readOnly"
          :preview="preview"
          :depth="depth"
          :icon="(preview) ? null : 'mdi-clock-time-eight-outline'"
          v-bind="attrs"
          v-on="on"
          @icon-click="on.click"
          :clearable="!!modelValue && !readOnly && !preview"
          @clear="clearField"
          @click="$emit('click', $event)"
        >
          <div>
            {{ currentTime }}
          </div>
        </eva-input>
        </template>
        
        <v-time-picker
          ref="timePicker"
          :value="formattedPickerTime"
          :locale="$locale.current"
          :min="formattedMinTime"
          :max="formattedMaxTime"
          :disabled="readOnly"
          format="24hr"
          :use-seconds="!!settings.seconds"
          @click:hour="setHours"
          @change="selectTime"
        ></v-time-picker>
      </v-menu>
    </eva-field-wrapper>
  </div>
</template>

<script>
import {v4 as uuid} from "uuid";
import { isNil, isEmpty } from "lodash";
import moment from "moment";
import {
  formatTime,
  formatTimeToUnix,
} from "./formatters";

export default {
  name: 'eva-time-field',

  data() {
    return {
      menu: false,
      pickerFormat: "HH:mm",
      maxTimeStr: "23:59",
      currentTime: "",
      inputKey: uuid(),

      format: 'HH:mm',
      mask: "##:##",
      maskChecker: /^\d\d:\d\d$/,
      placeholder: "__:__",
    };
  },

  computed: {
    min () {
      return this.settings?.min
    },
    max () {
      return this.settings?.max
    },
    formattedPickerTime() {
      return this.formatEnteredTime(this.model[this.settings.name]);
    },
    formattedMinTime () {
      return this.formatEnteredTime(this.min);
    },
    formattedMaxTime () {
      return this.formatEnteredTime(this.max);
    }
  },
  watch: {
    model: {
      handler() {
        this.currentTime = this.model[this.settings.name]
          ? formatTime(this.model[this.settings.name], this.format)
          : null;
      },
      deep: true,
    }
  },
  mounted() {
    if (this.settings?.seconds) {
      this.pickerFormat = "HH:mm:ss"
      this.maxTimeStr = "23:59:59"
      this.format = 'HH:mm:ss';
      this.mask = "##:##:##";
      this.maskChecker = /^\d\d:\d\d:\d\d$/
      this.placeholder = "__:__:__"
    }
    this.setValue()
  },
  methods: {
    // todo: rename or remove
    formatEnteredTime (newTime) {
      let time = newTime;
      if (isNil(newTime)) {
        return;
      }
      if (newTime < 0) {
        time = 0;
      }
      if (moment.duration(newTime).days() > 0) {
        time = formatTimeToUnix(this.maxTimeStr, !!this.settings.seconds ? 'seconds' : 'milliseconds');
      }
      return formatTime(time, this.pickerFormat);
    },
    setHours (hours) { // Workaround this bug: https://github.com/vuetifyjs/vuetify/issues/6163
      if (!this.model[this.settings.name] || !this.$refs.timePicker) {
        // to prevent double set value in start
        return
      }
      const formattedHours = `${hours}`.length === 1 ? `0${hours}` : `${hours}`
      const selectedMinutes = this.$refs.timePicker.inputMinute
      const formattedMinutes = `${selectedMinutes}`.length === 1 ? `0${selectedMinutes}` : `${selectedMinutes}`
      const fullValue = `${formattedHours}:${formattedMinutes}`
      this.selectTime(fullValue)
    },
    selectTime(time) {
      this.$set(this.model, this.settings.name, formatTimeToUnix(time, !!this.settings.seconds ? 'seconds' : 'milliseconds'))
      this.currentTime = formatTime(this.model[this.settings.name], this.format)
    },
    changeTime(time) {
      const isMatchingMask = this.maskChecker.test(time)
      if (isNil(time) || !isMatchingMask || !moment.utc(time, this.format, true).isValid()) {
        this.$refs.timeInput.reset();
        this.clearField()
        return;
      }

      const formattedTime = formatTimeToUnix(moment.utc(time, this.format, true).format(this.format), !!this.settings.seconds ? 'seconds' : 'milliseconds');
      if (!isNil(this.min) && formattedTime < this.min || !isNil(this.max) && formattedTime > this.max) {
        this.$refs.timeInput.reset();
        this.clearField()
        return;
      }

      this.$set(this.model, this.settings.name, formattedTime)
      this.currentTime = formatTime(this.model[this.settings.name], this.format)
    },
    setValue() {
      const value = this.model[this.settings.name]
      this.currentTime = value ? formatTime(this.model[this.settings.name], this.format) : null;
      // inputKey позволяет гарантированно применить маску к новому значению
      // https://github.com/probil/v-mask/issues/518 и другие
      this.inputKey = uuid();
    },
    clearField() {
      this.$set(this.model, this.settings.name, null)
      this.currentTime = null
    },
  },
}
</script>

<style lang="less">
.eva-time-field {
  position: relative;
  flex: 1;
}
</style>
