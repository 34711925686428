<template>
  <div :class="$options.name">
    <v-date-picker
      locale="ru"
      :first-day-of-week="1"
      :max="maxDate"
      v-model="selectedDate"
      no-title
      dark
    />
  </div>
</template>

<script>
import moment from "moment";

const DATE_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'eva-select-date-popup',

  props: {
    value: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    select: {
      type: Function,
      default: null
    },
    box: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      selectedDate: null,
      maxDate: null,
    }
  },
  mounted() {
    if (this.max) {
      this.maxDate = moment(new Date(this.max)).format(DATE_FORMAT);
    }
  },

  watch: {
    value: {
      handler(value) {
        if (value) {
          this.selectedDate = moment(new Date(value)).format(DATE_FORMAT);
        } else {
          this.selectedDate = null;
        }
      },
      immediate: true
    },
    selectedDate(value) {
      this.select(value ? moment(value, DATE_FORMAT).valueOf() : null);
      this.box.hide();
    }
  }
}
</script>

<style lang="less">
.eva-select-date-popup {
  display: flex;
  flex-direction: column;
}
</style>
