<template>
  <div
      class="eva-checkbox-cell"
      :class="{ 'eva-text-clipped': isClipped }"
  >
    {{
      currentValue === true
          ? $eva.$t('$t.core.dialog.commands.yes.header')
          : currentValue === false
              ? $eva.$t('$t.core.dialog.commands.no.header')
              : ''
    }}
  </div>
</template>

<script>
import EvaTableCellMixin from "./EvaTableCellMixin";

export default {
  name: 'eva-checkbox-cell',

  mixins: [
    EvaTableCellMixin
  ]
}
</script>

<style lang="less">
.eva-checkbox-cell {
}
</style>
