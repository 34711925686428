<template>
    <div class="blockIndex">
        <div class="widthLine">
            <span class="mdi mdi-arrow-left" @click="backmenu"></span>
            <h3>Менюшка</h3>
        </div>
        <div class="folderBody">
            <div v-if="itemsFolder != null || itemsFolder.length > 0" v-for="(item, index) in itemsFolder" :key="index"
                class="itemFolder eva-background-3" :class="item.checked == true ? 'itemFolder-Selected' : ''">
                <div class="textBlock" @click="checkItem(index)">{{ item.name }}</div>
                <div v-if="item.child" class="iconBlock">
                    <span class="mdi mdi-chevron-right" @click="enterFfolder"></span>
                </div>
            </div>
            <!-- not found -->
            <div v-if="itemsFolder == null || itemsFolder.length === 0">
                <h3>{{ $$t('$t.$cur.labelFolder') }}</h3>
                <!-- $$t('$t.$cur.labelFolder') или $eva.$t('$t.eva-folder-navigate.labelFolder') -->
            </div>
        </div>

    </div>
</template>

<script>
/* для использования actions  в pinia */
import { useEFNStore } from '@piniaStores';
import { mapActions } from 'pinia'

const storeEFN = useEFNStore();

export default {
    name: 'eva-folder-navigate',
    props: {
        items: {
            type: Array
        },
        coloritem: {
            type: String
        },
        itemsExample: {
            type: Object
        }
    },
    data() {
        return {
            itemsFolder: null,
            currentCheck: null
        }
    },
    mounted() {
        this.itemsFolder = storeEFN.objectFolder;
        //console.log("object",this.$eva.$t(`${$options.name}.labelFolder`));
    },

    methods: {
        /* alert-if нашего модуля */
        ...mapActions('evaFolderNAvigate', ['folderChecked']),

        checkItem(iditem) {

            let index = this.itemsFolder.findIndex((item) => item.checked == true)
            console.log(index);
            index != -1
                ? (this.itemsFolder[index].checked = false, this.itemsFolder[iditem].checked = true)
                : this.itemsFolder[iditem].checked = true

            storeEFN.folderChecked(this.itemsFolder[iditem]);

        },
        backmenu() {
            console.log("click back");
        },
        enterFfolder() {
            console.log("enter folder");
        }
    }
}
</script>

<style lang="less" scoped>
.folderBody {
    overflow: auto;
}

.blockIndex {
    margin: 15px;
}

.widthLine {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #3E4C5D;
    cursor: pointer;

    span {
        font-size: 1.4rem;
        margin-right: 15px;
        cursor: pointer;
    }
}

.itemFolder {
    display: flex;
    align-items: center;
    border-radius: 6px;
    /* background-color: #3E4C5D; */
    margin: 5px;
    padding: 5px;
    cursor: pointer;

    .textBlock {
        width: 90%;
        padding: 5px;
    }

    .iconBlock {
        width: 10%;
        text-align: end;
    }
}

.itemFolder:hover {
    outline: 1px solid #26A4FF;
    /* background-color: #2A3746; */
}

.itemFolder-Selected {
    background-color: #2A3746;
}
</style>

<locale lang="ru">
    {
        labelFolder:"Отсутствуют данные для отображения"
    }
</locale>