<template>
  <div
      class="eva-chip-list-cell"
      :class="{ 'eva-text-clipped': isClipped }"
  >
    <v-chip
        v-for="(item, index) in currentValue"
        :key="index"
        v-tooltip="$eva.$tools.tooltipContent($eva.$tools.getNestedValue(item, column.path))"
    >
      {{ $eva.$tools.getNestedValue(item, column.path) }}
    </v-chip>
  </div>
</template>

<script>
import EvaTableCellMixin from "./EvaTableCellMixin";

export default {
  name: 'eva-chip-list-cell',

  mixins: [
    EvaTableCellMixin
  ]
}
</script>

<style lang="less">
.eva-chip-list-cell {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: @eva-padding;
}
</style>
