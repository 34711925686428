<template>
  <eva-input
    :label="label"
    :placeholder="isFocus ? '' : placeholder"
    :maxlength="maxlength"
    :icon="icon"
    :iconlabel="iconlabel"
    :clearable="clearable"
    :readonly="readonly"
    :preview="preview"
    :depth="depth"
    :error="error"
    :value="currentValue != null && currentValue !== ''"
    @clear="currentValue = ''"
    @icon-click="onIconClick"
  >

    <template v-slot:header>
      <slot name="header"></slot>
    </template>

    <input
      :value="currentValue != null ? currentValue : ''"
      :readonly="readonly || preview"
      :maxlength="maxlength"
      :type="type"
      class="eva-textbox__input"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput($event)"
      ref="input"
    >

  </eva-input>
</template>

<script>
import EvaInputMixin from "./EvaInputMixin";

export default {
  name: 'eva-textbox',

  props: {
    type: {},
    pattern: {
      type: String
    }
  },

  mixins: [
    EvaInputMixin
  ],

  data() {
    return {
      isFocus: false
    }
  },

  methods: {
    onIconClick(e) {
      this.$emit('icon-click', e);
    },
    onFocus() {
      this.isFocus = true;
    },
    onBlur() {
      this.isFocus = false;
    },
    onInput(e) {
      let value = (e.target.value || '').trim();
      if (this.pattern) {
        let res = new RegExp(this.pattern).exec(value);
        value = res && res[0] || '';
        e.target.value = value;
      }
      this.currentValue = value;
    }
  }
}
</script>
