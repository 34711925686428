<template>
  <div
      class="eva-chip"
      :class="classes"
      @click.stop.prevent="onClick($event)"
      @mousedown.stop.prevent
  >
    <div
      class="eva-chip__inner"
      :style="style"
    >
      <div class="eva-chip__text eva-text-clipped eva-foreground-1">
        {{ label }}
      </div>
      <div v-if="commands" class="eva-chip__buttons">
        <div
            v-for="(item, index) in commands"
            :key="index"
            class="eva-chip__button mdi eva-foreground-1"
            :class="item.icon"
            @click.stop.prevent="runCommand(item)"
            @mousedown.stop.prevent
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'eva-chip',

  props: {
    label: {},
    commands: {},
    tag: {},
    isFlat: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'small',
    },
    color: {
      type: String,
      default: null,
    },
  },

  computed: {
    hasClickListener() {
      return !!(this.$listeners && this.$listeners.click);
    },
    classes() {
      return {
        'eva-cursor-pointer': this.hasClickListener,
        'eva-chip--flat': this.isFlat,
        'eva-chip--small': this.size === 'small',
        'eva-chip--medium': this.size === 'medium',
        'eva-chip--large': this.size === 'large',
      }
    },
    style() {
      let baseStyle = '';

      if (this.color) {
        baseStyle += `background-color: ${this.color}`;
      }

      return baseStyle;
    }
  },

  methods: {
    onClick(e) {
      if (!this.hasClickListener) {
        return;
      }
      this.$emit('click', e);
    },
    runCommand(item) {
      item.handler(this.tag);
    }
  }
}
</script>


<style lang="less">
.eva-chip {
  &:hover {
    .eva-chip__text {
      color: #3E4C5D!important;
    }
    .eva-chip__button {
      color: #3E4C5D!important;
    }
  }
}
</style>
