<template>
  <div class="eva-text-field-legacy">
    <eva-field-wrapper :title="fieldTitle">
      <v-text-field
        v-model="modelValue"
        :label="fieldLabel"
        :error="hasErrors"
        :messages="fieldErrors"
        :disabled="readOnly"
        :maxlength="maxLength"
        :counter="counter"
        clearable
        outlined
        dense
        :append-icon="iconPass"
        :type="type"
        @mousedown="showPassword"
        @mouseup="hidePassword"
      />
    </eva-field-wrapper>
  </div>
</template>

<script>
export default {
  name: 'eva-text-field',

  data() {
    return {
      type: 'text',
      iconPass: null
    }
  },

  computed: {
    computedType() {
      return this.settings.password ? this.type = 'password' : this.type = 'text';
    },
    computedIcon() {
      return this.settings.password ? this.iconPass = 'mdi-eye-off' : this.iconPass = null;
    }
  },

  methods: {
    showPassword() {
      if (this.settings.password) {
        this.type = "text";
        this.iconPass = "mdi-eye";
      }
    },
    hidePassword() {
      if (this.settings.password) {
        this.type = "password";
        this.iconPass = "mdi-eye-off";
      }
    },
  },
}
</script>

<style lang="less">
.eva-text-field-legacy {
  .v-input__slot {
    background-color: #E5F1FF!important;
  }
}
</style>
