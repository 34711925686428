import EvaStorage from "./EvaStorage";

class EvaBrowserStorage extends EvaStorage {

  constructor(source) {
    super();
    this.source = source;
  }

  async initInternal() {
    const test = '__STORAGE_TEST__';
    this.source.setItem(test, test);
    this.source.removeItem(test);
    return true;
  }

  getInternal(key) {
    let str = this.source[key];
    if (str) {
      let state = JSON.parse(str);
      return state.value;
    } else {
      return null;
    }
  }

  setInternal(key, value) {
    this.source.setItem(key, JSON.stringify({ value }));
  }

  removeInternal(key) {
    this.source.removeItem(key);
  }
}

export default EvaBrowserStorage;
