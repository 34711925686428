export default {
  props: {
    settings: {
      type: Object
    },
    model: {
      type: Object
    },
    border: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    classes() {
      return {
        'eva-form-layout--border': this.border
      }
    }
  }
}
