<template>
  <div v-if="col?.description || col?.label" :class="col.color" class="alertContainer">
    <div class="color-bar"></div>
    <div class="alert-content "> 
      <div v-if="col?.label"> {{ col.label}} </div>
      <div v-if="col?.description"> {{ col.description}} </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'alertEtv',

  props: {
    col: {
      type: Object,
      default: () => { },
    },
  },
}
/* 
example

col1: {
  type:'alertEtv',
  color:'purple1',
  label: '',
  description: object.prerequisites.rework_comment
}

*/
</script>

<style lang="less" scoped>
.alertContainer {
  display: flex;
  width: 100%;
}

.color-bar {
  width: 2px;
}

.alert-content {
  padding: 5px 0 5px 10px;
}

.purple1.alertContainer {
  background-color: #352B61;
  /* Яркий цвет фона */
}

.purple1 .color-bar {
  background-color: #8323FF;
  /* Цвет левой полосы */
}

.purple1 .alert-content {
  color: #E4E9EF;
  /* Цвет текста на ярком фоне */
}
</style>

