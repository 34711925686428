<template>
  <div class="eva-interval-time-field">
    <eva-field-wrapper :title="fieldTitle">
      <eva-time-field
        :settings="lowSettings"
        :form-settings="formSettings"
        :model="model"
        :validators="validators"
        :disabled="readOnly"
      />
      <eva-time-field
        :settings="highSettings"
        :form-settings="formSettings"
        :model="model"
        :validators="validators"
        :disabled="readOnly"
      />
    </eva-field-wrapper>
  </div>
</template>

<script>
import { isNil } from "lodash"

import EvaTimeField from "../datetime/EvaTimeField"

export default {
  name: 'eva-interval-time-field',

  components: {
    EvaTimeField,
  },

  computed: {
    lowSettings() {
      return {
        ...this.settings,
        name: this.settings.low && this.settings.low.name || `${this.settings.name}Low`,
        max: !isNil(this.settings.low.max)
          ? Math.max(this.settings.low.max, this.model[this.settings.high.name || `${this.settings.name}High`])
          : this.model[this.settings.high.name || `${this.settings.name}High`],
        min: this.settings.low.min,
        readOnly: this.settings.readOnlyTimeStart != null ? this.settings.readOnlyTimeStart : this.settings.readOnly,
      }
    },
    highSettings() {
      return {
        ...this.settings,
        name: this.settings.high && this.settings.high.name || `${this.settings.name}High`,
        max: this.settings.high.max,
        min: !isNil(this.settings.high.min)
            ? Math.min(this.settings.high.min, this.model[this.settings.low.name || `${this.settings.name}Low`])
            : this.model[this.settings.low.name || `${this.settings.name}Low`],
        readOnly: this.settings.readOnlyTimeEnd != null ? this.settings.readOnlyTimeEnd : this.settings.readOnly,
      }
    },
  }
}
</script>

<style lang="less">
.eva-interval-time-field {
  display: flex;
  gap: @eva-padding;
}
</style>
