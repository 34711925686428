<template>
  <eva-layout row scroll class="eva-gallery-field">
    <eva-field-wrapper :title="fieldTitle">
      <eva-image 
        class="gallery-item" 
        v-for="(item, index) in items"
        :key="index"
        :src="item.src"
        onerror="this.src = '/assets/images/no_image.png';"
      />
    </eva-field-wrapper>
  </eva-layout>
</template>

<script>

export default {
  name: 'eva-gallery-field',

  data() {
    return {
      items: [],
    }
  },

  watch: {
    'settings.items': {
      async handler(value) {
        if (!value) {
          return;
        }
        if (typeof value === 'function') {
          value = value();
        }
        if (typeof value === 'string') {
          value = this.$eva.$http.getItems(value);
        }
        if (value instanceof Promise) {
          value = await value();
        }
        if (value && value.items) {
          value = value.items;
        }
        this.items = value;
      },
      immediate: true
    },
  },

  methods: {
    getTName(item) {
      let index = this.items.indexOf(item);
      return `$t.${this.formSettings.prefix}.${this.settings.name}.items[${index}]`;
    },
  }
}
</script>

<style lang="less">
.eva-gallery-field {
  margin-bottom: 30px;
  .gallery-item {
    max-height: 180px;
  }
}
</style>
