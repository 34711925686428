import { defineStore } from 'pinia';

export const useAlertStore = defineStore({
    id: 'alert',
    state: () => ({
        alert: " standart"
    }),
    actions: {
        success(message) {
            console.log("here", message);
            this.alert = message;
        },
        error(message) {
            this.alert = message;
        },
        clear() {
            this.alert = null;
        }
    }
});