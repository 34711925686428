<template>
  <v-tooltip top :disabled="!currentTooltip">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="currentColor"
        :icon="isIcon"
        :outlined="isOutlined"
        :text="isPlain"
        class="eva-btn-legacy"
        :class="currentClasses"
        :disabled="currentDisabled"
        v-bind="attrs"
        v-on="on"
        @click.prevent.stop="onClick"
        @mousedown.stop.prevent
      >
        <v-icon v-if="hasIcon">{{ currentIcon }}</v-icon>
        <span v-if="!isIcon">{{ currentLabel }}</span>
      </v-btn>
    </template>
    <span>
      {{ currentTooltip }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'eva-btn',

  props: {
    type: {
      type: String /* icon text icon-text text-icon */
    },
    color: {
      type: String
    },
    icon: {
      type: String
    },
    label: {
      type: String | Number
    },
    tooltip: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    command: {
      type: Object
    }
  },

  computed: {
    currentType() {
      let type = this.getProperty('type');
      if (type) {
        let parts = type.split('--');
        return parts[0];
      }
      let switchType = (this.getProperty('icon') ? '1' : '0') + (this.getProperty('label') ? '1' : '0');
      switch (switchType) {
        case '10':
          return 'icon';
        case '11':
          return 'icon-text';
        case '01':
          return 'text';
        default:
          return 'icon';
      }
    },
    currentClasses() {
      let result = {
        'eva-btn--light': !this.isPlain && !this.isOutlined && this.currentType !== 'icon',
        'eva-btn--text': this.currentType === 'text',
        'eva-btn--icon': this.currentType === 'icon',
        'eva-btn--icon-text': this.currentType === 'icon-text',
        'eva-btn--text-icon': this.currentType === 'text-icon',
        'eva-btn--outlined': this.isOutlined,
        'eva-btn--border-start': this.hasModifier('border-start'),
        'eva-btn--border-middle': this.hasModifier('border-middle'),
        'eva-btn--border-end': this.hasModifier('border-end'),
        'eva-btn--border-less': this.hasModifier('border-less'),
      }
      if (this.class) {
        result[this.class] = true;
      }
      return result;
    },
    currentColor() {
      return this.$eva.$styles.getColor(this.getProperty('color'));
    },
    currentIcon() {
      return this.$eva.$t(this.getProperty('icon'));
    },
    currentLabel() {
      return this.$eva.$t(this.getProperty('label'));
    },
    currentTooltip() {
      switch (this.currentType) {
        case 'icon':
          return this.$eva.$ta(this.getProperty('tooltip'), this.getProperty('label'));
        default:
          return this.$eva.$te(this.getProperty('tooltip'));
      }
    },
    currentDisabled() {
      return this.getProperty('disabled');
    },
    isIcon() {
      return this.currentType === 'icon';
    },
    hasIcon() {
      return this.currentType && this.currentType.indexOf('icon') >= 0;
    },
    isPlain() {
      return this.hasModifier('plain');
    },
    isOutlined() {
      return this.hasModifier('outlined');
    }
  },

  methods: {
    hasModifier(name) {
      let type = this.getProperty('type');
      return type
        ? type.split('--').includes(name)
        : false;
    },
    onClick(e) {
      this.$emit('click', e);
    },
    getProperty(name) {
      if (this.command && this.command[name] != null) {
        return this.command[name];
      }
      return this[name];
    }
  }
}
</script>

<style lang="less">
.eva-btn-legacy {
  border-radius: (@eva-padding)!important;
  padding: 0!important;
  &.eva-btn--light {
    color: white!important;
  }
  &.eva-btn--text {
    min-width: 36px!important;
    min-height: 36px!important;
    padding: 0 (@eva-padding) !important;
    //color: rgba(0,0,0,.54);
  }
  &.eva-btn--border-start {
    border-radius: (@eva-padding) 0 0 (@eva-padding)!important;
  }
  &.eva-btn--border-end {
    border-radius: 0 (@eva-padding) (@eva-padding) 0!important;
  }
  &.eva-btn--border-middle {
    border-radius: 0!important;
  }
  &.eva-btn--border-less {
    border: none!important;
  }
  &.eva-btn--icon {
    width: @eva-icon-size!important;
    height: @eva-icon-size!important;
    /*color: unset!important;*/
    &.eva-btn--outlined {
      width: 36px!important;
      height: 36px!important;
    }
    .v-icon {
      /*margin-right: (@eva-padding / 2)!important;
      font-size: (@eva-icon-size * 0.9)!important;*/
      height: 18px;
      font-size: 18px;
      width: 18px;
    }
  }
  &.eva-btn--icon-text {
    padding: 0 (@eva-padding) !important;
    .v-btn__content {
      flex-direction: row!important;
    }
    .v-icon {
      margin-right: (@eva-padding / 2)!important;
      /*font-size: (@eva-icon-size * 0.75)!important;*/
      height: 18px;
      font-size: 18px;
      width: 18px;
    }
  }
  &.eva-btn--text-icon {
    padding: 0 (@eva-padding) !important;
    .v-btn__content {
      flex-direction: row-reverse!important;
    }
    .v-icon {
      margin-left: (@eva-padding / 2)!important;
      /*font-size: (@eva-icon-size * 0.75)!important;*/
      height: 18px;
      font-size: 18px;
      width: 18px;
    }
  }
}
</style>
