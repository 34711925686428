import { reactive } from "vue";

class UISettings {

  constructor() {
    this.inputs = reactive({
      borderType: 'outlined' // outlined filled solo regular
    })
  }

}

export default UISettings;
