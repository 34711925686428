<template>
  <eva-layout row transparent height="100%" style="min-height: 350px;">
    <eva-layout column transparent width="300px" no-shrink>
      <eva-tree
        v-model="moTreeId"
        :settings="moTreeSettings"
        style="width: 300px"
        ref="moTree"
      />
    </eva-layout>
    <eva-layout column transparent width="100%">
      <eva-map
        v-model="moId"
        class="eva-incidents-incident-create-map"
        :map-object-id="mapObjectId"
        readonly
        :isCustomObject="isCreate"
        :common-panel="false"
        :path-panel="false"
        ref="map"
        @item:set-coords="onSetCoords"
      />
    </eva-layout>
  </eva-layout>
</template>

<script>
import { watch } from 'vue';

export default {
  name: 'eva-map-tree',

  props: {
    moTreeSettings: {
      type: Object,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    mapHaveCustomObject() {
      return !!this.$refs.map.state.customObject;
    },
  },

  data() {
    return {
      moTreeId: null,
      moId: null,
      mapObjectId: null,

    };
  },

  methods: {
    async getMonitoringObject(value) {
      return value ?
          await this.$eva.$http.getItem(
            `/api/v1/iagentservice/monitoringobject/${value}`
          ) : null;
    },
    async onSetCoords(id) {
      await this.$refs.map.reloadItem(id);
      await this.$refs.moTree.state.reloadItems(id);
    },
  },

  watch: {
    moTreeId: {
      async handler(value, oldValue) {
        if (this.mapHaveCustomObject && value !== this.$refs.map.state.mapObjectId) {
          this.$set(this.$refs.map.state, 'customObject', null);
          this.$refs.map.state.removeFromMap(['custom']);
        }

        if ((!oldValue && !this.moId) || this.moId !== value) {
          const object = value
            ? await this.$eva.$http.getItem(
                `/api/v1/iagentservice/monitoringobject/${value}`
              )
            : null;

          if (
            !this.mapObjectId
            && object
            && object.ref_mapsource
            && object.ref_mapsource.id
          ) {
            this.$set(this.$data, 'mapObjectId', value);
          }

          if (this.$refs.map.state.selectedMonitoringObject) {
            this.$set(this.$refs.map.state, 'selectedMonitoringObject', null);
          }


          this.$set(this.$refs.map.state, 'selectedMonitoringObject', value);
          this.$set(this.$data, 'moId', object && object.id || null);
        }
      }
    },
    moId: {
      async handler(value, oldValue) {
        if (value) {
          this.$set(this.$data, 'moTreeId', value);
        }
      }
    },
  },
  mounted() {
    watch(
      () => this.mapHaveCustomObject,
      async (value) => {
        if (value) {
          this.$set(
            this.$data,
            'moTreeId',
            this.$eva.$tools.clone(this.mapObjectId)
          );
        }
      }
    )
  },
}
</script>
