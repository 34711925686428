<template>
  <div>
    {{ title }}
    <div class="eva-checkbox" :class="classes">
      <label
          class="eva-checkbox__label"
          @click="onClick"
      >
        <div class="eva-checkbox__icon mdi" :class="valueIcon"></div>
        <span class="eva-checkbox__text">{{ label }}</span>
      </label>
    </div>
  </div>
</template>

<script>
import EvaInputMixin from "./EvaInputMixin";

export default {
  name: 'eva-checkbox',

  props: {
    tristate: {
      type: Boolean,
      default: false
    },
    error: {
    }
  },

  mixins: [
    EvaInputMixin
  ],

  computed: {
    valueIcon() {
      if (this.currentValue === true) {
        return 'mdi-check-bold';
      }
      if (this.currentValue === false || !this.tristate) {
        return '';
      }
      return 'mdi-minus-thick';
    },
    classes() {
      return {
        'eva-checkbox--error': !!this.error,
        'eva-checkbox--readonly': !!this.readonly
      }
    }
  },

  methods: {
    onClick() {
      if (this.readonly) {
        return;
      }
      if (this.tristate) {
        if (this.currentValue === true) {
          this.currentValue = null;
        } else if (this.currentValue === false) {
          this.currentValue = true;
        } else {
          this.currentValue = false;
        }
      } else {
        this.currentValue = !this.currentValue;
      }
    }
  }
}
</script>

<style lang="less">
.eva-checkbox {
  min-height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.eva-checkbox--error {

  }
  &.eva-checkbox--readonly {
    .eva-checkbox__text {
      opacity: 0.6;
    }
  }
}
</style>
