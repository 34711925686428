<template>
  <span
      class="eva-map-tools-panel"
      :style="styles"
  >
    <span class="eva-map-tools-panel__inner eva-background-2">
      <slot/>
      <eva-command-list
          v-if="commands"
          :commands="commands"
          :column="vertical"
          type="icon--flat"
      />
    </span>
  </span>
</template>

<script>
export default {
  name: 'eva-map-tools-panel',

  props: {
    commands: {
      type: Array
    },
    top: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    styles() {
      return {
        top: this.top
            ? this.$eva.$styles.evaPadding.formatValue()
            : null,
        bottom: this.bottom
            ? this.$eva.$styles.evaPadding.formatValue()
            : null,
        left: this.left
            ? this.$eva.$styles.evaPadding.formatValue()
            : null,
        right: this.right
            ? this.$eva.$styles.evaPadding.formatValue()
            : this.bottom
              ? '64px'
              : null
      }
    }
  }
}
</script>

<style lang="less">
.eva-map-tools-panel {
  position: absolute;
  display: flex;
  overflow: hidden;
  z-index: 10;
  .eva-map-tools-panel__inner {
    display: flex;
    gap: 8px;
    border-radius: 6px;
    opacity: 0.9;
    padding: (@eva-padding / 2);
    align-items: center;
    max-width: 100%;
    .eva-btn {
      width: 32px;
      min-width: 32px;
      height: 32px;
      flex-shrink: 0;
      /*background-color: #FFFFFF;*/
    }
  }
}
</style>
