
    import localesHash from '@eva/client/plugins/locales/LocalesHash';
    export default function (Component) {
      localesHash.push({ 
        lang: 'ru', 
        data: {
          [Component.options.name]: 
{
  caption: 'Стили рисования',
  columns: {
    name: {
      header: 'Название'
    },
    ref_feature_type: {
      header: 'Тип стиля'
    },
    sort_order: {
      header: 'Сортировка'
    },
    ref_fileobject: {
      header: 'Файл изображения'
    }
  },
  commands: {
    add: {
      header: 'Создать стиль рисования',
      success: 'Стиль рисования создан успешно',
      error: 'При создании стиля рисования произошла ошибка'
    },
    edit: {
      header: 'Редактировать стиль рисования',
      success: 'Стиль рисования отредактирован успешно',
      error: 'При редактировании стиля рисования произошла ошибка'
    },
    remove: {
      header: 'Удалить стиль рисования',
      confirm: 'Удалить стиль рисования ?',
      success: 'Стиль рисования удален успешно',
      error: 'При удалении стиля рисования произошла ошибка'
    }
  }
}

        } 
      });
    }