<template>
  <eva-btn
    :label="commandLabel"
    :tooltip="commandTooltip"
    :icon="commandIcon"
    :type="commandType"
    :class="$options.name"
  />
</template>

<script>
export default {
  name: 'eva-command-field',

  dbType: 'str',

  computed: {
    commandLabel() {
      return this.settings && this.settings.command && this.settings.command.name;
    },
    commandTooltip() {
      return this.settings && this.settings.command && this.settings.command.description;
    },
    commandIcon() {
      return this.settings && this.settings.command && this.settings.command.button_icon;
    },
    commandType() {
      return this.settings && this.settings.command && this.settings.command.button_type && this.settings.command.button_type.id;
    }
  }
}
</script>

<style lang="less">
.eva-command-field {
  max-height: 40px;
  align-self: end;
}
</style>
