<template>
  <eva-map-tools-panel top left :commands="commands"/>
</template>

<script>
import EvaMapToolsPanel from "./EvaMapToolsPanel";
import EvaMapFilter from "./EvaMapFilter";
import EvaMapSourceViewList from "./EvaMapSourceViewList";

const MAP_SHOW_NAMES = 'MAP_SHOW_NAMES';

export default {
  name: 'eva-map-common-panel',

  components: {
    EvaMapToolsPanel
  },

  props: {
    state: {
      type: Object
    },
    hideMapsourceViews: {
      type: Boolean,
      default: false
    }
  },

  data(){
    return {
      commands: this.$eva.$commands.createList([
        {
          prefix: `${this.$options.name}.commands`,
          name: 'showFilter',
          icon: 'mdi-layers',
          handle: (model, event) => this.showFilter(event)
        },
        this.hideMapsourceViews ? null : {
          prefix: `${this.$options.name}.commands`,
          name: 'showMapsourceViews',
          icon: 'mdi-map-marker-multiple',
          handle: (model, event) => this.showMapsourceViews(event)
        }
      ].filter((c) => !!c))
    }
  },

  watch: {
    'state.mapSourceId': {
      handler(value) {
        const noMapSource = !value;
        this.commands[0].disabled = noMapSource;
      },
      immediate: true
    }
  },

  methods: {
    async showFilter(event) {
      await this.$eva.$boxes.show({
        type: 'dropdown',
        activator: event.target,
        anchor: 'bottom-left',
        header: 'Фильтр',
        closeOnCLick: true,
        component: EvaMapFilter,
        componentProps: {
          state: this.state
        }
      });
    },
    showMapsourceViews(event) {
      this.$eva.$boxes.show({
        type: 'dropdown',
        activator: event.target,
        anchor: 'bottom-left',
        header: 'Быстрые переходы',
        closeOnCLick: true,
        component: EvaMapSourceViewList,
        componentProps: {
          readonly: !this.state.mapObjectId,
          state: this.state
        }
      });
    }
  }
}
</script>

<locale lang="ru">
{
  commands: {
    showFilter: {
      header: 'Фильтр'
    },
    showMapsourceViews: {
      header: 'Быстрые переходы'
    }
  }
}
</locale>
