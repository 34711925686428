import EvaValidator from "./EvaValidator";

class EvaSwitchValidator extends EvaValidator {
  constructor(app) {
    super(app, 'eva-switch-validator');
  }

  async validateInternal(value, args, model) {
    const errors = [];
    const keys = Object.keys(args);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      switch (key) {
        case 'type':
        case 'validator':
          continue;
        default:
          let fieldValue = this.app.$tools.getNestedValue(model, key);
          let validatorSwitch = args[key];
          let validatorSettings = fieldValue != null
            ? validatorSwitch[fieldValue]
            : validatorSwitch.defaultValue;
          if (validatorSettings) {
            if (Array.isArray(validatorSettings)) {
              for (let i = 0; i < validatorSettings.length; i++) {
                let error = await this.app.$metadatas
                  .getFieldValidator(validatorSettings[i])
                  .validate(value, validatorSettings[i], model);
                if (error) {
                  errors.push(error);
                }
              }
            } else {
              let error = await this.app.$metadatas
                .getFieldValidator(validatorSettings)
                .validate(value, validatorSettings, model);
              if (error) {
                errors.push(error);
              }
            }
          }
      }
    }
    return errors;
  }
}

export default EvaSwitchValidator;
