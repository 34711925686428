import EvaValidator from "./EvaValidator";

class EvaPasswordValidator extends EvaValidator {
  constructor(app) {
    super(app, 'eva-password-validator');
  }

  async validateInternal(value, args) {
    value = (value || '').toString().trim();
    if (!value) {
      return;
    }
    let res = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/g.exec(value);
    return !res ? '$t.core.errors.fields.password' : '';
  }
}

export default EvaPasswordValidator;
