<template>
  <div>
    <span class="eva-textbox__label eva-foreground-3"> {{ col.label }} </span>
    <span class="eva-textbox__input .eva-description" :class="col?.descriptionColor">
      {{ datetime }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'dateTime',

  props: {
    col: {
      type: Object,
      default: () => { },
    },
  },
  /* 
  example
  
  col1: {
    type: 'dateTime',
    label: 'Начало',
    description: 1719467813288
  }
  
  */
  computed: {
    datetime() {
      let datetime = new Date(this.col.description),
        outText = datetime.toLocaleString();
      if (outText === 'Invalid Date') outText = '-';
      return outText;
    }
  }

}
</script>

<style lang="less" scoped>
.etv-rowLabel {
  color: #fff
}

.eva-label {
  color: #7B8B9D;
}

.eva-description {
  color: #E4E9EF;
}

.green-1 {
  color: #3cb17e;
}
</style>