<template>
  <div class="eva-json-editor">
    <v-select
      :label="$eva.$t(label)"
      v-model="temp"
      :items="[1]"
      readonly
      dense
      outlined
    >
      <template v-slot:selection="">
        <vue-json-editor
          ref="json_editor"
          :value="currentValue"
          mode="code"
          :modes="['code']"
          :show-btns="false"
          :exapndedOnStart="true"
          style="width: 100%; height: 100%"
          @json-change="$emit('input', $event)"
        />
      </template>
    </v-select>
  </div>
</template>

<script>
import vueJsonEditor from "vue-json-editor";

export default {
  name: 'eva-json-editor',

  components: {
    vueJsonEditor
  },

  props: {
    value: {
      type: Object
    },
    label: {
      type: String
    }
  },

  data() {
    return {
      temp: 1,
      currentValue: null
    }
  },

  watch: {
    value: {
      handler(value) {
        this.currentValue = value;
      },
      immediate: true
    }
  }

}
</script>

<style lang="less">
.eva-json-editor {
  height: 100%;
  width: 100%;

  .v-select__selections {
    padding: 12px 0!important;
    input {
      display: none;
    }
  }
  .v-input {
    background-color: #F5F5F5!important;
  }
  .v-input,
  .v-input__control,
  .v-input__slot,
  .v-select__selections {
    height: 100%!important;
  }
  .v-text-field__details {
    display: none;
  }
  .v-input__slot {
    /*padding: 0 2px!important;*/
  }
  .v-input__append-inner {
    display: none;
  }
  .jsoneditor-menu {
    display: none;
  }
  .jsoneditor {
    border: none;
  }
  .ace_scroller {
    background-color: #F5F5F5!important;
  }
  .jsoneditor-vue {
    height: 100%;
  }
  .jsoneditor-tree{
    min-height: 100%;
  }
  .jsoneditor-outer{
    min-height:100%;
    margin: 0;
    padding: 0;
  }
}
</style>
