import EvaValidator from "./EvaValidator";

class EvaCustomValidator extends EvaValidator {
  constructor(app) {
    super(app, 'eva-custom-validator');
  }

  async validateInternal(value, args, model, settings) {
    return await args.validate(value, args, model, settings);
  }
}

export default EvaCustomValidator;
