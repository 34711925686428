import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import EvaAppError from "@eva/client/plugins/errors/EvaAppError";

class ErrorsEvaPlugin extends EvaPlugin {
  constructor(app) {
    super(app);
  }

  throw(code, ...args) {
    throw new EvaAppError(this.app, code, args);
  }
}

export default ErrorsEvaPlugin;
