<template>
  <div class="eva-command-list" :class="classes">
    <eva-btn
      v-for="command in currentCommands"
      v-if="command.visible"
      :key="command.name"
      :command="prepareCommand(command)"
      :type="command.type || type"
      @click.stop.prevent="executeCommand(command, $event)"
      @mousedown.stop.prevent
      ref="items"
    />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'eva-command-list',

  props: {
    type: {
      type: String
    },
    commands: {
      type: Array
    },
    model: {
      type: Object
    },
    column: {
      type: Boolean,
      default: false
    },
    noGap: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentCommands: []
    }
  },

  computed: {
    classes() {
      return {
        'eva-command-list--column': this.column,
        'eva-command-list--no-gap': this.noGap
      }
    }
  },

  watch: {
    commands: {
      handler() {
        this.currentCommands = this.$eva.$commands.createList(this.commands);
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    prepareCommand(item) {
      if (this.model && this.model.system_flag === true) {
        const command = cloneDeep(item);
        if (command.name === 'edit') {
          command.disabled = true;
        } 
        if (command.name === 'remove') {
          command.disabled = true;
        }
        return command;
      } else {
        return item;
      }
    },
    async executeCommand(command, event) {
      if (this.$listeners['before-command']) {
        this.$emit('before-command', { command, done: async (cancel) => {
          if (!cancel) {
            let result = await command.execute(this.model, event);
            this.$emit('on-command', result);
          }
        }
        });
      } else {
        let result = await command.execute(this.model, event);
        this.$emit('on-command', result);
      }
    }
  }
}
</script>

<style lang="less">
.eva-command-list {
  display: flex;
  gap: @eva-padding;
  &.eva-command-list--no-gap {
    gap: 0;
  }
  &.eva-command-list--column {
    flex-direction: column;
    .eva-btn {
      .v-btn__content {
        justify-content: flex-start;
      }
    }
  }
}
</style>
