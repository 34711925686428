import shaka from "shaka-player";
import EvaVideoSource from "./EvaVideoSource";

const SHAKA_CONFIG = {
  manifest: {
    retryParameters: {
      timeout: 20000,
      stallTimeout: 5000,
      connectionTimeout: 20000,
      maxAttempts: 2,
      baseDelay: 1000,
    },
  },
};

class EvaShakaVideoSource extends EvaVideoSource {
  constructor(source, paused, settings) {
    super(source, paused, settings);
    this.shaka = null;
  }

  getDefaultSupports() {
    return {
      screenshot: true,
      fullscreen: true,
      fit: true,
      play: true,
      pause: true,
    };
  }

  async playInternal(restart) {
    return new Promise(async (resolve, reject) => {
      try {
        if (restart) {
          const channel = await this.getChannel();
          this.shaka = new shaka.Player();
          this.shaka.configure(SHAKA_CONFIG);

          await this.shaka.attach(this.video);
          this.setEventsListeners();
          const url = this.getSourceUrl(channel.url);
          try {
            await this.shaka.load(url);
          } catch (error) {
            this.onErrorRaised(error);
          }
          resolve();
        } else {
          resolve();
        }
        this.video.play();
      } catch (error) {
        reject(error);
      }
    });
  }

  setEventsListeners() {
    if (this.shaka) {
      this.shaka.addEventListener("error", this.onErrorRaised);
      this.shaka.addEventListener("buffering", this.onBuffering);
    }
  }

  removeEventListeners() {
    if (this.shaka) {
      this.shaka.removeEventListener("error", this.onErrorRaised);
      this.shaka.removeEventListener("buffering", this.onBuffering);
    }
  }

  onBuffering = (event) => {
    this.state.isLoading = event.buffering;
  };

  onErrorRaised = (error) => {
    this.state.isError = true;
    this.state.isLoading = false;
    this.app.$logs.error("EvaShakaVideoSource", "Error occurred:", error);
    this.clearInternal();
  };

  clearInternal() {
    if (this.shaka) {
      this.removeEventListeners();
      this.shaka.unload();
      this.shaka.destroy();
      this.shaka = null;
    }
  }

  async getChannel() {
    const { stream_id, begin_time, end_time, timeout } = this.source;
    return await this.app.$http.post(
      `/api/v1/videohubservice/integrationapi/archive/${stream_id}`,
      {
        begin_time,
        end_time,
        timeout,
      }
    );
  }

  getSourceUrl(url) {
    return url.replace("webrtc", "hls/live/index.m3u8");
  }
}

export default EvaShakaVideoSource;
