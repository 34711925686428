<template>
  <div
      v-if="cells"
      :placeholder="placeholder"
      :style="style"
      class="eva-grid-cells"
  >
    <div
        v-for="(cell, index) in cells"
        :key="index"
        class="eva-grid_cell"
        :style="cell.style"
    >
      {{ cell.content }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'eva-grid-cells',

  props: {
    placeholder: {
      type: String
    },
    rowCount: {
      type: Number
    },
    columnCount: {
      type: Number
    },
    selection: {
      type: Object
    }
  },

  data() {
    return {
      cells: null
    }
  },

  watch: {
    rowCount() {
      this.updateCells();
    },
    columnCount() {
      this.updateCells();
    },
    selection: {
      handler() {
        this.updateSelection();
      },
      deep: true
    }
  },

  computed: {
    style() {
      return {
        'grid-template-rows': `repeat(${this.rowCount}, 1fr)`,
        'grid-template-columns': `repeat(${this.columnCount}, 1fr)`
      }
    }
  },

  methods: {
    updateCells() {
      if (this.rowCount > 0 && this.columnCount > 0) {
        let cells = [];
        for (let i = 0; i < this.rowCount; i++) {
          for (let j = 0; j < this.columnCount; j++) {
            let style = {
              gridArea: `${i + 1} / ${j + 1} / ${i + 2} / ${j + 2}`,
              backgroundColor: null
            };
            if (i === 0) {
              //style.borderTop = '1px grey dashed';
              //style.marginTop = '-1px';
            }
            if (j === 0) {
              //style.marginLeft = '-1px';
              //style.borderLeft = '1px grey dashed';
            }
            cells.push({
              style,
              content: `${i + 1} : ${j + 1}`
            });
          }
        }
        this.cells = cells;
      } else {
        this.cells = [];
      }
    },
    updateSelection() {
      if (this.selection && !this.selection.isEmpty()) {
        for (let i = 0; i < this.rowCount; i++) {
          for (let j = 0; j < this.columnCount; j++) {
            let index = i * this.columnCount + j;
            this.cells[index].style.backgroundColor = this.selection.contains(j, i)
                ? '#FFC107'
                : null;
          }
        }
      } else {
        for (let i = 0; i < this.cells.length; i++) {
          this.cells[i].style.backgroundColor = null;
        }
      }
    }
  },

  mounted() {
    this.updateCells();
  }
}
</script>

<style lang="less">
.eva-grid-cells {
  display: grid;
  height: 100%;
  box-sizing: border-box;
  grid-gap: @eva-grid-gap;
  &:before {
    content: attr(placeholder);
    position: absolute;
    top: 50%;
    left: 50%;
    /*color: rgba(0,0,0,0.1);*/
    font-size: 56px;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  .eva-grid_cell {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    /*color: rgba(0,0,0,0.2);*/
    outline: @eva-grid-gap #90A4AE dashed;
  }
}
</style>
