<template>
  <eva-map-tools-panel top right :commands="commands">
    <eva-media-text
        v-for="(style, index) in selectedDrawStyles"
        :key="index"
        :size="32"
        :icon="getFeatureTypeIcon(style)"
        :image="style.download_url"
        v-tooltip="style.name"
        :style="{ opacity: state.selectedDrawStyle === style ? 1 : 0.6 }"
        @click.stop="state.selectedDrawStyle = state.selectedDrawStyle === style ? null : style"
    />
  </eva-map-tools-panel>
</template>

<script>
import EvaMapToolsPanel from "./EvaMapToolsPanel";
import EvaMapDrawStyleList from "./EvaMapDrawStyleList";

const SELECTED_DRAW_STYLES = 'SELECTED_DRAW_STYLES';

export default {
  name: 'eva-map-draw-styles-panel',

  components: {
    EvaMapToolsPanel
  },

  props: {
    state: {
      type: Object
    }
  },

  data(){
    return {
      selectedDrawStyles: [],
      featureTypes: [],
      commands: this.$eva.$commands.createList([
        {
          prefix: `${this.$options.name}.commands`,
          name: 'addDrawStyle',
          icon: 'mdi-plus',
          handle: (model, event) => this.addDrawStyle(event)
        }
      ])
    }
  },

  methods: {
    async addDrawStyle(event) {
      let style = await this.$eva.$boxes.show({
        type: 'dropdown',
        activator: event.target,
        header: 'Добавление стиля рисования',
        closeOnCLick: true,
        component: EvaMapDrawStyleList,
        componentProps: {}
      });
      if (style !== 'cancel' && typeof style === 'object') {
        let item = this.selectedDrawStyles.find((s) => s.id === style.id);
        if (item) {
          let index = this.selectedDrawStyles.indexOf(item);
          this.selectedDrawStyles.splice(index, 1);
        }
        while (this.selectedDrawStyles.length > 9) {
          this.selectedDrawStyles.splice(this.selectedDrawStyles.length - 1, 1);
        }
        this.selectedDrawStyles.splice(0, 0, style)
        this.$eva.$storages.local.set(SELECTED_DRAW_STYLES, this.selectedDrawStyles.map(({ id }) => id));
      }
    },
    getFeatureTypeIcon(item) {
      if (item.download_url || !item.ref_feature_type) {
        return null;
      }
      let type = this.featureTypes.find((t) => t.id === item.ref_feature_type.id);
      return type && type.params && type.params.icon;
    }
  },

  async mounted() {
    this.featureTypes = await this.$eva.$http.getListItems('/api/v1/core/catalog', {
      q: {
        ref_catalog_type: 'CatalogType:FeatureType'
      }
    });
    const ids = this.$eva.$storages.local.get(SELECTED_DRAW_STYLES) || [];
    const selectedDrawStyles = [];
    for (let i = 0; i < ids.length; i++) {
      let drawStyle = null;
      try {
        drawStyle = await this.$eva.$http.getItem(`/api/v1/mapsservice/drawstyle/${ids[i]}`);
      } catch {
        drawStyle = null;
      }
      if (drawStyle) {
        selectedDrawStyles.push(drawStyle);
      }
    }
    this.selectedDrawStyles = selectedDrawStyles;
  }
}
</script>

<locale lang="ru">
{
  commands: {
    addDrawStyle: {
      header: 'Добавить стиль рисования'
    }
  }
}
</locale>
