import { computed, ref, toRefs, readonly } from "vue";

class EvaMetaError extends Error {

  static FIELD_WRONG_TYPE = 'core.meta.errors.fieldWrongType';
  static FIELD_NO_VALUE = 'core.meta.errors.fieldNoValue';

  constructor(code, message) {
    super(message);
    this.code = code;
  }
}

class EvaMetaField {
  constructor({ name, entity, validators }) {
    this.name = name;
    this.entity = entity;
    this.validators = validators;
  }

  getValue(model) {
    return model[this.name];
  }

  setValue(model, value) {
    model[this.name] = value;
  }

  getDefaultValue() {

  }
}

class EvaBooleanMetaField extends EvaMetaField {
  constructor(settings) {
    super(settings);
  }
}

class EvaIntegerMetaField extends EvaMetaField {
  constructor(settings) {
    super(settings);
  }
}

class EvaFloatMetaField extends EvaMetaField {
  constructor(settings) {
    super(settings);
  }
}

class EvaTextMetaField extends EvaMetaField {
  constructor(settings) {
    super(settings);
  }
}

class EvaArrayMetaField extends EvaMetaField {
  constructor(settings) {
    super(settings);
    this.itemType = settings.itemType;
  }

  getLength(model) {
    this.getValue(model).length;
  }

  add(model, item) {
    this.getValue(model).push(item);
  }

  remove(model, item) {
    let value = this.getValue(model);
    let index = value.indexOf(item);
    value.splice(index, 1);
  }
}

class EvaEntityMetaField extends EvaMetaField {
  constructor(settings) {
    super(settings);
    this.itemEntity = settings.itemEntity;
  }
}

const EvaMetaFields = Object.freeze({
  boolean: EvaBooleanMetaField,
  integer: EvaIntegerMetaField,
  float: EvaFloatMetaField,
  text: EvaTextMetaField
})


function createMetaScope(app) {

  app.$locales.register('ru', {
    core: {
      meta: {
        errors: {
          fieldWrongType: `Неверный тип поля '{1}' для сущности '{0}': {2}`,
          fieldNoValue: `Значение поля '{1}' для сущности '{0}' не указано`
        }
      }
    }
  })


  const scope = Object.freeze({
    app,
    getEntity,
    createEntity
  });

  const entities = {};

  function throwError(code, ...args) {
    throw new EvaMetaError(code, app.$t(`$t.${code}`, ...args));
  }

  function parseMetaName(metaName, metaType) {
    if (metaName == null) {
      throwError(EvaMetaError.FIELD_NO_VALUE, metaType, 'name');
    }
    if (typeof metaName !== 'string') {
      throwError(EvaMetaError.FIELD_WRONG_TYPE, metaType, 'name', typeof metaName);
    }
    metaName = metaName.trim();
    if (!metaName) {
      throwError(EvaMetaError.FIELD_NO_VALUE, metaType, 'name');
    }

    const parts = metaName.split('.');
    const name = ref(parts[parts.length - 1]);
    const path = ref(parts.slice(0, -1).join('.'));
    const fullName = ref(parts.join('.'));

    return readonly({
      name,
      path,
      fullName
    });
  }

  function createField(settings) {
    if (settings == null) {
      throwError(EvaMetaError.FIELD_NO_VALUE, 'field', 'name');
    }
    if (typeof settings !== 'object') {
      throwError(EvaMetaError.FIELD_WRONG_TYPE, 'field', 'settings', typeof settings);
    }

    let { name, path, fullName } = parseMetaName(settings.name, 'field');

    const [type, ...args] = settings.type && settings.type.split('--') || [];
    const isArray = args.indexOf('array') >= 0;

    return readonly({
      name,
      path,
      fullName
    });

  }

  /*console.log(createField({ name: 'name' }))
  console.log(createField({ name: 'person.name' }))
  console.log(createField({ name: 'person.name.ru' }))*/

  function getEntity(name) {
    return entities[name];
  }
  function createEntity(name, settings) {
    let result = new EvaMetaEntity({ scope, name, ...settings });
    entities[name] = result;
    Object.freeze(result);
    return result;
  }

  return scope;
}

class EvaMetaEntity {
  constructor({ scope, name, fields }) {
    this.scope = scope;
    this.name = name;
    this.fields = this.scope.app.$tools.mapObjectOrArray(fields, (field, name) => {
      const [type, ...args] = field.type.split('--');
      const isArray = args.indexOf('array') >= 0;
      const EvaMetaField = EvaMetaFields[type];
      if (EvaMetaField) {
        if (isArray) {
          return new EvaArrayMetaField({ ...field, itemType: new EvaMetaField({ ...field }) });
        } else {
          return new EvaMetaField({ ...field });
        }
      }
      let itemEntity = scope.getEntity(name);
      if (itemEntity) {
        if (isArray) {
          return new EvaArrayMetaField({ ...field, itemType: new EvaEntityMetaField({ ...field, itemEntity }) });
        } else {
          return new EvaEntityMetaField({ ...field, itemEntity });
        }
      }
    });
  }
}





export default (app) => {

  try {

    let scope = createMetaScope(app);

    let personEntity = scope.createEntity('person', {
      fields: {
        first_name: {
          type: 'text'
        },
        last_name: {
          type: 'text'
        },
        middle_name: {
          type: 'text'
        },
        age: {
          type: 'integer'
        }
      }
    });

    //console.log(personEntity)
  } catch (error) {
    //console.error(error);
  }
}






