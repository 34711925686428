<template>
  <eva-textbox
    v-show="!isHide"
    v-model="currentValue"
    :label="fieldLabel"
    :error="fieldErrors"
    :title="fieldTitle"
    :placeholder="fieldPlaceholder"
    :readonly="disabled || readOnly"
    :preview="preview"
    :depth="depth"
  />
</template>

<script>
export default {
  name: 'eva-number-field',

  dbType: 'int',

  computed: {
    currentValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.customError = null;
        if (value != null) {
          if (typeof value !== 'number') {
            value = value.toString().trim();
            if (value) {
              let newValue = parseInt(value);
              if (!/^-?\d+$/.test(value) || isNaN(newValue) || typeof newValue !== 'number') {
                this.customError = 'Введено некорректное целое число';
              } else {
                value = newValue;
                this.customError = null;
              }
            } else {
              value = null;
            }
          }
        }
        this.modelValue = value;
      }
    }
  }
}
</script>
