import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eva-video__header"},[_c('div',{staticClass:"eva-video__header-left"},[(
        _vm.settings.monitoringObjectList && _vm.settings.monitoringObjectList.length
      )?_c(VMenu,{attrs:{"disabled":_vm.settings.disabled || _vm.disabled,"offset-y":"","attach":""},on:{"input":_vm.onShowMOList},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":"","outlined":"","color":"white"}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"margin":"auto"}},[_vm._v(_vm._s(_vm.currentMonitoringObject?.name))]),_vm._v(" "),(!_vm.settings.disabled && _vm.showState && !_vm.isFullscreen)?_c(VIcon,[_vm._v("\n            mdi-chevron-down\n          ")]):_vm._e()],1)]}}],null,false,350906382)},[_vm._v(" "),_c(VList,{style:(_vm.monitoringObjectListStyle),attrs:{"dense":""}},_vm._l((_vm.settings.monitoringObjectList),function(monitoringObject,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.onSetMonitoringObject(monitoringObject)}}},[_c(VListItemTitle,[_vm._v(_vm._s(monitoringObject.name))])],1)}),1)],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"eva-video__header-right"},[(
        _vm.showState &&
        _vm.currentMonitoringObject?.status &&
        _vm.currentMonitoringObject?.status?.ACT
      )?_c('eva-btn',{attrs:{"icon":"mdi-circle","type":"icon-text--secondary--small","label":"Rec","tooltip":"Ведётся запись"}}):_vm._e(),_vm._v(" "),(
        _vm.showState &&
        _vm.currentMonitoringObject?.status &&
        _vm.currentMonitoringObject?.status?.ARM
      )?_c('eva-btn',{staticClass:"ml-2",attrs:{"icon":"mdi-shield-check","type":"icon--secondary--small","tooltip":"На охране"}}):_vm._e(),_vm._v(" "),_c('eva-video-header-clock',{directives:[{name:"show",rawName:"v-show",value:(_vm.showState),expression:"showState"}]}),_vm._v(" "),(!_vm.isFullscreen && _vm.settings.tuning)?_c('eva-btn',{staticClass:"ml-2",attrs:{"icon":"mdi-close","type":"icon--secondary--small","tooltip":"Убрать с панели"},on:{"click":_vm.onRemoveItemFromPanel}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }