import EvaValidator from "./EvaValidator";

class EvaRequireValidator extends EvaValidator {

  static create(app, name, regexp) {
    let result = new EvaRequireValidator(app, `eva-${name}-validator`);
    result.value = regexp;
    result.message = `$t.core.errors.fields.${name}`;
    return result;
  }

  constructor(app, name = 'eva-regexp-validator') {
    super(app, name);
  }

  async validateInternal(value, args) {
    value = (value || '').toString().trim();
    if (!value) {
      return;
    }
    let res = new RegExp(this.value || args.value).exec(value);
    return !res ? (args.message || this.message || '$t.core.errors.fields.regexp') : '';
  }
}

export default EvaRequireValidator;
