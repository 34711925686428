<template>
  <div
    class="eva-text"
    :class="classes"
    :style="styles"
    @click="onClick($event)"
    @mousedown="onMouseDown($event)"
  >
    <slot>
      {{ $eva.$t(text) }}
    </slot>
  </div>
</template>

<script>
export default {
  name: 'eva-text',

  props: {
    text: {

    },
    bolder: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    /*color: {
      type: String
    },*/
    size: {
      type: String,
      default: '1rem'
    },
    noWrap: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: false
    },
    subheader: {
      type: Boolean,
      default: false
    },
    italic: {
      type: Boolean,
      default: false
    },
    clipped: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'left',
    },
  },

  computed: {
    classes() {
      return {
        'eva-text--bolder': this.bolder,
        'eva-text--bold': this.bold,
        'eva-text--no-wrap': this.noWrap,
        'eva-text--italic': this.italic,
        'eva-text-clipped': this.clipped,
        'eva-text--header': this.header,
        'eva-text--center': this.center,
        'eva-text--subheader': this.subheader,
        'eva-text--subheader_right': this.position === 'right',
        'eva-text--subheader_center': this.position === 'center',
      }
    },
    styles() {
      return (!this.header && !this.subheader) ? {
        color: this.color,
        fontSize: this.size
      } : null;
    },
    hasClickListener() {
      return !!(this.$listeners && this.$listeners.click);
    }
  },

  methods: {
    onClick(e) {
      if (this.hasClickListener) {
        e.stopPropagation();
        e.preventDefault();
      }
      this.$emit('click', e);
    },
    onMouseDown(e) {
      if (this.hasClickListener) {
        e.stopPropagation();
        e.preventDefault();
      }
      this.$emit('mousedown', e);
    }
  }
}
</script>

<style lang="less">
.eva-text {
  align-items: center;
  margin: auto 0;
  text-transform: none!important;
  &.eva-text--bolder {
    font-weight: bolder;
  }
  &.eva-text--bold {
    font-weight: bold;
  }
  &.eva-text--italic {
    font-style: italic;
  }
  &.eva-text--no-wrap {
    white-space: nowrap;
  }
  &.eva-text--center {
    margin: auto;
  }
  &.eva-text--header {
    /*font-weight: 600;*/
    font-size: 16px;
    align-self: flex-start;
    line-height: 16px;
    /*color: #0260CF;*/
  }
  &.eva-text--subheader {
    font-weight: 400;
    font-size: 14px;
    align-self: flex-start;
    line-height: 14px;
    /*color: #0260CF;*/
  }
  &.eva-text--subheader.eva-text--subheader_right {
    align-self: flex-end !important;
  }
  &.eva-text--subheader.eva-text--subheader_center {
    align-self: flex-end !important;
  }
}
</style>
