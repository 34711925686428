<template>
  <eva-map-tools-panel bottom right vertical :commands="commands"/>
</template>

<script>
import EvaMapToolsPanel from "./EvaMapToolsPanel";

export default {
  name: 'eva-map-view-panel',

  components: {
    EvaMapToolsPanel
  },

  props: {
    state: {
      type: Object
    },
    homeCommand: {
      type: Object
    }
  },

  data(){
    return {
      commands: this.$eva.$commands.createList([
        {
          prefix: `${this.$options.name}.commands`,
          name: 'zoomIn',
          icon: 'mdi-plus',
          handle: () => this.state.zoomIn()
        },
        {
          prefix: `${this.$options.name}.commands`,
          name: 'zoomOut',
          icon: 'mdi-minus',
          handle: () => this.state.zoomOut()
        },
        {
          prefix: `${this.$options.name}.commands`,
          name: 'fitToScreen',
          icon: 'mdi-fit-to-screen',
          handle: () => this.state.fitToScreen()
        },
        this.homeCommand || {
          prefix: `${this.$options.name}.commands`,
          name: 'goHome',
          icon: 'mdi-home',
          handle: () => this.state.goHome()
        },
        {
          name: 'toggleFullscreen',
          label: () => this.state.isFullScreen
              ? this.$t(`$t.${this.$options.name}.commands.toggleFullscreen.exit`)
              : this.$t(`$t.${this.$options.name}.commands.toggleFullscreen.enter`),
          icon: 'mdi-aspect-ratio',
          handle: () => this.state.toggleFullscreen()
        }
      ])
    }
  },

  watch: {
    'state.mapSourceId': {
      handler(value) {
        const noMapSource = !value;
        this.commands[0].disabled = noMapSource;
        this.commands[1].disabled = noMapSource;
        this.commands[2].disabled = noMapSource;
        this.commands[3].disabled = noMapSource;
        this.commands[4].disabled = noMapSource;
      },
      immediate: true
    }
  }
}
</script>

<locale lang="ru">
{
  commands: {
    zoomIn: {
      header: 'Увеличить масштаб'
    },
    zoomOut: {
      header: 'Уменьшить масштаб'
    },
    fitToScreen: {
      header: 'Показать всю карту'
    },
    goHome: {
      header: 'Перейти к закрепленному положению карты'
    },
    toggleFullscreen: {
      exit: 'Выйти из полноэкранного режима',
      enter: 'Войти в полноэкранный режим'
    }
  }
}
</locale>
