<template>
  <eva-layout row transparent class="eva-icon-text-table-cell">
    <eva-icon :icon="currentIcon" :color="foregroundColor"/>
    <eva-text
      v-if="showText"
      :color="foregroundColor"
      :text="currentText"
      :class="{ 'eva-text-clipped': isClipped }"
      v-tooltip="$eva.$tools.tooltipContent(currentText)"
    />
  </eva-layout>
</template>

<script>
import EvaTableCellMixin from "./EvaTableCellMixin";

export default {
  name: 'eva-icon-text-table-cell',

  mixins: [
    EvaTableCellMixin
  ],

  computed: {
    currentIcon() {
      if (this.column.icon === true) {
        return this.model.icon;
      }
      if (typeof this.column.icon === 'string') {
        if (this.column.icon.startsWith('mdi-')) {
          return this.column.icon;
        } else {
          return this.model[this.column.icon];
        }
      }
      if (typeof this.column.icon === 'object') {
        return this.switchValue(this.column.icon);
      }
    },
    currentText() {
      return this.$eva.$tools.getNestedValue(this.currentValue, this.column.path);
    },
    showText() {
      return this.column.text !== false;
    }
  }
}
</script>

<style lang="less">
.eva-icon-text-table-cell {
  align-items: center;
}
</style>
