<template>

  <div class="etvContainer">
    <div v-for="(row, rowKey) in dataModel" v-show="emptyRow(row)" class="etv-row" :key="rowKey">
      <div v-for="(col, colKey) in row" :key="colKey" class="etv-col">
        <component :is="componentSelect(col)" v-bind:col="col" />
      </div>
    </div>
  </div>

</template>

<script>
// обычная разброска копонентов по флексу пример снизу
//компонент для отображения текста на странице в соответсвии с блочной версткой
import labelDescription from './components/LabelDescriptionEtv.vue';
import labelEtv from './components/LabelEtv.vue';
import alertEtv from './components/AlertEtv.vue';
import dateTime from './components/DateTime.vue';

export default {
  name: 'eva-title-viewer',

  props: {
    dataModel: {
      type: Array,
      default: () => { },
    },

  },

  data() {
    return {

    }
  },

  mounted() {
    //console.log('object load', this.dataModel);
  },

  methods: {
    //вставка компонента
    componentSelect(col) {
      switch (col.type) {
        case 'alertEtv':
          return alertEtv;
        case 'labelDescription':
          return labelDescription;
        case 'label':
          return labelEtv;
        case 'dateTime':
          return dateTime;
      }
    },

    emptyRow(row) {
      let isEmpty = true;
      for (let key in row) {
        if (row[key].description === '') {
          isEmpty = false;
          break;
        }
      }
      return isEmpty;
    }

  }

  /* 
  пример входящего пропса объекта 'col1' можно наз., как душе угодно
      {//это строка
        col1: {//это столбец 1
          type:'labelDescription',
          label: 'Дата создания задания',
          description: '18.05.2024'
        },
        col2: {//это столбец 2
          type:'labelDescription',
          label: 'Время',
          description: '18.05.2024'
        }
      },
  */
}
</script>

<style lang="less" scoped>
.etvContainer {
  margin: 10px;
  width: 100%;
  height: 100%;

  .etv-row {
    display: flex;
    width: 100%;
    gap: 5px;
    margin-bottom: 15px;

    .etv-col {
      flex: 1;
      /* для обрезки текста */
      overflow: hidden;
      word-wrap: break-word;
    }

  }

  .etv-row:last-child {
    margin-bottom: 1px;
  }
}
</style>