<template>
  <div class="eva-text-array-field-legacy">
    <eva-field-wrapper :title="fieldTitle">
      <v-text-field
        v-model="value"
        :label="fieldLabel"
        :error="hasErrors"
        :messages="fieldErrors"
        :disabled="readOnly"
        clearable
        outlined
        dense
      />
    </eva-field-wrapper>
  </div>
</template>

<script>
export default {
  name: 'eva-text-array-field',

  data() {
    return {
      value: ''
    }
  },

  watch: {
    model: {
      handler() {
        if (typeof this.modelValue === 'string') {
          this.value = this.modelValue;
        } else {
          this.value = (this.modelValue || []).join(',');
        }

      },
      immediate: true
    },
    value() {
      this.modelValue = (this.value || '').split(',');
    }
  }
}
</script>

<style lang="less">
.eva-text-array-field-legacy {
  .v-input__slot {
    background-color: #E5F1FF!important;
  }
}
</style>
