<template>
  <div class="eva-video__main">
    <div class="eva-video__main-container" ref="mainContainer">
      <eva-video-player
        ref="player"
        :source="source"
        :parent="$el"
        :isFullscreen="isFullscreen"
        :isArchive="false"
        :hideArchive="hideArchive"
        @archiveClick="onOpenArchive"
        @toggleFullscreen="onToggleFullscreen"
      >
        <template v-slot:header>
          <eva-video-m-o-header
            :settings="settings"
            :isFullscreen="isFullscreen"
            :mainContainerHeight="$refs?.mainContainer?.clientHeight"
            :currentMonitoringObject="currentMonitoringObject"
            :disabled="false"
            :show-state="true"
            @setCurrentMO="setCurrentMonitoringObject"
            @removeItemFromPanel="onRemoveItemFromPanel"
          />
        </template>
      </eva-video-player>
    </div>
  </div>
</template>
<script>
import EvaVideoPlayer from "../EvaVideoPlayer.vue";
import EvaWebrtcLifeVideoSource from "../sources/EvaWebrtcLifeVideoSource";
import EvaVideoMOHeader from "./EvaVideoMOHeader.vue";
import EvaTextbox from "@eva/client/plugins/core/components/inputs/EvaTextbox.vue";

export default {
  name: "eva-video-archive2",

  components: {
    EvaVideoMOHeader,
    EvaTextbox,
    EvaVideoPlayer,
  },

  props: {
    box: {
      type: Object,
    },
    event: {
      type: Object,
    },
    settings: {
      type: Object,
    },
    isFullscreen: {
      type: Boolean,
      default: false,
    },
    hideArchive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      source: null,
      fullscreen: false,
      currentMonitoringObject: null,
      stream: null,
    };
  },
  watch: {
    settings: {
      handler() {
        this.$set(
          this.$data,
          "currentMonitoringObject",
          this.settings.monitoringObject
        );
      },
      deep: true,
    },
    event: {
      handler() {
        if (this.event.event_type.sys_name === "VideoChannel:1") {
        } else if (this.event.event_type.sys_name === "StatusOMUpdate:1") {
          if (this.event.direct_obj.id === this.currentMonitoringObject.id) {
            this.$set(
              this.currentMonitoringObject,
              "status",
              this.event.params
            );
          }
        }
      },
    },
  },

  methods: {
    async setCurrentMonitoringObject(monitoringObject) {
      if (this.currentMonitoringObject.id !== monitoringObject.id) {
        this.currentMonitoringObject = monitoringObject;
        await this.openStream();
      }
    },
    openStream() {
      this.$set(
        this.$data,
        "stream",
        this.currentMonitoringObject?.ref_video_stream?.id
      );
      if (!this.stream) {
        return;
      }
      this.$nextTick(() => {
        this.$set(
          this.$data,
          "source",
          new EvaWebrtcLifeVideoSource(
            {
              stream_id: this.stream,
            },
            this.isPaused,
            this.settings
          )
        );
      });
    },
    onOpenArchive() {
      this.$emit("openArchive");
    },
    onRemoveItemFromPanel() {
      this.$emit("removeItemFromPanel");
    },
    onToggleFullscreen() {
      this.$emit("toggleFullscreen");
    },
  },

  mounted() {
    this.currentMonitoringObject = this.settings.monitoringObject;
    this.openStream();
  },

  beforeDestroy() {
    if (this.source) {
      this.source.clear();
    }
  },
};
</script>
<style lang="less">
.eva-video-teleport {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  //Главный раздел
  .eva-video__main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;

    //Контейнер с видео
    .eva-video__main-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;

      //Полупрозрачный фон для хедера
      .eva-video__header-background {
        height: 40px;
        width: 100%;
        position: absolute;
        background-color: black;
        opacity: 0.3;
      }

      .eva-video__video {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
      }

      .eva-video__error {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      //Онлайн футер
      .eva-video__footer {
        height: 50px;
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 @eva-padding;
      }
    }
  }

  video::-webkit-media-controls {
    display: none !important;
  }
}
</style>
