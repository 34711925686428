<template>
  <div class="eva-text-area-field-legacy">
    <eva-field-wrapper :title="fieldTitle">
      <v-textarea
        v-model="model[settings.name]"
        :label="fieldLabel"
        :error="hasErrors"
        :messages="fieldErrors"
        :disabled="readOnly"
        :maxlength="maxLength"
        :counter="counter"
        no-resize
        clearable
        outlined
        dense
      />
    </eva-field-wrapper>
  </div>
</template>

<script>
export default {
  name: 'eva-text-area-field'
}
</script>

<style lang="less">
.eva-text-area-field-legacy {
  .v-input__slot {
    background-color: #E5F1FF!important;
  }
  .v-input__append-inner {
    position: absolute!important;
    top: 0!important;;
    right: 0!important;;
  }
  textarea {
    margin-bottom: 4px!important;
    padding-right: 4px!important;;
    margin-right: 6px!important;
  }
}
</style>
