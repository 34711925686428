<template>
  <div class="eva-field-wrapper">
    <div class="eva-field-wrapper__content">
      <slot/>
    </div>
    <div class="eva-field-wrapper__info" v-if="title">
      <v-icon :title="title">
        mdi-help-circle-outline
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'eva-field-wrapper',

  props: {
    title: {
      type: String
    }
  }
}
</script>

<style lang="less">
.eva-field-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;

  .eva-field-wrapper__content {
    width: 100%;
    height: 100%;
  }

  .eva-field-wrapper__info {
    margin-top: 7px;
    margin-left: 5px;
  }
}
</style>
