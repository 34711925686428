import { defineStore } from 'pinia';
import { ref, computed, reactive } from 'vue';
import evaApp from "@eva/client/app";

export const useIncidentsStore = defineStore('incidents', () => {
  const selectedIncidentState = reactive({
    incident: reactive({}),
    moId: ref(null),
    mapId: ref(null),
    mapMonitoringObject: ref(null),
    placeMonitoringObject: ref(null),
  });

  const placeAndMapIsSame = computed(() => selectedIncidentState?.mapMonitoringObject.ref_mapsource.id === selectedIncidentState?.placeMonitoringObject.ref_mapsource.id);

  async function getMO(id) {
    const result = await evaApp.$http.getItem(
      `/api/v1/iagentservice/monitoringobject/${id}`,
    );

    return result;
  }

  async function setMap(item) {
    try {
      if (item && item.id) {
        let monitoringObject = await getMO(item.id);

        setMoId(monitoringObject.id);

        let mapId = monitoringObject.id;
        let moWithMap = monitoringObject;
        const { incident } = selectedIncidentState;
        // Если у нас идентификатор ОМ карты равен источнику и есть координаты, то тогда мы оставляем все как есть 
        if (!(incident.map_place?.coords?.point?.length > 0 && item.id === incident.ref_source.id)) {
          // Если есть объекты на картах, то тогда идем запрашивать нужный объект мониторинга
          if (monitoringObject.mapObjects?.length) {
            const indexMapObjectWithIncidentSource = monitoringObject.mapObjects.findIndex(
              (mapObject) => mapObject?.ref_mapsource?.id === incident.ref_source?.ref_mapsource?.id
            );
            const mapObject = indexMapObjectWithIncidentSource !== -1
                ? monitoringObject.mapObjects[indexMapObjectWithIncidentSource]
                : monitoringObject.mapObjects[0];
  
            const { items } = await evaApp.$http.getItem(
              `/api/v1/iagentservice/monitoringobject?limit=1&q=ref_mapsource__eq__${mapObject.ref_mapsource?.id}`
            );
            let monitoringObjectWithMap = items[0];
  
            // Если не смогли найти объект мониторинга с источником как у первого объекта, то идем искать первый попавшийся с нужной картой
            if (!monitoringObjectWithMap) {
              for (let i = 0; i < monitoringObject.mapObjects.length; ++i) {
                if (monitoringObject.mapObjects[i]?.ref_mapsource?.id) {
                  const { items } = await evaApp.$http.getItem(
                    `/api/v1/iagentservice/monitoringobject?limit=1&q=ref_mapsource__eq__${
                      monitoringObject.mapObjects[i].ref_mapsource?.id
                    }`
                  );
                  if (items.length) {
                    monitoringObjectWithMap = items[0];
                    break;
                  }
                }
              }
            }
  
            if (monitoringObjectWithMap?.id) {
              mapId = monitoringObjectWithMap.id;
              moWithMap = monitoringObjectWithMap;
            }
          }
        }

        setMapId(mapId);
        setMapMonitoringObject(moWithMap)
      }
    } catch (err) {
      console.log(err);
    }
  }

  function setMapId(id) {
    selectedIncidentState.mapId = ref(id);
  };
  
  function setMapMonitoringObject(mo) {
    selectedIncidentState.mapMonitoringObject = ref(mo);
  }

  function setMoId(id) {
    selectedIncidentState.moId = ref(id);
  }

  async function setPlaceMonitoringObject(id) {
    const item = await getMO(id);
    selectedIncidentState.placeMonitoringObject = ref(item);
  }

  async function setIncidentById(incidentId) {
    selectedIncidentState.incident = await evaApp.$http.get(`/api/v1/incidents/${incidentId}`);
  }

  async function setIncident(incident) {
    selectedIncidentState.incident = reactive(incident);
  }

  return {
    selectedIncidentState,
    placeAndMapIsSame,
    setMapId,
    setMap,
    setMoId,
    setPlaceMonitoringObject,
    setIncidentById,
    setIncident,
  }
});


