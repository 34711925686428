function getItemName(item) {
  if (!item) {
    return null;
  }
  if (this.settings.return) {
    if (typeof item === "string") {
      return item;
    }
    if (typeof item === "object") {
      if (this.settings.path) {
        if (typeof item[this.settings.path] === "string") {
          return item[this.settings.path];
        } else {
          if (this.settings.path.includes('.')) {
            let parts = this.settings.path.split('.');
            for (const part of parts) {
              item = item[`${part}`];
            }
            return item && item || '';
          } else {
            return item[this.settings.path] && item[this.settings.path] || '';
          }
        }
      } else {
        if (typeof item.name === "string") {
          return item.name;
        } else {
          return item.name && item.name[this.$locale.current] || '';
        }
      }
    }
  } else {
    if (this.settings.path) {
      if (typeof item[this.settings.path] === "string") {
        return item[this.settings.path];
      } else {
        if (this.settings.path.includes('.')) {
          let parts = this.settings.path.split('.');
          for (const part of parts) {
            item = item[`${part}`];
            if (item == null) {
              break;
            }
          }
          return item || '';
        } else {
          return item[this.settings.path] && item[this.settings.path] || '';
        }
      }
    } else {
      if (typeof item.name === "string") {
        return item.name;
      } else {
        return item.name && item.name[this.$locale.current] || '';
      }
    }
  }
}


export {
  getItemName,
}