<template>
  <eva-popup-input
    icon="mdi-calendar"
    :transparent="transparent"
    :class="$options.name"
    @click="showPopup"
    @icon-click="showPopup"
  >
    <eva-text bold :text="formattedValue" />
  </eva-popup-input>
</template>

<script>
import EvaSelectDatePopup from "./EvaSelectDatePopup";
import moment from "moment";

export default {
  name: "eva-select-date",

  props: {
    value: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    formattedValue() {
      return this.value
        ? moment(new Date(this.value)).format("DD.MM.YYYY")
        : "XX.XX.XXXX";
    },
  },

  methods: {
    findParentZIndex(parent) {
      //ищем первый родительский элемент с z-index
      while (parent) {
        const parentZIndex = this.getZIndex(parent);
        if (parentZIndex) {
          return parentZIndex;
        } else {
          parent = parent.parentElement;
        }
      }
      return null;
    },
    getZIndex(elem) {
      //из всех стилей элементов забираем zIndex
      const computedStyle = window.getComputedStyle(elem);
      const zIndex = parseInt(computedStyle.zIndex);
      return isNaN(zIndex) ? null : zIndex;
    },
    async showPopup() {
      let zIndex = this.findParentZIndex(this.$el.parentElement);
      zIndex = zIndex !== null ? zIndex + 1 : null;

      await this.$eva.$boxes.show({
        activator: this.$el,
        type: "dropdown",
        anchor: "bottom-left",
        zIndex: zIndex,
        component: EvaSelectDatePopup,
        componentProps: {
          value: this.value,
          max: this.max,
          select: (value) => this.$emit("input", value),
        },
      });
    },
  },
};
</script>

<style lang="less">
.eva-select-date {
  .eva-textbox__inner {
    padding: 8px 16px;

    .eva-textbox__input {
      display: flex;
      align-items: center;
    }
  }
}
</style>
