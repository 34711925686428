import evaApp from "@eva/client/app";

class EvaRepeaterSource {
  constructor() {
    this.app = evaApp;
  }

  async loadItems(settings, options) {
    return {
      items: [],
      total: 0
    }
  }

  async getItem(settings, options, model) {

  }

  async addItem(settings, options, model) {

  }

  async editItem(settings, options, model) {

  }

  async removeItem(settings, options, model) {

  }
}

export default EvaRepeaterSource;
