import EvaValidator from "./EvaValidator";

class EvaRangeValidator extends EvaValidator {
  constructor(app) {
    super(app, 'eva-range-validator');
  }

  async validateInternal(value, args) {
    if (value == null) {
      return;
    }
    if (typeof value === 'number') {
      if (args.min != null && args.max != null) {
        if (value < args.min || value > args.max) {
          return this.app.$t('$t.core.errors.fields.rangeOutside', args);
        }
      } else if (args.min != null) {
        if (value < args.min) {
          return this.app.$t('$t.core.errors.fields.rangeLess', args);
        }
      } else if (args.max != null) {
        if (value > args.max) {
          return this.app.$t('$t.core.errors.fields.rangeMore', args);
        }
      }
    }
  }
}

export default EvaRangeValidator;
