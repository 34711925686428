<template>
  <div class="eva-select-field-legacy">
    <eva-field-wrapper :title="fieldTitle">
      <v-select
        v-model="modelValue"
        :label="fieldLabel"
        :error="hasErrors"
        :messages="fieldErrors"
        :items="items"
        :multiple="multiple"
        :item-value="setReturn"
        item-text="label"
        outlined
        clearable
        attach
        dense
        :return-object="!setReturn"
        :menu-props="{ offsetY: true }"
        :disabled="readOnly"
      >
        <template v-slot:item="{item}">
          {{ $eva.$te(getTName(item)) ? $eva.$t(getTName(item)) : item.label }}
        </template>
        <template v-slot:selection="{item}">
          {{ $eva.$te(getTName(item)) ? $eva.$t(getTName(item)) : item.label }}
        </template>
      </v-select>
    </eva-field-wrapper>
  </div>
</template>

<script>
export default {
  name: 'eva-select-field',

  data() {
    return {
      items: null
    }
  },

  computed: {
    multiple() {
      return this.settings?.multiple || false;
    },
    setReturn() {
      return this.settings?.return;
    }
  },

  watch: {
    'settings.items': {
      async handler(value) {
        if (!value) {
          return;
        }
        if (typeof value === 'function') {
          value = value();
        }
        if (typeof value === 'string') {
          value = this.$eva.$http.getItems(value);
        }
        if (value instanceof Promise) {
          value = await value();
        }
        if (value && value.items) {
          value = value.items;
        }
        this.items = value;
      },
      immediate: true
    }
  },
  
  methods: {
    getTName(item) {
      let index = this.items.indexOf(item);
      return `$t.${this.formSettings.prefix}.${this.settings.name}.items[${index}]`;
    },
  }
}
</script>

<style lang="less">
.eva-select-field-legacy {
  .v-input__slot {
    background-color: #E5F1FF!important;
  }
}
</style>
