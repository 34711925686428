<template>
  <div class="eva-repeater-footer">

    <v-select
      v-if="options.state.isRemote && options.state.total > itemsPerPages[0].value"
      v-model="itemsPerPage"
      :disabled="options.state.loading"
      :items="itemsPerPages"
      :menu-props="{ top: true, offsetY: true }"
      outlined
      class="eva-repeater-footer__select"
    />

    <eva-text no-wrap
      v-if="!options.state.error"
      :text="totalCount"
      color="#3E4C5D"
    />

    <eva-text no-wrap size="1.4rem"
      v-if="!!options.state.error"
      :text="$eva.$t('$t.core.tables.loadingError')"
      :color="$eva.$styles.evaErrorColor.value"
    />

    <eva-spacer/>

    <div
      v-if="enablePagination && !options.state.isLoadOnScroll"
      class="eva-pagination"
    >

      <eva-btn
        v-if="enableHotPagination"
        type="icon--flat--small"
        icon="mdi-page-first"
        :label="$eva.$t('$t.eva-repeater-footer.commands.gotoFirstPage')"
        :disabled="!enablePrevPagination"
        @click="gotoFirstPage"
      />
      <eva-btn
        v-if="enableHotPagination"
        type="icon--flat--small"
        icon="mdi-chevron-left"
        :label="$eva.$t('$t.eva-repeater-footer.commands.gotoPrevPage')"
        :disabled="!enablePrevPagination"
        @click="gotoPrevPage"
      />

      <eva-btn
        v-for="item in pages"
        :key="page.number"
        :type="item.number === page ? 'text--secondary--small' : 'text--flat--small'"
        :label="item.number"
        :color="item.number === page ? 'primary' : null"
        @click="gotoPage(item.number)"
      />

      <eva-btn
        v-if="enableHotPagination"
        type="icon--flat--small"
        icon="mdi-chevron-right"
        :label="$eva.$t('$t.eva-repeater-footer.commands.gotoNextPage')"
        :disabled="!enableNextPagination"
        @click="gotoNextPage"
      />
      <eva-btn
        v-if="enableHotPagination"
        type="icon--flat--small"
        icon="mdi-page-last"
        :label="$eva.$t('$t.eva-repeater-footer.commands.gotoLastPage')"
        :disabled="!enableNextPagination"
        @click="gotoLastPage"
      />

    </div>
  </div>
</template>

<script>
export default {
  name: 'eva-repeater-footer',

  props: {
    settings: {
      type: Object
    },
    options: {
      type: Object
    }
  },

  data() {
    return {
      visiblePages: 3,
      itemsPerPages: [
        { value: 20, text: '20 записей' },
        { value: 50, text: '50 записей' },
        { value: 100, text: '100 записей' }
      ]
    }
  },

  computed: {
    enablePagination() {
      return this.options.state.isRemote && this.pageCount > 1;
    },
    enableNextPagination() {
      return this.enablePagination && this.page < this.pageCount;
    },
    enablePrevPagination() {
      return this.enablePagination && this.page > 1;
    },
    enableHotPagination() {
      return this.enablePagination && this.pageCount > this.visiblePages;
    },

    itemsPerPage: {
      get() {
        return this.options.filter.limit;
      },
      set(value) {
        this.options.filter.offset = 0;
        this.options.filter.limit = value;
      }
    },
    page: {
      get() {
        return Math.floor(this.options.filter.offset / this.options.filter.limit) + 1;
      },
      set(value) {
        this.options.filter.offset = this.options.filter.limit * (value - 1);
      }
    },
    startPage() {
      if (this.page === 1) {
        return this.page;
      }
      if (this.page === this.pageCount) {
        return Math.max(this.pageCount - this.visiblePages + 1, 1);
      }
      return this.page - 1;
    },
    endPage() {
      return Math.min(this.startPage + this.visiblePages - 1, this.pageCount);
    },
    pages() {
      const range = [];
      for (let i = this.startPage; i <= this.endPage; i++) {
        range.push({
          number: i,
          isDisabled: i === this.currentPage
        });
      }
      return range;
    },
    totalCount() {
      if (this.options.state.count) {
        return this.$eva.$t(`$t.core.paginations.total`, this.options.state.total);
      } else {
        return this.$eva.$t(`$t.core.paginations.total`, 0);
      }
    },
    pageCount() {
      return Math.ceil(this.options.state.total / this.options.filter.limit);
    }
  },

  methods: {
    gotoFirstPage() {
      if (this.enablePrevPagination) {
        this.page = 1;
      }
    },
    gotoPrevPage() {
      if (this.enablePrevPagination) {
        this.page -= 1;
      }
    },
    gotoLastPage() {
      if (this.enableNextPagination) {
        this.page = this.pageCount;
      }
    },
    gotoNextPage() {
      if (this.enableNextPagination) {
        this.page += 1;
      }
    },
    gotoPage(page) {
      if (this.enablePagination) {
        this.page = page;
      }
    }
  }
}
</script>

<style lang="less">
.eva-repeater-footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-items: left;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  gap: @eva-padding;
  padding: 0 16px 16px;

  .eva-pagination {
    display: flex;
    flex-direction: row;
    .eva-btn {
      border: none;
      &.eva-btn--secondary {
        /*background-color: #F6F8FF;
        color: #0260CF;*/
        /*font-weight: 600;*/
      }
      /*color: rgba(0,0,0,.54);*/

    }
  }

  .eva-repeater-footer__select {
    width : 130px;
    margin: 0!important;
    flex-grow: 0;
    border-radius: (@eva-padding)!important;
    input {
      display: none!important;
    }
    .v-input__append-inner {
      margin-top: 6px;
    }
    .v-input__slot {
      margin: 0!important;;
      min-height: 36px!important;
      padding: 0!important;
      background-color: #2A3746!important;
      fieldset {
        border-color: #2A3746;
      }
    }
    .v-icon {
      color: #E4E9EF !important;
    }
    .v-text-field__details {
      display: none!important;;
    }
    .v-select__selections {
      padding: 0!important;
      justify-content: center!important;
    }
    .v-icon {
      /*color: #3E4C5D!important;*/
    }
    .v-select__selection {
      margin: 0!important;
      /*color: #3E4C5D;*/
      /*font-weight: 600;*/
      color: #E4E9EF !important;
    }
  }
}
</style>

<locale lang="ru">
{
  commands: {
    gotoFirstPage: 'Первая страница',
    gotoPrevPage: 'Предыдущая страница',
    gotoNextPage: 'Следующая страница',
    gotoLastPage: 'Последняя страница'
  }
}
</locale>
