<template>
  <div class="eva-progress">
    <eva-icon
      icon="mdi-loading"
      color="#0260CF"
      spin
    />
    <eva-text
      v-if="message"
      :text="message"
      color="#0260CF"
      size="24px"
      bold
    />
  </div>
</template>

<script>
export default {
  name: 'eva-progress',

  props: {
    message: {
      type: String
    }
  }
}
</script>

<style lang="less">
.eva-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  .eva-icon {
    font-size: 64px;
    width: 98px!important;
    height: 98px!important;
    &:before {
      animation: mdi-spin 0.7s infinite linear;
    }
  }
}
</style>
