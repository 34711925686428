import { isEqual } from "lodash";
import { reactive } from "vue";
import moment from "moment";

class EvaVideoSource {
  constructor(source, paused, settings) {
    this.source = source;
    this.paused = paused;
    this.supports = reactive(this.getDefaultSupports());
    this.state = reactive({
      isPlaying: false,
      isLoading: false,
      isError: false,
      isFullscreen: false,
      fit: settings.objectFit || "contain",
    });
  }

  async updateSource(source, paused) {
    if (isEqual(this.source, source)) {
      return;
    }
    this.source = source;
    this.paused = paused;
    await this.clear();
    await this.play();
  }

  getDefaultSupports() {
    return {
      screenshot: false,
      fullscreen: false,
      fit: false,
      play: false,
      pause: false,
    };
  }

  use({ app, container, parent, video }) {
    this.app = app;
    this.container = container;
    this.video = video;
    this.parent = parent;
  }

  toggleFit() {
    if (!this.supports.fit) {
      return;
    }
    try {
      switch (this.state.fit) {
        case "contain":
          this.state.fit = "cover";
          break;
        case "cover":
          this.state.fit = "fill";
          break;
        case "fill":
          this.state.fit = "contain";
          break;
      }
    } catch (error) {
      this.app.$logs.error("EvaVideoSource", "fit video", error);
    }
  }

  getScreenshot() {
    if (!this.supports.screenshot) {
      return;
    }
    try {
      const link = document.createElement("a");
      const canvas = document.createElement("canvas");
      canvas.width = 1920;
      canvas.height = 1080;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(this.video, 0, 0, canvas.width, canvas.height);
      link.href = canvas.toDataURL("image/jpeg");
      const time = moment(Date.now(), "x").format("DD.MM.YYYY HH.mm");
      link.download = `${time}.jpeg`;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      this.app.$logs.error("EvaVideoSource", "screenshot video", error);
    }
  }

  async play(restart = true, paused = false) {
    if (!this.supports.play || !this.source || this.state.isPlaying) {
      return;
    }
    try {
      this.state.isError = false;
      this.state.isLoading = true;
      await this.playInternal(restart);
      if (this.paused === true) {
        this.paused = null;
        await this.pauseInternal();
        this.state.isPlaying = false;
      } else {
        this.state.isPlaying = true;
      }
    } catch (error) {
      this.app.$logs.error("EvaVideoSource", "play video", error);
      await this.clear();
      this.state.isError = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  async pause() {
    if (!this.supports.pause || !this.source || !this.state.isPlaying) {
      return;
    }
    try {
      this.state.isError = false;
      this.state.isLoading = true;
      await this.pauseInternal();
      this.state.isPlaying = false;
    } catch (error) {
      this.app.$logs.error("EvaVideoSource", "pause video", error);
      await this.clear();
      this.state.isError = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  async togglePlay() {
    if (this.state.isPlaying) {
      await this.pause();
    } else {
      await this.play();
    }
  }

  async clear() {
    try {
      this.state.isPlaying = false;
      this.state.isError = false;
      await this.clearInternal();
    } catch (error) {
      this.app.$logs.error("EvaVideoSource", "clear video", error);
    }
  }

  async playInternal() {}

  async pauseInternal() {
    if (this.video) {
      await this.video.pause();
    }
  }

  async clearInternal() {}
}

export default EvaVideoSource;
