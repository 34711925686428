<template>
  <eva-layout column class="eva-get-text-component">
    <eva-textarea
      v-model="value"
      :label="$eva.$t(message)"
      :error="error"
    />
    <eva-layout row>
      <eva-spacer/>
      <eva-command-list
        :commands="commands"
      />
    </eva-layout>
  </eva-layout>
</template>

<script>
export default {
  name: 'eva-get-text-component',

  props: {
    message: {
      typo: String
    },
    ok: {
      typo: String
    },
    box: {
      type: Object
    }
  },

  data() {
    return {
      value: '',
      initialized: false,
      commands: [{
        name: 'ok',
        label: this.ok,
        handle: () => {
          this.initialized = true;
          if (this.error) {
            this.$eva.$boxes.notifyError('Не все поля заполнены верно');
          } else {
            this.box.hide(this.value.trim());
          }
        }
      }, {
        name: 'cancel',
        handle: () => this.box.hide()
      }]
    }
  },

  computed: {
    error() {
      if (this.initialized && !this.value.trim()) {
        return this.$eva.$t('$t.core.errors.fields.require');
      }
    }
  }
}
</script>

<style lang="less">
.eva-get-text-component {
  width: 400px!important;
  padding: (@eva-padding * 2);
}
</style>
