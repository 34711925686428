import EvaHlsVideoSource from "./EvaHlsVideoSource";

class EvaHlsllVideoSource extends EvaHlsVideoSource {
  constructor(source, paused, settings) {
    super(source, paused, settings);
  }

  getSourceUrl(url) {
    return url.replace("webrtc", "hlsll/live/index.m3u8");
  }
}

export default EvaHlsllVideoSource;
