<template>
  <eva-popup-input
    v-show="!isHide"
    v-model="modelValue"
    :label="fieldLabel"
    :placeholder="hasFieldPlaceholder ? fieldPlaceholder : ''"
    :error="fieldErrors"
    :title="fieldTitle"
    :readonly="readOnly"
    :icon="(readOnly || preview) ? '' : opened ? 'mdi-menu-up' : 'mdi-menu-down'"
    :depth="depth"
    :preview="preview"
    @click="showItems"
    @icon-click="showItems"
    class="eva-select-field"
  >
    <div v-if="multiple" class="eva-select-field__chips">
      <v-chip
          v-for="(item, index) in modelValue"
          :key="index"
          :close="!readOnly"
          @click:close="deselectItem(item)"
          class="eva-select-field__chip"
          close-icon="mdi-close"
      >
        <span class="eva-text-clipped">
          {{ getItemName(item) }}
        </span>
      </v-chip>
    </div>
    <div v-else>
      {{ currentValue }}
    </div>
  </eva-popup-input>
</template>

<script>
import EvaSelectFieldPopup from "./EvaSelectFieldPopup";

export default {
  name: 'eva-select-field',

  dbType: 'str',

  data() {
    return {
      items: null,
      opened: false
    }
  },

  computed: {
    multiple() {
      return this.settings?.multiple || false;
    },
    returnField() {
      return this.settings && this.settings.return;
    },
    currentValue() {
      if (!this.modelValue) {
        return '';
      }
      if (typeof this.modelValue === 'object') {
        return this.$eva.$tools.getNestedValue(this.modelValue, this.labelField);
      }
      if (this.returnField) {
        let item = this.items && this.items.find((i) => i[this.returnField] === this.modelValue);
        if (item) {
          return this.$eva.$tools.getNestedValue(item, this.labelField);
        }
      }
      return this.modelValue;
    },
    hasFieldPlaceholder() {
      if (this.multiple) {
        return this.modelValue == null || !this.modelValue.length;
      } else {
        return this.modelValue == null;
      }
    },
    labelField() {
      return this.settings?.labelField || 'label';
    }
  },

  watch: {
    'settings.items': {
      async handler(value) {
        if (!value) {
          return;
        }
        if (typeof value === 'function') {
          value = value();
        }
        if (typeof value === 'string') {
          value = this.$eva.$http.getItems(value);
        }
        if (value instanceof Promise) {
          value = await value();
        }
        if (value && value.items) {
          value = value.items;
        }
        this.items = value;
      },
      immediate: true
    }
  },

  methods: {
    async showItems() {
      if (this.readOnly) {
        return;
      }
      let rect = this.$el.getBoundingClientRect();
      this.opened = true;
      await this.$eva.$boxes.show({
        activator: this.$el,
        type: 'dropdown',
        component: EvaSelectFieldPopup,
        componentProps: {
          selected: this.modelValue,
          multiple: this.multiple,
          items: this.items,
          width: rect.width,
          labelField: this.labelField,
          select: (item) => {
            if (item == null) {
              this.modelValue = null;
            } else if (this.multiple) {
              this.modelValue = this.returnField
                ? item.map((i) => i[this.returnField] || i)
                : item;
            } else {
              this.modelValue = this.returnField
                ? (item[this.returnField] || item)
                : item;
            }
          }
        }
      });
      this.opened = false;
    },
    getItemName(item) {
      if (this.returnField) {
        let value = this.items.find((i) => i[this.returnField] === item);
        return value ? this.$eva.$tools.getNestedValue(value, this.labelField) : item;
      } else {
        return this.$eva.$tools.getNestedValue(item, this.labelField);
      }
    },
    deselectItem(item) {
      if (this.settings.return) {
        if (typeof item === "string") {
          this.modelValue = this.multiple ? (this.modelValue).filter((s) => s !== item) : undefined;
        }
      } else {
        this.modelValue = this.multiple ? (this.modelValue).filter((s) => s.id !== item.id) : undefined;
      }
    }
  }
}
</script>

<style lang="less">
.eva-select-field {
  .eva-textbox__input {
    height: unset;
    min-height: 24px;
  }
  .eva-select-field__chips {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    .eva-select-field__chip {
      background-color: #0260CF !important;
      color: white !important;
      height: 24px;
      margin: 0!important;
      .v-chip__close {
        margin: 0 !important;
      }
      .v-chip__content {
        gap: .75rem;
      }
      .v-icon {
        font-size: 16px !important;
      }
    }
  }
  &.eva-input--readonly {

  }
}
</style>
