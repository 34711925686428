import EvaInputMixin from "./EvaInputMixin";
import Vue from "vue";

export default {
  name: 'eva-combobox',

  mixins: [
    EvaInputMixin
  ],

  components: {

  },

  data() {
    return {
      init: false,
      shown: false,
      added: false
    }
  },

  updated() {
    console.log(123)
  },

  beforeCreate() {
    console.log('beforeCreate', this.$slots.default)
  },

  created() {
    console.log('created', this.$slots.default)
  },

  render(h) {
    let vm = this;

    //console.log(vm.$slots.default)
    if (vm.$slots.default) {
      if (!this.init) {
        const ComponentClass = Vue.extend({
          props: {
            nodes: {}
          },
          render(h) {
            return this.nodes
          }
        });
        console.log('render', this.$slots.default)
        const result = new ComponentClass({
          vuetify: vm.$eva.vuetify,
          propsData: {
            nodes: vm.$slots.default
          }
        });
        result.$mount();
        this.res = result;
        this.init = true
      } else {
        this.res.nodes = vm.$slots.default;
      }

      if (this.shown) {
        if (!this.added) {
          document.body.append(this.res.$el);
          this.added = true;
        }
      }  else {
        if (this.added) {
          document.body.removeChild(this.res.$el)
          this.added = false;
        }
      }

    }

    return h('eva-popup-input', {
      attrs: {
        label: vm.label,
        placeholder: vm.placeholder,
        maxlength: vm.maxlength,
        icon: vm.icon,
        clearable: vm.clearable,
        error: vm.error,
      },
      on: {
        click: () => this.shown = !this.shown
      },
      staticClass: "eva-combobox"
    });
  }
}
