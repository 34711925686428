import { defineStore } from "pinia";

export const useEFNStore = defineStore({
    id: 'evaFolderNAvigate',
    state: () => ({
        objectFolder: null,
        checkedFolder: null
    }),
    actions: {
        loadFolders(objects) {
            this.objectFolder = objects;
        },
        folderChecked(object) {
            this.checkedFolder = object
        },
        clear() {
            this.objectFolder = null;
            this.checkedFolder = null;
        }
    }
})