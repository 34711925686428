<template>
  <div
    class="eva-icon-field"
    v-show="!isHide"
  >
    <eva-field-wrapper :title="fieldTitle">
      <v-autocomplete
        v-model="model[settings.name]"
        :label="fieldLabel"
        :error="hasErrors"
        :messages="fieldErrors"
        :items="items"
        outlined
        clearable
        dense
        hide-details
        :menu-props="{ offsetY: true }"
        :disabled="readOnly"
      >
        <template v-slot:selection="{ item, index }">
          <div><v-icon left>{{ item }}</v-icon>{{ item }}</div>
        </template>
        <template v-slot:item="{ item, index }">
          <div><v-icon left>{{ item }}</v-icon>{{ item }}</div>
        </template>
      </v-autocomplete>
    </eva-field-wrapper>
  </div>
</template>

<script>
import MDIIconList from '@eva/client/plugins/core/components/media/MDIIconList';

export default {
  name: 'eva-icon-field',

  data() {
    return {
      items: MDIIconList
    }
  }
}
</script>

<style lang="less">
.eva-icon-field {

}
</style>
