import EvaStorage from "./EvaStorage";

class EvaUserStorage extends EvaStorage {

  constructor(app) {
    super();
    this.app = app;
    this.data = null;
  }

  async initInternal() {
    if (!window.location.href.includes('login')) {
      let data = await this.app.$http.getItem('/api/v1/users/storage');
      this.data = data;
    }
    if (!this.data) {
      this.data = {};
    }
    return true;
  }

  getInternal(key) {
    return this.app.$tools.getNestedValue(this.data, key);
  }

  setInternal(key, value) {
    this.app.$tools.setNestedValue(this.data, key, value);
    this.save();
  }

  removeInternal(key) {
    this.app.$tools.setNestedValue(this.data, key, undefined);
    this.save();
  }

  save() {
    this.app.$http
      .put('/api/v1/users/storage', this.data || {})
      .catch((error) => this.logError(error));
  }
}

export default EvaUserStorage;
