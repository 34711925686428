import {isNil} from "lodash";
import moment from "moment";

function formatDateTime (dateTime, formatStr = 'MM/DD/YYYY HH:mm') {
  if (isNil(dateTime)) return null
  const convertedDateTime = moment.utc(dateTime, 'x');
  return convertedDateTime.format(formatStr);
}

function formatDateTimeNotUtc (dateTime, formatStr = 'MM/DD/YYYY HH:mm') {
  if (isNil(dateTime)) return null
  const convertedDateTime = moment(dateTime, 'x');
  return convertedDateTime.format(formatStr);
}

function formatTime (time, formatStr = 'HH:mm') {
  if (isNil(time)) return null
  const dateTemp = moment().startOf('day');
  const dur = moment.duration(time, formatStr === 'HH:mm:ss' ? 's' : 'ms');
  return dateTemp.add(dur).format(formatStr)
}

function formatTimeToUnix (time, formatStamp = 'milliseconds') {
  if (isNil(time)) return null
  const timeWithSeconds = formatStamp === 'milliseconds' ? `${time}:00` : time;
  return moment.duration(timeWithSeconds).as(formatStamp)
}

function formatDateToUnix (date) {
  return moment.utc(date).valueOf()
}


function padTo2Digits(num) {
  return num.toString().padStart(2, 0);
}

function convertMsToHM(milliseconds) {
  const sign = Math.sign(milliseconds);

  if (sign === -1) {
    milliseconds = -milliseconds;
  }

  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  // minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;

  return `${sign < 0 ? '-' : '+'}${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

function getTimeOffset(timeZone) {
  const utcDate = new Date(new Date().toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(new Date().toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime());
}


/**
 *  Метод для получения списка временных зон (без учета летнего времени - STD)
 *  @param all - Получить список всех временных зон, если ложно то только по России
 */
function getTimezoneList(all = true) {
  const timezones = Intl.supportedValuesOf('timeZone');
  const result = all ? timezones.map(timezone => {
    return {
      label: `(${convertMsToHM(getTimeOffset(timezone))}) ${timezone}`,
      offset: getTimeOffset(timezone) / 1000,
    }
  }) : [
    { offset: 7200, label: 'Калининградское время (МСК-1)' },
    { offset: 10800, label: 'Московское время (МСК)' },
    { offset: 14400, label: 'Самарское время (МСК+1)' },
    { offset: 18000, label: 'Екатеринбургское время (МСК+2)' },
    { offset: 21600, label: 'Омское время (МСК+3)' },
    { offset: 25200, label: 'Красноярское время (МСК+4)' },
    { offset: 28800, label: 'Иркутское время (МСК+5)' },
    { offset: 32400, label: 'Якутское время (МСК+6)' },
    { offset: 36000, label: 'Владивостокское время (МСК+7)' },
    { offset: 39600, label: 'Магаданское время (МСК+8)' },
    { offset: 43200, label: 'Камчатское время (МСК+9)' }
  ];
  return result;
}

export { formatDateTime, formatDateTimeNotUtc, formatTime, formatTimeToUnix, formatDateToUnix, getTimezoneList }
