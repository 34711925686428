<template>
    <!-- transparen background @click="$emit('cancel', false)"-->
    <transition name="fade">
        <div class=" backGround" v-if="showDialog" >
            <!-- formDialog @click.stop-->
            <div  
                :class="'eva-dialog2 eva-layout eva-dialog eva-layout--column eva-layout--row ' + classes + type"
                :style="stylesBlock">
                <!-- header -->
                <div class="eva-layout eva-dialog__header">
                    {{ currentLabel }}
                </div>
                <!-- body -->
                <div class="bodyDialog">
                    <!-- component -->
                    <component :is="bodyOptions.nameComponent" v-bind="bodyOptions.componentProps" />
                </div>
                <!-- footer -->
                <div :class="footerOptionsBlock.blockClass" class="eva-dialog__footer eva-layout--gap footerDialog">
                    
                    <eva-btn :type="footerOptionsBlock.buttons.classOk" :label="footerOptionsBlock.buttons.labelOk"
                        :tooltip="footerOptionsBlock.buttons.tooltipOk" :icon="footerOptionsBlock.buttons.iconOk"
                        @click="$emit('okDialog', true)">
                    </eva-btn>

                    <eva-btn :type="footerOptionsBlock.buttons.classCancel" :label="footerOptionsBlock.buttons.labelCansel"
                        :tooltip="footerOptionsBlock.buttons.tooltipCansel" :icon="footerOptionsBlock.buttons.iconCancel"
                        @click="$emit('cancelDialog', false)">
                    </eva-btn>
                </div>
            </div>

        </div>
    </transition>
</template>

<script>
export default {/* изменить все на лой буквы */
    name: 'eva-dialog2',
    props: {
        showDialog: {
            type: Boolean
        },
        labelDialog: {
            type: String,
            default: 'Подтвердите действие'
        },
        styles: {
            type: Object
        },
        type: {
            type: String
        },
        classes: {
            /* backgroundColor dialog */
            type: String,
            default: 'eva-background-4'
        },
        type: {
            /* backgroundColor dialog: drawer, dialog*/
            type: String
        },
        bodyOptions: {
            /* componen in body seting */
            nameComponent: { type: String },
            componentProps: { type: Array },
            componentOns: { type: String },
        },
        footerOptions: {
            type: Object
        }


    },
    data() {
        return {
            stylesBlock: '',
            footerOptionsBlock: {}
        }
    },
    mounted() {
        this.stylesBlock = this.styles?.width ? ` width:${this.styles.width};` : ' width: 500px;';
        this.stylesBlock += this.styles?.height ? ` height:${this.styles.height};` : '';

        this.footerOptionsBlock = {
            blockClass: this.footerOptions?.blockClass ? this.footerOptions.blockClass : 'footer-jc-fe',
            buttons: {
                labelOk: this.footerOptions?.labelOk ? this.footerOptions.labelOk : 'Да',
                tooltipOk: this.footerOptions?.tooltipOk ? this.footerOptions.tooltipOk : null,
                classOk: this.footerOptions?.classOk ? this.footerOptions.classOk : 'text-icon buttonML10',
                iconOk: this.footerOptions?.iconOk ? this.footerOptions.iconOk : ' mdi-check-bold',
                labelCansel: this.footerOptions?.labelCansel ? this.footerOptions.labelCansel : 'Нет',
                tooltipCansel: this.footerOptions?.tooltipCansel ? this.footerOptions.tooltipCansel : null,
                classCancel: this.footerOptions?.classCancel ? this.footerOptions.classCancel : 'text-icon--darkest buttonML10',
                iconCancel: this.footerOptions?.iconCancel ? this.footerOptions.iconCancel : ' mdi-cancel',
            },
        };
    },

    computed: {
        currentLabel() {
            return this.labelDialog != null ? this.labelDialog : "labelDialog"
        },

    },

    methods: {
        closeOk() {
            this.$emit('ok')
        },

        handleFocusOut(){
            console.log("handleFocusOut", handleFocusOut);
        }
    }



}
</script>

<style lang="less" scoped>
.backGround {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    background-color: #2121217e;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}

.bodyDialog {
    flex: 1;
    overflow: auto;
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 0px 16px;
}
</style>