<template>
  <div class="eva-preview">
    <div>
      <div class="eva-preview__label eva-foreground-2">
        {{ fieldLabel }}
      </div>
      <div
        class="eva-preview__value"
        :class="getValueClass"
      >
        <div v-html="getValue"></div>
        <eva-icon v-if="settings.action" :icon="settings.action.icon" :color="settings.action.color || '#E4E9EF'"
          @click="settings.action.handler" />
      </div>
    </div>
  </div>
</template>

<script>
import { getItemName } from "../selects/catalogRefField/commonMethods";
import moment from "moment";

export default {
  name: "eva-preview-field",

  computed: {
    getValueClass() {
      return {
        'eva-preview__value_header': this.settings.header,
      };
    },
    getValue() {
      switch (this.settings.type) {
        case "catalog-ref": {
          //let value = this.getItemName(this.modelValue);
          let value = this.getCatalogRef(this.modelValue);
          return value ? value : "-";
        }
        case "text-area":
          let value = this.modelValue?.replace(/\r?\n/g, "</br>");
          return value ? value : "-"; //надо бы отрефакторить
        case "date":
        case "datetime":
        case "time": {
          if (
            this.settings.zeroIsNull && this.modelValue === 0
            || this.modelValue == null
          ) {
            return "-";
          }

          const standartFormats = {
            date: "DD.MM.YYYY",
            datetime: "DD.MM.YYYY HH:mm",
            time: "HH:mm",
          };

          return moment(this.modelValue)
            .utc(this.settings.utc !== undefined ? !this.settings.utc : false)
            .format(
              this.settings.format || standartFormats[this.settings.type]
            );
        }
        case "checkbox": {
          return this.settings.values
            ? this.settings.values[this.modelValue]
            : this.$eva.$t(
              `$t.${this.$options.name}.values.${this.modelValue}`
            );
        }
        default: {
          return checkText(this.modelValue);
        }
      }

      function checkText(value) {
        //дополнительная проверка на обычные пееременные
        switch (true) {
          case (value?.toString() === "NaN" || value === undefined):
            return "-";
          case (value === ""):
            return "-";
          default:
            return value;
        }
      }
    },
  },

  methods: {
    getItemName,

    getCatalogRef(items) {
      const typeItem = Object.prototype.toString.call(items);
      switch (typeItem) {
        case "[object Object]":
          return items.name?.ru ? items.name.ru : items.name;
        case "[object Array]":
          let arritem = [];
          items.forEach((item) => arritem.push(item.name?.ru ? item.name.ru : item.name) );
          return arritem.join("</br>");
        case "[object String]":
          return items;
        default:
          return ""
      }
    }

  },
};
</script>

<style lang="less" scoped>
.eva-preview {
  & &__label {
    font-size: 12px;
    line-height: 1;
  }

  & &__value {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  & &__value_header {
    font-size: 16px !important;
  }
}
</style>

<locale lang="ru">
  {
    values: {
      true: 'Да',
      false: 'Нет',
    }
  }
</locale>
