<template>
  <div :class="$options.name">
    <div ref="container" class="eva-video-player__container">
      <div class="eva-video-player__header eva-foreground-1">
        <slot name="header" />
      </div>

      <video
        v-show="source && !source?.state?.isError"
        ref="video"
        playsinline
        muted
        :style="`object-fit: ${source && source.state.fit}`"
        @click.stop.prevent="onPlayerClick($event)"
        @mousedown.stop.prevent
      />

      <div class="eva-video-player__panel" v-show="showButtons">
        <div>
          <eva-btn
            v-if="!hideArchive"
            :icon="archiveIcon"
            type="icon--secondary--small"
            @click="onArchiveClick"
            :tooltip="archiveTooltip"
          />
        </div>
        <eva-spacer />
        <eva-btn
          v-if="source && source.supports.screenshot && !source.state.isError"
          icon="mdi-camera"
          type="icon--secondary--small"
          tooltip="Сделать снимок"
          @click="getScreenshot"
        />
        <eva-btn
          v-if="source && source.supports.fit && !source.state.isError"
          class="ml-2"
          :icon="fitIcon"
          type="icon--secondary--small"
          :tooltip="fitTooltip"
          @click="toggleFit"
        />
        <eva-btn
          class="ml-2"
          :icon="fullscreenIcon"
          type="icon--secondary--small"
          :tooltip="fullscreenTooltip"
          @click="toggleFullscreen"
        />
      </div>

      <div
        v-if="!source || (source && source.state.isError)"
        class="eva-video__error"
        @click.stop.prevent="onPlayerClick($event)"
        @mousedown.stop.prevent
      >
        <eva-icon icon="mdi-video-box-off" />
        <span>Видео недоступно или отсутствует</span>
      </div>
      <eva-progress v-if="source && source.state.isLoading" />
    </div>
  </div>
</template>

<script>
export default {
  name: "eva-video-player",

  props: {
    source: {
      type: Object,
    },
    parent: {
      type: HTMLDivElement,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
    hideArchive: {
      type: Boolean,
      default: false,
    },
    isFullscreen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showButtons: false,
      onFullscreenChanged: null,
    };
  },

  computed: {
    archiveIcon() {
      return this.isArchive ? "mdi-video" : "mdi-archive";
    },
    archiveTooltip() {
      return this.isArchive ? "Онлайн" : "Архив";
    },
    fullscreenIcon() {
      return this.isFullscreen ? "mdi-fullscreen-exit" : "mdi-aspect-ratio";
    },
    fullscreenTooltip() {
      return this.isFullscreen
        ? "Выход из полноэкранного режима"
        : "Во весь экран";
    },
    fitIcon() {
      switch (this.source.state.fit) {
        case "contain":
          return "mdi-arrow-expand";
        case "cover":
          return "mdi-arrow-expand-all";
        case "fill":
          return "mdi-arrow-collapse";
      }
    },
    fitTooltip() {
      switch (this.source.state.fit) {
        case "contain":
          return "Подстроить";
        case "cover":
          return "Растянуть";
        case "fill":
          return "Заполнить";
      }
    },
  },

  watch: {
    source: {
      handler(value, oldValue) {
        if (value) {
          value.use({
            app: this.$eva,
            container: this.parent,
            parent: this.parent.parentElement,
            video: this.$refs.video,
          });
          value.play();
        } else if (oldValue) {
          oldValue.clear();
        }
      },
      immediate: true,
    },
  },

  methods: {
    onPlayerClick(e) {
      this.$set(this.$data, "showButtons", !this.showButtons);
      this.$emit("playerClick");
    },
    onArchiveClick() {
      this.$emit("archiveClick");
    },
    async toggleFullscreen() {
      this.$emit("toggleFullscreen");
    },
    toggleFit() {
      if (this.source) {
        this.source.toggleFit();
      }
    },
    getScreenshot() {
      if (this.source) {
        this.source.getScreenshot();
      }
    },
  },
  mounted() {
    this.onFullscreenChanged = async (e) => {
      if (!document.fullscreenElement && this.isFullscreen) {
        await this.toggleFullscreen();
      }
    };
    document.addEventListener("fullscreenchange", this.onFullscreenChanged);
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.onFullscreenChanged);
  },
};
</script>

<style lang="less">
.eva-video-player {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;
  position: relative;
}
.eva-video-player__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  position: relative;
  .eva-video-player__header {
    height: 40px;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
  .eva-video-player__header::after {
    z-index: 2;
  }

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    /*object-fit: fill;*/
  }
  .eva-video-player__panel {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: @eva-padding;
    z-index: 2;
  }
  .eva-video__error {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .eva-icon {
      font-size: 34px;
    }
  }
}
</style>
