import axios from "axios";
import {stringify} from "querystring";
import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import moment from 'moment';

class HttpEvaPlugin extends EvaPlugin {
  constructor(app) {
    super(app);
    this.http = axios.create({
      maxBodyLength: Infinity,
      headers: {
        /*'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',*/
      },
    });
  }

  getListUrl(url, query, groupBy) {
    if (query) {
      let { q, sorting, ...args } = query;
      if (q) {
        q = Object.keys(q).map((key) => {
          let value = q[key];
          if (value == null || (typeof value === 'string' && value.length === 0)) {
            return;
          }
          if (Array.isArray(value)) {
            return value.length ? `${key}__in__[${value.map((i) => `'${i.id}'`)}]` : null;
          }
          if (typeof value === 'object') {
            if (!!value.op) {
              return value.value != null ? `${key}__${value.op}__${value.value}` : null;
            } else if (value.hidden) {
              let str = '';
              if (value.filters && value.filters.length) {
                for (const filter of value.filters) {
                  str += this.columnsFilter(filter.attr_name.id, filter.operand, filter.rules);
                }
              }
              if (value.quick_filters && value.quick_filters.length) {
                for (const filter of value.quick_filters) {
                  str += this.columnsFilter(filter.attr_name.id, filter.operand, filter.rules);
                }
              }
              return str;
            } else if (value.operand && !value.hidden) {
              let str = this.columnsFilter(key, value.operand, value.rules);
              return str;
            } else {
              return value.id != null ? `${key}__eq__${value.id}` : null;
            }
          } else {
            return `${key}__eq__${value}`;
          }
        }).filter((i) => !!i);
      }
      if (groupBy) {
        if (sorting) {
          sorting = `${groupBy}%1,${sorting}`;
        } else {
          sorting = `${groupBy}%1`;
        }
      }
      if (q && q.length && q.length > 1) {
        q = q.join('&|');
      }
      query = stringify({ q, sorting, ...args });
      if (url.split('?').length == 2) {
        return `${url}&${query}`;
      } else {
        return `${url}?${query}`;
      }
    } else {
      return url;
    }
  }

  async getItem(url) {
    let res = await this.http.get(url);
    return this.getResult(res);
  }

  async getList(url, query, groupBy) {
    url = this.getListUrl(url, query, groupBy);
    let res = await this.http.get(url);
    return this.getResult(res);
  }

  async getListItems(url, query) {
    url = this.getListUrl(url, query);
    let res = await this.http.get(url);
    let result = this.getResult(res);
    return result && result.items;
  }

  async getListFirstItem(url, query) {
    url = this.getListUrl(url, query);
    let res = await this.http.get(url);
    let result = this.getResult(res);
    return result && result.items && result.items[0];
  }

  async get(url) {
    let res = await this.http.get(url);
    return this.getResult(res);
  }

  async post(url, data) {
    let res = await this.http.post(url, data);
    return this.getResult(res);
  }

  async put(url, data) {
    let res = await this.http.put(url, data);
    return this.getResult(res);
  }

  async patch(url, data) {
    let res = await this.http.patch(url, data);
    return this.getResult(res);
  }

  async delete(url, data) {
    let res = await this.http.delete(url, { data });
    return this.getResult(res);
  }

  async getBlob(url, method = 'GET', data) {
    const blob = await this.http({
      url,
      method,
      responseType: 'blob',
      data
    });
    return blob;
  }

  async download(url, query, columns_list, group_by, timezone) {
    url = this.getListUrl(url, query);
    url += `&timezone=${timezone}&columns_list=${columns_list}`;
    if (group_by) {
      url += `&group_by=${group_by}`;
    }

    let res = await this.http({
      url,
      method: 'GET',
      responseType: 'blob'
    });
    return this.getResult(res);
  }

  columnsFilter(key, operand, rules) {
    let timeZone = new Date().getTimezoneOffset();
    timeZone = timeZone * 60 * 1000;
    let str = '';
    for (let i = 0; i < rules.length; i++) {
      if (i === 0) {
        if (rules[i].condition.id === 'empty') {
          str += `${key}__empty__`;
          continue;
        }
        if (rules[i].attr_name.type === 'date') {
          if (rules[i].type.id === 'period') {
            let start;
            let end;
            if (rules[i].condition.id === 'current') {
              start = moment.utc().startOf(`${rules[i].period.id}`).valueOf();
              end = moment.utc().endOf(`${rules[i].period.id}`).valueOf();
            } else {
              start = moment.utc().subtract(1, `${rules[i].period.id}`).startOf(`${rules[i].period.id}`).valueOf();
              end = moment.utc().subtract(1, `${rules[i].period.id}`).endOf(`${rules[i].period.id}`).valueOf();
            }
            if (timeZone > 0) {
              start = start + timeZone;
              end = end + timeZone;
            } else {
              start = start - Math.abs(timeZone);
              end = end - Math.abs(timeZone);
            }
            str += `${key}__gte__${start}&&${key}__lte__${end}`;
          } else {
            let start = moment.utc(rules[i].from).startOf('day').valueOf();
            let end = moment.utc(rules[i].to).endOf('day').valueOf();

            if (timeZone > 0) {
              start = start + timeZone;
              end = end + timeZone;
            } else {
              start = start - Math.abs(timeZone);
              end = end - Math.abs(timeZone);
            }

            str += this.prepareDateTime(rules[i].condition.id, key, start, end);
          }
        } else if (rules[i].attr_name.type === 'time' || rules[i].attr_name.type === 'datetime') {
          if (rules[i].type.id === 'period') {
            let start;
            let end;
            if (rules[i].condition.id === 'current') {
              start = moment.utc().startOf(`${rules[i].period.id}`).valueOf();
              end = moment.utc().endOf(`${rules[i].period.id}`).valueOf();
            } else {
              start = moment.utc().subtract(1, `${rules[i].period.id}`).startOf(`${rules[i].period.id}`).valueOf();
              end = moment.utc().subtract(1, `${rules[i].period.id}`).endOf(`${rules[i].period.id}`).valueOf();
            }
            if (timeZone > 0) {
              start = start + timeZone;
              end = end + timeZone;
            } else {
              start = start - Math.abs(timeZone);
              end = end - Math.abs(timeZone);
            }
            str += `${key}__gte__${start}&&${key}__lte__${end}`;
          } else {
            let start = moment.utc(rules[i].from).startOf('minute').valueOf();
            let end = moment.utc(rules[i].to).endOf('minute').valueOf();

            if (timeZone > 0) {
              start = start + timeZone;
              end = end + timeZone;
            } else {
              start = start - Math.abs(timeZone);
              end = end - Math.abs(timeZone);
            }

            str += this.prepareDateTime(rules[i].condition.id, key, start, end);
          }        
        } else {
          let value;
          if (typeof rules[i].value === 'object') {
            value = rules[i].value && rules[i].value.id || '';
          } else {
            value = rules[i].value;
          }
          str += `${key}__${rules[i].condition.id}__${value}`;
        }
      } else {
        if (rules[i].condition.id === 'empty') {
          str += `${operand.id}${key}__empty__`;
          continue;
        }
        if (rules[i].attr_name.type === 'date') {
          if (rules[i].type.id === 'period') {
            let start;
            let end;
            if (rules[i].condition.id === 'current') {
              start = moment.utc().startOf(`${rules[i].period.id}`).valueOf();
              end = moment.utc().endOf(`${rules[i].period.id}`).valueOf();
            } else {
              start = moment.utc().subtract(1, `${rules[i].period.id}`).startOf(`${rules[i].period.id}`).valueOf();
              end = moment.utc().subtract(1, `${rules[i].period.id}`).endOf(`${rules[i].period.id}`).valueOf();
            }
            if (timeZone > 0) {
              start = start + timeZone;
              end = end + timeZone;
            } else {
              start = start - Math.abs(timeZone);
              end = end - Math.abs(timeZone);
            }
            str += `${operand.id}${key}__gte__${start}&&${key}__lte__${end}`;
          } else {
            let start = moment.utc(rules[i].from).startOf('day').valueOf();
            let end = moment.utc(rules[i].to).endOf('day').valueOf();

            if (timeZone > 0) {
              start = start + timeZone;
              end = end + timeZone;
            } else {
              start = start - Math.abs(timeZone);
              end = end - Math.abs(timeZone);
            }

            str += `${operand.id}${this.prepareDateTime(rules[i].condition.id, key, start, end)}`
          }
        } else if (rules[i].attr_name.type === 'time' || rules[i].attr_name.type === 'datetime') {
          if (rules[i].type.id === 'period') {
            let start;
            let end;
            if (rules[i].condition.id === 'current') {
              start = moment.utc().startOf(`${rules[i].period.id}`).valueOf();
              end = moment.utc().endOf(`${rules[i].period.id}`).valueOf();
            } else {
              start = moment.utc().subtract(1, `${rules[i].period.id}`).startOf(`${rules[i].period.id}`).valueOf();
              end = moment.utc().subtract(1, `${rules[i].period.id}`).endOf(`${rules[i].period.id}`).valueOf();
            }
            if (timeZone > 0) {
              start = start + timeZone;
              end = end + timeZone;
            } else {
              start = start - Math.abs(timeZone);
              end = end - Math.abs(timeZone);
            }
            str += `${operand.id}${key}__gte__${start}&&${key}__lte__${end}`;
          } else {
            let start = moment.utc(rules[i].from).startOf('minute').valueOf();
            let end = moment.utc(rules[i].to).endOf('minute').valueOf();

            if (timeZone > 0) {
              start = start + timeZone;
              end = end + timeZone;
            } else {
              start = start - Math.abs(timeZone);
              end = end - Math.abs(timeZone);
            }

            str += `${operand.id}${this.prepareDateTime(rules[i].condition.id, key, start, end)}`
          }  
        } else {
          let value;
          if (typeof rules[i].value === 'object') {
            value = rules[i].value.id;
          } else {
            value = rules[i].value;
          }
          str += `${operand.id}${key}__${rules[i].condition.id}__${value}`;
        }
      }
    }
    return str;
  }

  prepareDateTime(condition, key, start, end) {
    if (condition === 'eq') {
      return `${key}__gte__${start}&&${key}__lte__${end}`;
    } else if (condition === 'gt') {
      return `${key}__gt__${end}`;
    } else if (condition === 'gte') {
      return `${key}__gte__${start}`;
    } else if (condition === 'lt') {
      return `${key}__lt__${start}`;
    } else if (condition === 'lte') {
      return `${key}__lte__${end}`;
    }
  }

  getResult(res) {
    return res && res.data;
  }
}

export default HttpEvaPlugin;
