
    import localesHash from '@eva/client/plugins/locales/LocalesHash';
    export default function (Component) {
      localesHash.push({ 
        lang: 'ru', 
        data: {
          [Component.options.name]: 
{
  attach: 'Прикрепить файл',
  errors: {
    file_limit: 'Достигнуто максимальное количество прикрепленных файлов',
    too_large_file: 'Слишком большой размер файла (Больше 10Мб)',
  }
}

        } 
      });
    }