<template>
  <div
    v-show="!isHide"
    class="eva-day-time-field"
  >
    <label v-if="fieldLabel" class="eva-textbox__label">
      {{ fieldLabel }}
    </label>
    <div class="eva-day-time-field-wrapper">
      <eva-input
        class="eva-day-time-field-days"
        :label="$eva.$t(`$t.${$options.name}.days_label`)"
        :clearable="clearable"
        :readonly="readOnly"
        :value="!!fieldModel.days"
        :depth="depth"
        @clear="fieldModel.days = ''"
      >
        <template v-slot:header>
          <slot name="header"></slot>
        </template>
  
        <input
          v-model="fieldModel.days"
          :readonly="readOnly"
          type="number"
          class="eva-textbox__input"
          min="0"
          max=""
        >
      </eva-input>
      <div class="eva-day-time-field-time">
        <label class="eva-textbox__label">
          {{ $eva.$t(`$t.${$options.name}.time_label`) }}
        </label>
        <eva-time-field
          :settings="timeSettings"
          :form-settings="formSettings"
          :model="fieldModel"
          :disabled="readOnly"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EvaNumberField from '../texts/numberField/index';
import EvaTimeField from '../datetime/EvaTimeField';

export default {
  name: 'eva-day-time-field',

  components: {
    EvaNumberField,
    EvaTimeField,
  },

  props: {
    model: {
      type: Object,
      required: true,
    },
    fieldName: {
      type: String,
      default: 'time'
    },
    label: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    fieldModel: {
      handler() {
        this.model[this.settings.name] = (this.fieldModel.days * 86400 + this.fieldModel.time) * 1000;
        let days = Number.parseInt(this.fieldModel.days);
        if (Number.isNaN(days) || this.fieldModel.days.length <= 0 || days < 0) {
          days = 0;
        }
        this.fieldModel.days = days; 
      },
      deep: true,
    },
    model: {
      handler() {
        this.setDefaultValues();
      },
      deep: true,
    }
  },

  computed: {
    timeSettings() {
      return {
        name: 'time',
        startDate: false,
        readOnly: this.settings.readOnly || false,
        seconds: true,
      }
    },
  },

  created() {
    if (this.model && this.model[this.settings.name] > 0) {
      this.setDefaultValues();
    }
  },

  data() {
    return {
      fieldModel: {
        days: 0,
        time: 0,
      },
    }
  },

  methods: {
    setDefaultValues() {
      this.fieldModel.days = (Math.trunc((this.model[this.settings.name] / 86400) / 1000)) || 0;
      this.fieldModel.time = (this.model[this.settings.name] / 1000 % 86400) || 0;
    },
  }

}
</script>

<style lang="less">
.eva-day-time-field {
  & &-wrapper {
    display: flex;
    gap: 8px;
  }

  & &-days {
    width: 120px;
    height: 57px;
  }

  & &-time {
    width: 100%;
    .v-text-field__details {
      display: none;
    }
  }
}
</style>

<locale lang="ru">
  {
    days_label: 'Дни',
    time_label: 'Часы:Минуты:Секунды',
  }
</locale>
