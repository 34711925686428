<template>
  <div class="eva-row-layout" :class="classes" :style="layoutStyle" @scroll="updated" @resize="updated">
<!--    <div style="position: absolute; color: red">{{ hasScroll }}</div>-->
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'eva-row-layout',

  props: {
    width: {
      type: [Number, String],
      default: '100%'
    },
    height: {
      type: [Number, String],
      default: 'unset'
    },
    scroll: {
      type: Boolean,
      default: false
    },
    scrollX: {
      type: Boolean,
      default: false
    },
    scrollY: {
      type: Boolean,
      default: false
    },
    scrollHide: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hasScroll: false
    }
  },

  computed: {
    layoutStyle() {
      return {
        width     : this.getSize(this.width),
        height    : this.getSize(this.height),
        overflowX : (!this.scrollHide && (this.scroll || this.scrollX)) ? 'auto' : 'hidden',
        overflowY : (!this.scrollHide && (this.scroll || this.scrollY)) ? 'auto' : 'hidden',
      };
    },
    classes() {
      return {
        'eva-row-layout--hide-scroll': this.scrollHide
      }
    }
  },

  methods: {
    getSize(value) {
      if (typeof value === 'number') {
        return `${value}px`;
      }
      if (typeof value === 'string') {
        return value;
      }
      return 'unset';
    },

    updated() {
      this.$nextTick(() => {
        this.hasScroll = this.$el.scrollHeight > this.$el.clientHeight;
      });
    }
  },

  mounted() {
    this.updated()
  }
}
</script>

<style lang="less">
.eva-row-layout {
  &.eva-row-layout--hide-scroll {
    overflow-y: hidden;
    &:hover {
      overflow-y: auto;
    }
  }
  display: flex;
  flex-direction: row;

}
</style>
