import { defineStore } from "pinia";

export const taskCardStore = defineStore(
  'taskСardst',
  {
    state: () => ({
        executingTask: false,
        headerStepState: false,//false - view
        updatewidgetState:false,
        defaultUpdateTable:false,
    }),
    actions: {
        setExecutingTask(variable) {
            this.executingTask = variable;
        },

        setHeaderStepState(variable) {
            this.headerStepState = variable;//maybe remove
        },

        setUpdateWidgetState(variavle){
            this.updatewidgetState = variavle;
        },

        setDefaultUpdateTable(variavle){
            this.defaultUpdateTable = variavle;
        },

        clear() {
            this.executingTask = false;
        }
    }
})