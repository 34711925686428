<template>
  <eva-layout column class="eva-dialog eva-background-2" :class="classes" :style="styles" no-gap transparent>

    <eva-layout row class="eva-dialog__header eva-background-2" transparent :class="headerClasses">
      <span :class="{
    'eva-dialog__header--text_fixed': settings.commandsComponent
  }">
        {{ $eva.$t(settings.header) }}
      </span>

      <component v-if="settings.commandsComponent" :is="settings.commandsComponent"
        :data="settings.commandsComponentProps" />

      <eva-command-list v-if="isCommandsInTop" type="icon-text--small" :commands="customLeftCommands" />

      <eva-spacer />

      <eva-command-list v-if="isCommandsInTop" type="icon-text--small" :commands="customRightCommands" />

      <eva-command-list v-if="isCommandsInTop" type="text--small" :commands="commands"
        @before-command="beforeCommand($event)" @on-command="onCommand($event)" />

      <eva-layout v-if="progress" row class="eva-dialog__progress" transparent>
        <progress></progress>
      </eva-layout>

    </eva-layout>

    <eva-layout column scroll class="eva-dialog__content eva-background-2" transparent>
      <component :is="component" v-bind="componentProps" v-on="componentOns" ref="currentComponent" />
    </eva-layout>

    <eva-layout
      v-if="!isCommandsInTop && (!!customLeftCommands.length || !!customRightCommands.length || !!commands.length)" row
      transparent class="eva-dialog__footer">

      <eva-command-list type="icon-text--small" :commands="customLeftCommands" />

      <eva-spacer />

      <eva-command-list type="icon-text--small" :commands="customRightCommands" />

      <eva-command-list type="icon-text--small" :commands="commands" @before-command="beforeCommand($event)"
        @on-command="onCommand($event)" />

    </eva-layout>
  </eva-layout>
</template>

<script>
import EvaToggleable from "../mixins/EvaToggleable";

const LOADING_TIMEOUT = 100;
const SYSTEM_FIELDS = ['index'];
function serialize(obj) {
  return JSON.stringify(obj, (key, value) => {
    if (value !== null && SYSTEM_FIELDS.indexOf(key) < 0) return value;
  })
}

export default {
  name: 'eva-dialog',

  mixins: [
    EvaToggleable
  ],

  props: {
    settings: {
      type: Object
    }
  },

  data() {
    return {
      error: null,
      headerBorder: false,
      commands: [],
      customLeftCommands: [],
      customRightCommands: [],
      customWidth: null,
      progress: false,
      oldModel: null,
      onCancel: null,
      onOk: null
    };
  },

  computed: {
    component() {
      return this.settings.component;
    },
    componentProps() {
      let result = { ...this.settings.componentProps };
      result.box = this;
      return result;
    },
    componentOns() {
      let result = { ...this.settings.componentOns || {} };
      return result;
    },
    classes() {
      let result = {};
      if (this.settings.type) {
        result[`eva-dialog--${this.settings.type}`] = true;
      }
      return result;
    },
    isCommandsInTop() {
      if (!this.settings) {
        return false;
      }
      switch (this.settings.type) {
        case 'full-screen':
        case 'drawer':
          return true;
        default:
          return false;
      }
    },
    headerClasses() {
      return {
        'eva-display-none': !this.settings.header && this.isDropDown
      }
    },
    styles() {
      return {
        zIndex: this.settings && this.settings.zIndex,
        width: this.customWidth || (this.settings && this.settings.width)
      }
    },
    isDropDown() {
      return this.settings.type === 'dropdown';
    },
    isDrawer() {
      return this.settings.type === 'drawer';
    }
  },

  watch: {
    'settings.commands': {
      handler() {
        this.commands = this.settings && this.$eva.$commands.createList(this.settings.commands) || [];
      },
      immediate: true,
      deep: true
    },
    'settings.customCommands': {
      handler() {
        (this.customLeftCommands || (this.customLeftCommands = [])).push(
          ...this.settings && this.$eva.$commands.createList(this.settings.customCommands) || []
        );
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    async show() {
      this.oldModel = this.settings.componentProps.model && serialize(this.settings.componentProps.model);

      this.overlay = this.$eva.$tools.beforeComponent({
        parent: this,
        component: 'eva-overlay',
        propsData: {
          settings: {
            zIndex: this.settings.overlayZIndex != null
              ? this.settings.overlayZIndex
              : this.settings.zIndex
          }
        }
      });
      if (this.settings.closeOnCLick !== false) {
        this.overlay.$on('click', async () => {
          if (await this.confirmClose()) {
            this.runCommand(this.commands.find((c) => c.name === 'cancel'));
          }
        });
      }
      await Promise.all([
        this.overlay.show({
          opacity: this.isDropDown ? 0.01 : undefined
        }),
        EvaToggleable.methods.show.call(this, {
          opacity: 1,
          activator: this.settings.activator,
          anchor: this.settings.anchor
        })
      ]);
    },

    async hide(commandName, result) {
      this.oldModel = null;

      this.$emit('begin-hide', commandName);
      await Promise.all([
        (this.overlay && this.overlay.hide()),
        EvaToggleable.methods.hide.call(this)
      ]);
      if (this.overlay) {
        this.$eva.$tools.dismountComponent(this.overlay);
        delete this.overlay;
      }
      this.$emit('hide', commandName, result);
    },

    async validate() {
      if (this.$refs.currentComponent && this.$refs.currentComponent.validate) {
        let res = await this.$refs.currentComponent.validate();
        if (res) {
          this.$eva.$boxes.notifyError('Не все поля заполнены верно');
          return false;
        }
      }
      return true;
    },

    addLeftCommands(...commands) {
      for (let i = 0; i < commands.length; i++) {
        this.customLeftCommands.push(this.$eva.$commands.create(commands[i]));
      }
    },

    addRightCommands(...commands) {
      for (let i = 0; i < commands.length; i++) {
        this.customRightCommands.push(this.$eva.$commands.create(commands[i]));
      }
    },

    async beforeCommand({ command, done }) {
      switch (command.name) {
        case 'ok':
          if (await this.validate() && (!this.onOk || await this.onOk())) {
            setTimeout(() => this.progress = true, LOADING_TIMEOUT);
            done();
          } else {
            //command.disabled = false;
          }
          break;
        case 'cancel':
          if (this.onCancel) {
            if (await this.onCancel()) {
              done();
            } else {
              done(true);
            }
          } else {
            if (await this.confirmClose()) {
              done();
            } else {
              //command.disabled = false;
              done(true);
            }
          }
          break;
        default:
          done();
          break;
      }
    },

    onCommand({ name, error }) {
      if (!(this.error = error)) {
        this.hide(name);
      } else {
        setTimeout(() => this.progress = false, LOADING_TIMEOUT);
      }
    },

    async runCommand(command) {
      this.error = null;
      if (command) {
        let { name, error } = await command.execute();
        if (!(this.error = error)) {
          await this.hide(name);
        }
      } else {
        await this.hide('cancel');
      }
    },

    async confirmClose() {
      if (this.oldModel == null) {
        return true;
      }
      if (this.oldModel === serialize(this.settings.componentProps.model)) {
        return true;
      }
      return !!(await this.$eva.$boxes.confirm({
        header: `$t.core.dialog.confirm.header`,
        message: `$t.core.dialog.confirm.message`
      }));
    },

    updateWidth(width) {
      this.customWidth = width;
    }
  }
}
</script>

<style lang="less">
.eva-dialog {
  position: absolute;
  z-index: 2001;
  flex-shrink: 1;
  max-height: 90vh;
  max-width: 100vw;
  transform: translate(-50%, -50%);
  border-radius: (@eva-padding / 2);

  .eva-dialog__header {
    position: relative;
    align-items: center;
    padding: @eva-padding @eva-padding * 2;
    /*background-color: #FCFDFF;
    border-bottom: 2px solid #E5F1FF;*/
    /*color: rgba(0,0,0,0.6);*/
    font-size: 1.2rem;
    /*font-weight: bold;*/

    &.eva-dialog__header--small {
      padding-bottom: 0;
    }

    .eva-dialog__header--text_fixed {
      width: 350px;
    }

    .eva-btn {
      height: 34px;
    }

    span:first-child {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .eva-dialog__progress {
    width: 100%;
    height: @eva-padding;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    progress {
      width: 100%;
      height: 100%;
    }
  }

  .eva-dialog__content {
    padding: 0;
  }

  .eva-dialog__footer {
    padding: @eva-padding @eva-padding * 2;
    min-height: 50px;
  }

  &.eva-dialog--dialog {
    min-width: 500px;
    width: 0;
    top: 50%;
    left: 50%;
  }

  &.eva-dialog--full-screen {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-height: 100vh;
    transform: translate(0, 0);
    border-radius: 0;
  }

  &.eva-dialog--drawer {
    position: absolute;
    left: auto !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    max-height: 100vh !important;
    transform: translate(0, 0) !important;
    min-width: 300px;
    width: 40%;
    flex-grow: 0;
    border-radius: 0;
  }

  &.eva-dialog--dropdown {
    position: absolute;
    max-height: calc(100vh - 300px) !important;
    transform: translate(0, 0) !important;
    width: auto;

    .eva-dialog__header {
      background-color: unset !important;
    }
  }

  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2),
  0 8px 10px 1px rgba(0, 0, 0, .14),
  0 3px 14px 2px rgba(0, 0, 0, .12);
}
</style>
