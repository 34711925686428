<template>
  <div class="eva-catalog-ref-field-legacy">
    <eva-field-wrapper :title="fieldTitle">
      <v-menu
        offset-y
        nudge-top="25px"
        :close-on-content-click="false"
        attach
        v-model="shownMenu"
        :disabled="readOnly"
      >
        <template v-slot:activator="{ on, value }">
          <div v-on="on">
            <v-select
              :value="modelValue"
              :label="fieldLabel"
              :error="hasErrors"
              :messages="fieldErrors"
              :items="selectItemsWorkaround"
              :multiple="multiple"
              readonly
              outlined
              clearable
              item-text="name"
              item-value="id"
              @click:clear="clearModel"
              :disabled="readOnly"
              dense
            >
              <template v-slot:selection="{ item }">
                <v-chip 
                  v-if="multiple" 
                  :close="!readOnly" 
                  @click:close="deselectItem(item)"
                  class="eva-catalog-ref-field__chip"
                >
                  <span>{{ getItemName(item) }}</span>
                  <v-icon
                    v-if="actions && actions.view" 
                    @click.stop="viewItem(item)" 
                    class="v-chip__close"
                  >
                    mdi-eye-circle
                  </v-icon>
                  <v-icon 
                    v-if="actions && actions.edit && !readOnly" 
                    @click.stop="editItem(item)" 
                    class="v-chip__close"
                  >
                    mdi-pencil-circle
                  </v-icon>
                </v-chip>
                <div v-else>{{ getItemName(item) }} </div>
              </template>
              <template v-slot:append>
                <v-icon :disabled="readOnly">
                  {{ value ? 'mdi-menu-up' : 'mdi-menu-down' }}
                </v-icon>
              </template>
            </v-select>
          </div>
        </template>
        <eva-layout column max-height="250px">
          <eva-layout column class="eva-catalog-ref-search">
            <v-text-field
              v-model="search"
              outlined
              dense
              clearable
              hide-details
              append-icon="mdi-magnify"
              class="eva-catalog-ref-search-field"
            />
            <v-btn
              v-if="actions && actions.add"
              elevation="2"
              min-width="40"
              max-width="40"
              width="40"
              class="eva-catalog-ref-actions"
              @click="createByMeta"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </eva-layout>
          <eva-layout column scroll>
            <div
              v-for="item in items"
              :key="item.id"
              :class="getClasses(item)"
              @click="selectItem(item)"
            >
              <div> {{ getItemName(item) }} </div>
              <div class="eva-catalog-ref__list-item__actions">
                <v-icon
                  v-for="(action, index) in extraActions"
                  :key="index"
                  @click.stop="action.handler(item)"
                >
                  {{ action.icon }}
                </v-icon>
                <div v-if="multiple">
                  <v-simple-checkbox 
                    :value="isSelectedItem(item)" 
                    @input="selectItem(item)"
                  />
                </div>
              </div>
            </div>
            <div class="eva-catalog-ref-list-footer">
              <div v-intersect="onIntersect"></div>
              <div v-if="loadItemsError" style="color: #F44336">
                {{ loadItemsError }}
              </div>
            </div>
          </eva-layout>
        </eva-layout>
      </v-menu>
    </eva-field-wrapper>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import getQuery from '../../../../../../../../plugins/core-plugin/client/components/utils/get-query';
import { watch } from "vue";

const DEBOUNCE = 500;


export default {
  name: 'eva-catalog-ref-field',

  data () {
    return {
      search: '',
      items: [],
      total: null,
      shownMenu: false,
      loading: true,
      loadItemsError: null
    }
  },
  computed: {
    fieldName() {
      return this.settings?.name;
    },
    itemsPerPage() {
      return this.settings?.itemsPerPage || 10;
    },
    multiple() {
      return this.settings?.multiple || false;
    },
    actions() {
      return this.settings?.actions;
    },
    selectItemsWorkaround() {
      // чтобы корректно выбирались значения
      if (!this.modelValue) {
        return [];
      }
      return this.multiple ? this.modelValue : [this.modelValue];
    },
    extraActions() {
      const actions = [];
      if (this.actions && this.actions.view) {
        actions.push({
          icon: "mdi-eye-circle",
          handler: async (item) => {
            await this.viewItem(item)
          }
        });
      }
      if (this.actions && this.actions.edit) {
        actions.push({
          icon: "mdi-pencil-circle",
          handler: async (item) => {
            await this.editItem(item)
          }
        });
      }
      return actions;
    },
  },
  watch: {
    async search() {
      // 0 для сброса, если стереть поисковую строку
      if (this.search?.length > 0 && this.search?.length < 2) {
        return;
      }
      await this.clearAndSearchItems();
    },
    shownMenu() {
      if (!this.shownMenu) {
        this.items = [];
        this.total = null;
      } else {
        this.loadItems();
      }
    }
  },
  created() {
    // todo: возвращается пустой объект в случае, если ref_ не указывался.
    // Делаю специальную очистку, но это неправильно, должен возвращаться null для корректной работы
    // Обратиться к разработчикам сервера
    if (!this.multiple && this.modelValue && !this.modelValue.id) {
      this.clearModel();
    }

    this.clearAndSearchItems = debounce(async (page) => {
      await this.loadItems(true);
    }, DEBOUNCE);

    this.getQ();
  },
  methods: {
    getClasses(item) {
      const classes = ['eva-catalog-ref__list-item'];
      if (this.isSelectedItem(item)) {
        classes.push('eva-catalog-ref__list-item__active');
      }
      return classes;
    },
    getItemName(item) {
      if (this.settings.return) {
        if (typeof item === "string") {
          return item;
        }
        if (typeof item === "object") {
          if (this.settings.path) {
            if (typeof item[this.settings.path] === "string") {
              return item[this.settings.path];
            } else {
              if (this.settings.path.includes('.')) {
                let parts = this.settings.path.split('.');
                for (const part of parts) {
                  item = item[`${part}`];
                }
                return item && item || '';
              } else {
                return item[this.settings.path] && item[this.settings.path] || '';
              }
            }
          } else {
            if (typeof item.name === "string") {
              return item.name;
            } else {
              return item.name && item.name[this.$locale.current] || '';
            }
          }
        }
      } else {
        if (this.settings.path) {
          if (typeof item[this.settings.path] === "string") {
            return item[this.settings.path];
          } else {  
            if (this.settings.path.includes('.')) {
              let parts = this.settings.path.split('.');
              for (const part of parts) {
                item = item[`${part}`];
              }
              return item && item || '';
            } else {
              return item[this.settings.path] && item[this.settings.path] || '';
            }
          }
        } else {
          if (typeof item.name === "string") {
            return item.name;
          } else {
            return item.name && item.name[this.$locale.current] || '';
          }
        }
      }
    },
    isSelectedItem(item) {
      if (!this.fieldName || !this.modelValue) {
        return false;
      }
      if (this.settings.return) {
        if (typeof item[this.settings.return] === "string") {
          const selected = this.modelValue;
          if (this.multiple) {
            return (selected || []).length !== 0 && selected.some((s) => s === item[this.settings.return]);
          } else {
            return !!selected && selected === item[this.settings.return];
          }
        }
      } else {
        const selected = this.modelValue;
        if (this.multiple) {
          return (selected || []).length !== 0 && selected.some((s) => s.id === item.id);
        } else {
          return !!selected?.id && selected?.id === item.id;
        }
      }
    },
    selectItem(item) {
      if (this.settings.return) {
        if (typeof item[this.settings.return] === "string") {
          const selected = this.modelValue;
          if (selected) {
            const hasItem = this.multiple ? (selected).some((s) => s === item[this.settings.return]) : selected === item[this.settings.return];
            if (hasItem) {
              this.deselectItem(item);
              return;
            }
          }
          this.modelValue = this.multiple ? (selected || []).concat(item[this.settings.return]) : item[this.settings.return];
          if (!this.multiple) {
            this.shownMenu = false;
          }
        }
      } else {
        const selected = this.modelValue;
        if (selected) {
          const hasItem = this.multiple ? (selected).some((s) => s.id === item.id) : selected?.id === item.id;
          if (hasItem) {
            this.deselectItem(item);
            return;
          }
        }
        this.modelValue = this.multiple ? (selected || []).concat(item) : item;
        if (!this.multiple) {
          this.shownMenu = false;
        }
      }
    },
    deselectItem(item) {
      if (this.settings.return) {
        if (typeof item === "string") {
          this.modelValue = this.multiple ? (this.modelValue).filter((s) => s !== item) : undefined;
        }
      } else {
        this.modelValue = this.multiple ? (this.modelValue).filter((s) => s.id !== item.id) : undefined;
      }
    },
    async viewItem(item) {
      const model = await this.$eva.$http.getItem(`${this.settings?.url}/${item.id}`);
      await this.$eva.$metadatas.openMeta(this.settings.meta, {
        type: 'drawer',
        url: this.settings.url,
        header: `$t.${this.formSettings.prefix}.${this.settings.name}.actions.view.title`,
        settings: { readOnly: true },
        model
      });
    },
    async editItem(item) {
      const model = await this.$eva.$http.getItem(`${this.settings?.url}/${item.id}`);
      await this.$eva.$metadatas.openMeta(this.settings.meta, {
        type: 'drawer',
        url: this.settings.url,
        header: `$t.${this.formSettings.prefix}.${this.settings.name}.actions.edit.title`,
        model,
        ok: async () => {
          try {
            const data = await this.$eva.$http.put(`${this.settings.url}/${model.id}`, model);
            if (data?.id) {
              throw new Error ('Incorrect body');
            }
            const result = await this.$eva.$http.getList(`${this.settings?.url}?q=_id__eq__${data.id}`);
            if (!result && !result.items[0]) {
              throw new Error ('Incorrect result');
            }
            if (this.isSelectedItem(result.items[0])) {
              this.deselectItem(result.items[0]);
              this.selectItem(result.items[0]);
            }
            this.shownMenu = false;
          } catch (e) {
            this.$eva.$boxes.notifyError(`Error during creation object: ${e.message}`);
            console.error(this.settings.url, model);
          } finally {
          }
        }
      })
    },
    async createByMeta() {
      const meta = await this.$eva.$http.getListFirstItem(`/api/v1/metadata?q=meta_name__eq__${this.settings.meta.name}`);
      let model = {
        ref_metadata: {
          id: meta.id
        }
      };
      await this.$eva.$metadatas.openMeta(this.settings.meta, {
        type: 'drawer',
        url: this.settings.url,
        header: `$t.${this.formSettings.prefix}.${this.settings.name}.actions.add.title`,
        isnew: true,
        model,
        ok: async () => {
          try {
            const data = await this.$eva.$http.post(this.settings.url, model);
            if (data?.id) {
              throw new Error ('Incorrect body');
            }
            const result = await this.$eva.$http.getList(`${this.settings?.url}?q=_id__eq__${data.id}`);
            if (!result && !result.items[0]) {
              throw new Error ('Incorrect result');
            }
            this.selectItem(result.items[0]);
            this.shownMenu = false;
          } catch (e) {
            this.$eva.$boxes.notifyError(`Error during creation object: ${e.message}`);
            console.error(this.settings.url, model);
          } finally {
          }
        }
      })
    },
    clearModel() {
      if (this.multiple) {
        this.modelValue = [];
      } else {
        this.modelValue = {};
      }
    },
    getQ() {
      if (!this.settings?.q) {
        return;
      }
      const q = Array.isArray(this.settings.q) ? this.settings.q : [this.settings.q];
      const result = [];
      for (const qPart of q) {
        if (typeof qPart === 'object') {
          switch (qPart.op) {
            case '__in__':
              let value = typeof qPart.value === 'function'
                ? (qPart.value() || [])
                  .map((v) => `'${v.id}'`)
                  .join(',')
                : ([...(this.getDynamicValue(qPart.value) || [])])
                  .map((v) => `'${v.id}'`)
                  .join(',');
              if (value) {
                result.push(`${qPart.field}${qPart.op}[${value}]`);
              }
              break;
            default:
              result.push(`${qPart.field}${qPart.op}${this.getDynamicValue(qPart.value)}`);
              break;
          }
          if (typeof qPart.value === 'string' && qPart.value.startsWith('$current')) {
            watch(
                () => this.getDynamicValue(qPart.value),
                () => this.clearModel()
            );
          }
        } else {
          const templates = qPart.match(this.regexDynamicParam);
          if (!templates || templates.length === 0) {
            result.push(qPart);
          } else {
            let resQ = qPart;
            for (const t of templates) {
              const value = this.getDynamicValue(t.slice(1, -1));
              resQ = resQ.replace(t, value);
            }
            result.push(resQ);
          }
        }
      }
      return result;
    },
    async loadItems(clear) {
      if (!clear && this.total === this.items.length) {
        return;
      }
      this.loading = true;
      this.loadItemsError = null;
      try {
        const url = this.settings?.url;
        if (!url) {
          throw new Error('Wrong settings: url');
        }
        const params = {
          limit: this.itemsPerPage,
          offset: clear ? 0 : this.items.length,
          context: (!!this.search && this.search.length > 1) ? this.search : undefined,
          sorting: this.settings.sorting || 'name%1',
          q: this.getQ(),
        };
        const result = await this.$eva.$http.getList(`${url}?${getQuery(params)}`);
        if (!result) {
          throw new Error ('Incorrect list result');
        }
        const { items, total } = result;
        if (clear) {
          this.items = [];
          this.total = null;
        }
        this.items = (this.items || []).concat(items);
        this.total = total;
      } catch (error) {
        this.loadItemsError = 'Ошибка при загрузке';
        console.error(error.message);
      } finally {
        this.loading = false;
      }
    },
    async onIntersect(entries) {
      if (!this.loading && !this.loadItemsError && entries[0].isIntersecting) {
        await this.loadItems();
      }
    },
  },
}
</script>

<style lang="less">
.eva-catalog-ref-field-legacy {
  position: relative;

  .v-input__slot {
    background-color: #E5F1FF!important;
  }

  .v-input {
    .v-input__append-inner:first-child {
      order: 100;
    }
  }

  .eva-catalog-ref-field__chip {
    height: 24px;
    margin: 2px!important;
    .v-chip__content {
      gap: .75rem;
    }
    .v-icon {
      font-size: 24px!important;
    }
    background-color: #0260CF!important;
    color: white!important;
  }

  .eva-catalog-ref-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    padding: @eva-padding;
  }

  .eva-catalog-ref-search-field {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .eva-catalog-ref-actions {
    flex-shrink: 1;
  }

  .eva-catalog-ref__list-item {
    padding-left: @eva-padding * 2;
    padding-top: (@eva-padding / 2);
    padding-bottom: (@eva-padding / 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .eva-catalog-ref__list-item__active {
    background-color: #d2d8dd;
  }

  .eva-catalog-ref__list-item__actions {
    display: flex;
    flex-direction: row;
    gap: (@eva-padding / 1);
    padding-right: @eva-padding;
  }

  .eva-catalog-ref-list-footer {
    display: flex;
    justify-content: center;
  }

  .v-select__selections {
    input {
      display: none;
    }
  }
}
</style>
