import EvaVideoSource from "./EvaVideoSource";

const PLAY_TIMEOUT = 20000;
const TRY_COUNT = 3;

class EvaWebrtcVideoSource extends EvaVideoSource {
  constructor(source, paused, settings) {
    super(source, paused, settings);
    this.webrtc = null;
  }

  getDefaultSupports() {
    return {
      screenshot: true,
      fullscreen: true,
      fit: true,
      play: true,
      pause: true,
    };
  }

  async playInternal() {
    return new Promise(async (resolve, reject) => {
      try {
        const channel = await this.getChannel();

        this.webrtc = new RTCPeerConnection({
          iceServers: channel.ice_servers.map((item) => ({
            urls: item,
            username: channel.ice_username,
            credential: channel.ice_credential,
          })),
          sdpSemantics: "unified-plan",
        });
        this.webrtc.ontrack = async (e) => {
          if (!e.streams || !e.streams.length) {
            return reject(new Error("No streams"));
          }
          try {
            this.video.srcObject = e.streams[0];
            await this.app.$tools.promiseTimeout(
              this.video.play(),
              PLAY_TIMEOUT,
              "'EvaWebrtcVideoSource' Стрим не вернул данные за отведенное время"
            );
            resolve();
          } catch (error) {
            reject(error);
          }
        };
        this.webrtc.onnegotiationneeded = async (e) => {
          let success = false;
          let error = null;
          for (let i = 0; i < TRY_COUNT; i++) {
            try {
              this.app.$logs.info(
                "EvaWebrtcVideoSource",
                `Попытка № ${i + 1} открытия канала`
              );
              success = await this.openConnection(e, channel);
              if (success) {
                break;
              }
            } catch (error) {
              this.app.$logs.error(
                "EvaWebrtcVideoSource",
                "Во время запуска видео произошла ошибка",
                error
              );
            }
          }
          if (!success) {
            reject(error);
          }
        };
        this.webrtc.addTransceiver("video", {
          direction: "sendrecv",
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  async openConnection(e, channel) {
    const offer = await e.target.createOffer();
    await e.target.setLocalDescription(offer);

    const response = await fetch(channel.url, {
      method: "POST",
      body: new URLSearchParams({
        data: btoa(e.target.localDescription.sdp),
      }),
    });
    const text = await response.text();
    if (e.target.signalingState !== "closed") {
      await e.target.setRemoteDescription(
        new RTCSessionDescription({
          type: "answer",
          sdp: atob(text),
        })
      );
      return true;
    }
    return false;
  }

  async clearInternal() {
    this.video.srcObject = null;
    if (this.webrtc) {
      this.webrtc.close();
      this.webrtc = null;
    }
  }

  async getChannel() {}
}

export default EvaWebrtcVideoSource;
