
    import localesHash from '@eva/client/plugins/locales/LocalesHash';
    export default function (Component) {
      localesHash.push({ 
        lang: 'ru', 
        data: {
          [Component.options.name]: 
{
  days_label: 'Дни',
  time_label: 'Часы:Минуты:Секунды',
}

        } 
      });
    }