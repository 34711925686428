<template>
  <eva-popup-input
    :icon="currentIcon"
    :transparent="transparent"
    :class="$options.name"
    @click="showPopup"
    @icon-click="showPopup"
  >
    <eva-text :text="formattedValue" />
  </eva-popup-input>
</template>

<script>
import EvaSelectPopup from "./EvaSelectPopup";
import moment from "moment";

export default {
  name: "eva-select",

  props: {
    value: {
      type: Object,
      default: null,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isShowing: false,
    };
  },

  computed: {
    formattedValue() {
      return this.value?.name || "";
    },
    currentIcon() {
      if (this.hideIcon === true) {
        return null;
      }
      return this.isShowing ? "mdi-chevron-up" : "mdi-chevron-down";
    },
  },

  methods: {
    findParentZIndex(parent) {
      //ищем первый родительский элемент с z-index
      while (parent) {
        const parentZIndex = this.getZIndex(parent);
        if (parentZIndex) {
          return parentZIndex;
        } else {
          parent = parent.parentElement;
        }
      }
      return null;
    },
    getZIndex(elem) {
      //из всех стилей элементов забираем zIndex
      const computedStyle = window.getComputedStyle(elem);
      const zIndex = parseInt(computedStyle.zIndex);
      return isNaN(zIndex) ? null : zIndex;
    },
    async showPopup() {
      let zIndex = this.findParentZIndex(this.$el.parentElement);
      zIndex = zIndex !== null ? zIndex + 1 : null;
      if (this.disabled) {
        return;
      }
      if (!this.url && !this.items) {
        return;
      }
      try {
        this.isShowing = true;

        await this.$eva.$boxes.show({
          activator: this.$el,
          type: "dropdown",
          anchor: "bottom-left",
          zIndex: zIndex,
          component: EvaSelectPopup,
          componentProps: {
            value: this.value,
            url: this.url,
            items: this.items,
            select: (value) => this.$emit("input", value),
          },
        });
      } finally {
        this.isShowing = false;
      }
    },
  },
};
</script>

<style lang="less">
.eva-select {
  .eva-textbox__inner {
    padding: 8px 16px;

    .eva-textbox__input {
      display: flex;
      align-items: center;
    }
  }
}
</style>
