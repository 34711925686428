import { render, staticRenderFns } from "./EvaFolderNavigate.vue?vue&type=template&id=bab80e0e&scoped=true&"
import script from "./EvaFolderNavigate.vue?vue&type=script&lang=js&"
export * from "./EvaFolderNavigate.vue?vue&type=script&lang=js&"
import style0 from "./EvaFolderNavigate.vue?vue&type=style&index=0&id=bab80e0e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bab80e0e",
  null
  
)

/* custom blocks */
import block0 from "./EvaFolderNavigate.vue?vue&type=custom&index=0&blockType=locale&lang=ru"
if (typeof block0 === 'function') block0(component)

export default component.exports