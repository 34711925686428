<template>
  <div
    class="eva-range-date-field"
    v-show="!isHide"
  >
    <eva-field-wrapper :title="fieldTitle">
      <v-menu
        ref="dateMenu"
        :close-on-content-click="false"
        offset-y
        nudge-top="30px"
        min-width="0"
        attach
        :disabled="computedSettings.readOnly"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            ref="dateInput"
            :key="inputKey"
            :value="dates"
            :label="fieldLabel"
            :error="hasErrors"
            :messages="fieldErrors"
            outlined
            clearable
            dense
            append-icon="mdi-calendar-range"
            @click:clear="clearField"
            @click:append="on.click"
            @click.stop="on.click"
            v-bind="attrs"
            readonly
            :disabled="computedSettings.readOnly"
          />
        </template>
        <v-date-picker
          v-model="low"
          :locale="$locale.current"
          :min="lowMin"
          :max="lowMax"
          :first-day-of-week="1"
          no-title
        />
        <v-date-picker
          v-model="high"
          :locale="$locale.current"
          :min="highMin"
          :max="highMax"
          :first-day-of-week="1"
          no-title
        />
      </v-menu>
    </eva-field-wrapper>
  </div>
</template>

<script>
import { isNil } from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import { formatDateTime, formatDateToUnix } from '../datetime/formatters';

export default {
  name: 'eva-range-date-field',

  data() {
    return {
      inputKey: uuid(),
      calendarFormat: 'YYYY-MM-DD',

      format: 'DD.MM.YYYY',
    };
  },

  computed: {
    computedSettings() {
      return {
        ...this.settings,
        name: this.settings.name,
        low: {
          name: this.settings.low && this.settings.low.name || `${this.settings.name}Low`,
          max: !isNil(this.settings.low.max)
            ? Math.max(this.settings.low.max, this.model[this.settings.high.name || `${this.settings.name}High`])
            : this.model[this.settings.high.name || `${this.settings.name}High`],
          min: this.settings.low.min,
          startDate: true,
        },
        high: {
          name: this.settings.high && this.settings.high.name || `${this.settings.name}High`,
          max: this.settings.high.max,
          min: !isNil(this.settings.high.min)
            ? Math.min(this.settings.high.min, this.model[this.settings.low.name || `${this.settings.name}Low`])
            : this.model[this.settings.low.name || `${this.settings.name}Low`],
          startDate: false,
        },
        readOnly: this.settings.readOnly,
      }
    },

    low: {
      get() {
        let value = this.model[this.computedSettings.low.name];
        if (value != null) {
          return moment.utc(value, 'x').format('YYYY-MM-DD');
        } else {
          return '';          
        }
      },
      set(value) {
        if (value) {
          if (this.computedSettings.low.startDate) {
            this.$set(this.model, this.computedSettings.low.name, formatDateToUnix(moment.utc(
              `${value} 00:00:00`,
              `${this.calendarFormat} HH:mm:ss`
            )));
          } else {
            this.$set(this.model, this.computedSettings.low.name, formatDateToUnix(moment.utc(
              `${value} 23:59:59`,
              `${this.calendarFormat} HH:mm:ss`
            )));
          }
        } else {
          this.$set(this.model, this.computedSettings.low.name, null);
        }
      },
    },
    high: {
      get() {
        let value = this.model[this.computedSettings.high.name];
        if (value != null) {
          return moment.utc(value, 'x').format('YYYY-MM-DD');
        } else {
          return '';          
        }
      },
      set(value) {
        if (value) {
          if (this.computedSettings.high.startDate) {
            this.$set(this.model, this.computedSettings.high.name, formatDateToUnix(moment.utc(
              `${value} 00:00:00`,
              `${this.calendarFormat} HH:mm:ss`
            )));
          } else {
            this.$set(this.model, this.computedSettings.high.name, formatDateToUnix(moment.utc(
              `${value} 23:59:59`,
              `${this.calendarFormat} HH:mm:ss`
            )));
          }
        } else {
          this.$set(this.model, this.computedSettings.high.name, null);
        }
      },
    },
    dates() {
      if (this.low || this.high) {
        return `${this.low? formatDateTime(this.model[this.computedSettings.low.name], this.format): ''} - ${this.high? formatDateTime(this.model[this.computedSettings.high.name], this.format): ''}`;
      }
    },
    lowMin() {
      const min = this.computedSettings?.low?.min;
      const lowestMin = moment('01-01-1900', 'DD-MM-YYYY').valueOf();
      if (!min) {
        return lowestMin.toString();
      }
      return this.formatEnteredDate(min > lowestMin ? min : lowestMin);
    },
    lowMax() {
      const max = this.computedSettings?.low?.max;
      const highestMax = moment('01-01-2100', 'DD-MM-YYYY').valueOf();
      if (!max) {
        return highestMax.toString();
      }
      return this.formatEnteredDate(max < highestMax ? max : highestMax);
    },
    highMin() {
      const min = this.computedSettings?.high?.min;
      const lowestMin = moment('01-01-1900', 'DD-MM-YYYY').valueOf();
      if (!min) {
        return lowestMin.toString();
      }
      return this.formatEnteredDate(min > lowestMin ? min : lowestMin);
    },
    highMax() {
      const max = this.computedSettings?.high?.max;
      const highestMax = moment('01-01-2100', 'DD-MM-YYYY').valueOf();
      if (!max) {
        return highestMax.toString();
      }
      return this.formatEnteredDate(max < highestMax ? max : highestMax);
    },
  },

  mounted() {
    this.setValue();
  },

  methods: {
    // todo: rename or remove
    formatEnteredDate(newDate) {
      if (isNil(newDate)) {
        return;
      }
      const date =
        moment.utc(parseInt(newDate)).isValid()
          ? moment.utc(parseInt(newDate), 'x')
          : undefined;
      if (isNil(date)) {
        return;
      }
      return formatDateTime(date, this.calendarFormat);
    },
    setValue() {
      const lowValue = this.model[this.settings.low.name];
      this.low = lowValue ? formatDateTime(lowValue, this.format) : '';
      const highValue = this.model[this.settings.high.name];
      this.high = highValue ? formatDateTime(highValue, this.format) : '';
      this.inputKey = uuid();
    },
    clearField() {
      this.$set(this.model, this.settings.low.name, '');
      this.low = null;
      this.$set(this.model, this.settings.high.name, '');
      this.high = null;
    }
  },
}
</script>

<style lang="less">
.eva-range-date-field {
  display: flex;
  gap: @eva-padding;
}
</style>
