<template>
  <div
      class="eva-date-table-cell"
      :class="{ 'eva-text-clipped': isClipped }"
  >
    <span v-tooltip="$eva.$tools.tooltipContent(currentDateValue)">
      {{ currentDateValue }}
    </span>
  </div>
</template>

<script>
import EvaTableCellMixin from "./EvaTableCellMixin";
import moment from 'moment';

export default {
  name: 'eva-date-table-cell',

  mixins: [
    EvaTableCellMixin
  ],

  props: {
    format: {
      type: String
    },
    utc: {
      type: Boolean
    }
  },

  computed: {
    currentDateValue() {
      let result = this.currentValue;
      return result
          ? this.column.utc
              ? moment.utc(result, 'x').format(this.format || 'DD.MM.YYYY')
              : moment(result, 'x').format(this.format || 'DD.MM.YYYY')
          : '';
    }
  }
}
</script>

<style lang="less">
.eva-date-table-cell {

}
</style>
