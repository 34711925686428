<template>
  <span
      class="eva-data-name"
      :class="classes"
      :style="styles"
  >
    {{ name }}
  </span>
</template>

<script>
export default {
  name: 'eva-data-name',

  props: {
    value: {
      type: String
    },
    color: {
      type: String
    },
    clipped: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    hasName() {
      return !!(this.value || '').toString().trim();
    },
    name() {
      let name = (this.value || '').toString().trim() || '$t.core.common.noName';
      return this.$eva.$t(name);
    },
    classes() {
      return {
        'eva-text-clipped': this.clipped,
        'eva-text-italic': !this.hasName
      }
    },
    styles() {
      return {
        color: this.color
      }
    }
  }
}
</script>

<style lang="less">
.eva-data-name {
  width: 100%;
  word-break: break-word;
}
</style>
