import { reactive } from "vue";

class EvaFullscreenController {
  constructor() {
    this.state = reactive({
      isFullscreen: false,
    });
  }
  use({ app, container, parent, video }) {
    this.app = app;
    this.container = container;
    this.parent = parent;
  }

  async toggleFullscreen() {
    try {
      if (this.state.isFullscreen) {
        await this.app.$tools.clearPortal();
        this.parent.appendChild(this.container);
      } else {
        await this.app.$tools.toPortal(this.container);
      }
      this.state.isFullscreen = !this.state.isFullscreen;
    } catch (error) {
      this.app.$logs.error("EvaFullscreenController", "fullscreen", error);
    }
  }
}

export default EvaFullscreenController;
