<template>
  <eva-combobox
    v-show="!isHide"
    v-model="modelValue"
    :label="fieldLabel"
    :error="fieldErrors"
    :title="fieldTitle"
    :readonly="readOnly"
  >
    <div>{{ test }}</div>
  </eva-combobox>
</template>

<script>
export default {
  name: 'eva-combobox-field',

  data() {
    return {
      test: 1
    }
  },

  computed: {

  },

  watch: {

  },

  methods: {

  },

  mounted() {
    setInterval(() => this.test+= 1, 1000)
  }
}
</script>

<style lang="less">
.eva-select-field {
  .v-input__slot {
    background-color: #E5F1FF!important;
  }
}
</style>
