<template>
  <eva-popup-input
      :label="label"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :icon="icon"
      :clearable="clearable"
      :error="error"
      class="eva-combobox"
      @clear="currentValue = ''"
      @icon-click="onIconClick"
  >
    <template v-slot:header>
      <slot name="header"></slot>
    </template>
  </eva-popup-input>
</template>

<script>
import EvaInputMixin from "./EvaInputMixin";

export default {
  name: 'eva-combobox',

  mixins: [
    EvaInputMixin
  ],

  data() {
    return {
      test: 1
    }
  },

  methods: {
    onIconClick(e) {
      this.$emit('icon-click', e);
    }
  },

  mounted() {
    setTimeout(() => this.test+= 1, 1000)
  }
}
</script>

<style lang="less">
.eva-combobox {

}
</style>
