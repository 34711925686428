<template>
  <eva-layout column scroll :class="$options.name">
    <ul v-if="currentItems">
      <li
        v-for="item in currentItems"
        :key="item.id"
        :class="selectedItem && selectedItem.id === item.id ? 'eva-select-popup__selected' : ''"
        @click="onClick(item)"
      >
        {{ item.name }}
      </li>
    </ul>
  </eva-layout>
</template>

<script>
export default {
  name: 'eva-select-popup',

  props: {
    value: {
      default: null
    },
    select: {
      type: Function,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: null
    },
    box: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      currentItems: [],
      selectedItem: null
    }
  },

  watch: {
    value: {
      handler(value) {
        this.selectedItem = value;
      },
      immediate: true
    },
    url() {
      this.loadCurrentItems();
    },
    items() {
      this.loadCurrentItems();
    }
  },

  methods: {
    async loadCurrentItems() {
      if (this.items) {
        this.currentItems = this.items;
      } else if (this.url) {
        this.currentItems = this.$eva.$http.getListItems(this.url);
      } else {
        this.currentItems = null;
      }
    },
    onClick(value) {
      this.select(value);
      this.box.hide();
    }
  },

  mounted() {
    this.loadCurrentItems();
  }
}
</script>

<style lang="less">
.eva-select-popup {
  display: flex;
  flex-direction: column;
  padding: @eva-padding 0;
  max-height: 300px;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      height: 28px;
      line-height: 28px;
      cursor: pointer;
      padding: 0 (@eva-padding * 2);
      &:hover {
        background-color: #0260CF9F;
      }
      &.eva-select-popup__selected {
        background-color: #0260CF;
      }
    }
  }
}
</style>
