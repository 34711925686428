<template>
  <div class="eva-interval-date-field">
    <eva-date-field
      :settings="lowSettings"
      :form-settings="formSettings"
      :model="model"
      :validators="validators"
      :disabled="readOnly"
      @changed="onLowChanged"
    />
    <eva-date-field
      :settings="highSettings"
      :form-settings="formSettings"
      :model="model"
      :validators="validators"
      :disabled="readOnly"
      ref="high"
    />
  </div>
</template>

<script>
import { isNil } from "lodash";
import EvaDateField from "../datetime/dateField";

const DAY_MILLISECONDS = (86400 - 1) * 1000;

export default {
  name: 'eva-interval-date-field',

  components: {
    EvaDateField,
  },

  computed: {
    lowSettings() {
      return {
        ...this.settings,
        name: this.settings.low && this.settings.low.name || `${this.settings.name}Low`,
        max: this.settings.singleDate ? null :
          !isNil(this.settings.low.max)
            ? Math.max(this.settings.low.max, this.model[this.settings.high.name || `${this.settings.name}High`])
            : this.model[this.settings.high.name || `${this.settings.name}High`],
        min: this.settings.low.min,
        startDate: true,
        readOnly: this.settings.readOnlyDateStart != null ? this.settings.readOnlyDateStart : this.settings.readOnly
      }
    },
    highSettings() {
      return {
        ...this.settings,
        name: this.settings.high && this.settings.high.name || `${this.settings.name}High`,
        max: this.settings.high.max,
        min: this.settings.singleDate ? null :
          !isNil(this.settings.high.min)
            ? Math.min(this.settings.high.min, this.model[this.settings.low.name || `${this.settings.name}Low`])
            : this.model[this.settings.low.name || `${this.settings.name}Low`],
        startDate: false,
        readOnly: this.settings.singleDate || (this.settings.readOnlyDateEnd != null ? this.settings.readOnlyDateEnd : this.settings.readOnly)
      }
    },
  },

  methods: {
    onLowChanged(value) {
      if (this.settings.singleDate === true) {
        this.$nextTick(() => {
          this.$set(this.model, this.$refs.high.settings.name, value ? (value + DAY_MILLISECONDS) : null);
          this.$nextTick(() => {
            this.$refs.high.setValue();
          });
        });
      }
    }
  }
}
</script>

<style lang="less">
.eva-interval-date-field {
  display: flex;
  gap: 20px;
}
</style>
