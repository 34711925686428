import EvaWebrtcVideoSource from "./EvaWebrtcVideoSource";

class EvaWebrtcLifeVideoSource extends EvaWebrtcVideoSource {
  constructor(source, paused, settings) {
    super(source, paused, settings);
  }

  getDefaultSupports() {
    return {
      screenshot: true,
      fullscreen: true,
      fit: true,
      play: true,
      pause: true,
    };
  }

  async getChannel() {
    const stream = await this.app.$http.get(
      `/api/v1/videohubservice/videostream/${this.source.stream_id}`
    );
    return await this.app.$http.get(
      `/api/v1/videohubservice/integrationapi/${stream.ref_channels[0].id}`
    );
  }
}

export default EvaWebrtcLifeVideoSource;
