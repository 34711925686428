<template>
  <div class="eva-checkbox-field-legacy">
    <eva-field-wrapper :title="fieldTitle">
      <v-switch
        v-model="model[settings.name]"
        :label="fieldLabel"
        :error="hasErrors"
        :messages="fieldErrors"
        :disabled="readOnly"
        :true-value="trueValue"
        :false-value="falseValue"
      />
    </eva-field-wrapper>
  </div>
</template>

<script>
export default {
  name: 'eva-checkbox-field',

  computed: {
    isRevert() {
      return this.settings && this.settings.revert === true;
    },
    falseValue() {
      return this.isRevert ? true : false;
    },
    trueValue() {
      return this.isRevert ? false : true;
    }
  }

}
</script>

<style lang="less">
.eva-checkbox-field-legacy {
  .v-input--selection-controls {
    margin-top: 0!important;
  }
}
</style>
