import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import EvaLoaderDefault from './components/EvaLoaderDefault.vue';
import EvaLoaderBlueWhite from './components/EvaLoaderBlueWhite.vue';

let loader = null;
class LoaderEvaPlagin extends EvaPlugin {
  constructor(app) {
    super(app);
  }

  install() {
    this.app.components(
      EvaLoaderDefault,
      EvaLoaderBlueWhite
    );
  }

  showLoader(style='eva-loader-default') {
    loader = this.app.$tools.appendComponent({
      component: style
    })
  }

  hideLoader() {
    this.app.$tools.dismountComponent(loader);
  }


}

export default LoaderEvaPlagin;
