import StyleField from "./StyleField";

class SizeStyleField extends StyleField {

  constructor(value) {
    super(value);
  }

  initialize(value) {
    let parts = value.split(/(?<=\d)(?=\D)|(?=\d)(?<=\D)/ig);
    this.value = parseInt(parts[0]);
    this.unit = parts[1];
  }

  formatValue(value = undefined) {
    return `${value == null ? this.value : value}${this.unit}`;
  }
}

export default SizeStyleField;
