<template>
  <div class="eva-confirm-component">
    {{ $eva.$t(message, messageArgs) }}
  </div>
</template>

<script>
export default {
  name: 'eva-confirm-component',

  props: {
    message: {

    },
    messageArgs: {

    }
  }
}
</script>

<style lang="less">
.eva-confirm-component {
  padding: @eva-padding * 2 @eva-padding * 2;
  padding-bottom: @eva-padding;
}
</style>
