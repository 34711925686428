<template>
  <eva-input
    :label="label"
    :placeholder="placeholder"
    :icon="icon"
    :clearable="clearable"
    :error="error"
    :depth="depth"
    @clear="currentValue = ''"
    class="eva-jsonarea"
  >
    <pre v-if="readonly" class="eva-padding" style="overflow-x: auto; height: 100%">{{JSON.stringify(currentValue, null, 4)}}</pre>
    <vue-json-editor
      v-else
      ref="json_editor"
      :value="currentValue"
      mode="code"
      :modes="['code']"
      :show-btns="false"
      :exapndedOnStart="true"
      class="eva-jsonarea--editor"
      @json-change="currentValue = $event"
      @has-error="$emit('json-error', $event.message)"
    />
  </eva-input>
</template>

<script>
import EvaInputMixin from "./EvaInputMixin";
import vueJsonEditor from "vue-json-editor";

export default {
  name: 'eva-jsonarea',

  mixins: [
    EvaInputMixin
  ],

  components: {
    vueJsonEditor
  }
}
</script>

<style lang="less">
.eva-jsonarea {
  min-height: 300px;
  .eva-textbox__inner{
    padding-left: 1px;
  }
  .eva-textbox__input {
    height: unset;
  }
  &.eva-textbox--error {
    .eva-textbox__inner:hover {
      .eva-jsonarea--editor {
        .ace_scroller {
          background-color: #E5F1FF!important;
        }
        .ace_gutter {
          background-color: #d2e7ff!important;
        }
        .ace_gutter-active-line {
          background-color: #bedcff!important;
        }
      }
    }
    .eva-jsonarea--editor {
      .ace_scroller {
        background-color: #FFF9F9 !important;
      }
      .ace_gutter {
        background-color: #ffe5e5 !important;
      }
      .ace_gutter-active-line {
        background-color: #ffd1d1 !important;
      }
    }
  }
  .eva-jsonarea--editor {
    height: 100%;
    width: 100%;
    z-index: 1;
    .ace_scroller {
      background-color: #E5F1FF!important;
    }
    .ace_gutter {
      background-color: #d2e7ff!important;
    }
    .ace_gutter-active-line {
      background-color: #bedcff!important;
    }
    .jsoneditor-menu {
      display: none;
    }
    .jsoneditor {
      border: none;
    }
    .jsoneditor-vue {
      height: 100%;
    }
    .jsoneditor-tree{
      min-height: 100%;
    }
    .jsoneditor-outer{
      min-height:100%;
      margin: 0;
      padding: 0;
    }
    .jsoneditor-readonly {
      display: none;
    }
  }
}
</style>
