<template>
  <div
    class="eva-repeater-header"
    :class="getClass"
  >

    <eva-text v-if="showCaption" header :text="caption" class="eva-repeater-header__caption"/>
    <eva-text subheader :position="settings.subheaderPosition">
      <slot name="subheader"/>
    </eva-text>

    <eva-layout v-if="showTools" row transparent class="eva-repeater-header__tools">

      <slot name="context">
        <eva-textbox
          v-if="showContext"
          v-model="context"
          placeholder="Поиск"
          icon="mdi-magnify"
          :depth="getSearchDeth"
          class="eva-repeater-header__context"
        />
      </slot>

      <eva-command-list
        type="icon--outlined--secondary"
        :commands="options.filterCommands"
        class="eva-repeater-header__filter"
      />

      <slot/>

      <eva-spacer/>

      <span
        v-if="showDescription"
        v-tooltip="$eva.$tools.tooltipContent(settings.description)"
        class="eva-repeater-header__icon eva-textbox__button mdi mdi-information-outline"
      >
      </span>

      <eva-command-list
        type="text--secondary"
        :commands="options.listCommands"
        class="eva-repeater-header__list"
        ref="listCommands"
      />
    </eva-layout>
  </div>
</template>

<script>
export default {
  name: 'eva-repeater-header',

  props: {
    settings: {
      type: Object
    },
    options: {
      type: Object
    }
  },

  data() {
    this.onContextChanged = this.$eva.$tools.debounce(() => {
      const context = (this.context || '').toString().trim();
      if ((
        !this.settings.filter ||
        !this.settings.filter.startSearch
      ) || (
        Number.isFinite(this.settings.filter.startSearch) &&
        this.settings.filter.startSearch <= context.length ||
        context.length === 0
      )) {
        this.options.filter.context = context;
        this.options.filter.offset = 0;
      }
    });
    return {
      context: '',
      configuration: {}
    };
  },

  computed: {
    getClass() {
      return `eva-background-${
        Number.isFinite(this.settings.depth) ? this.settings.depth : 2}`;
    },
    caption() {
      return typeof this.settings.caption === 'string'
        ? this.$eva.$t(this.settings.caption)
        : `${this.$eva.$t(`$t.${this.settings.prefix}.caption`)}`;
    },
    showCaption() {
      return this.settings.caption !== false;
    },
    showContext() {
      return this.settings.context !== false;
    },
    showFilter() {
      return this.settings.filter !== false;
    },
    showTools() {
      return this.settings.tools !== false;
    },
    showDescription() {
      return this.settings.description && this.settings.description.length;
    },
    showConfiguration() {
      // return this.settings.report;
      return true;
    },
    getSearchDeth() {
      return this.settings.filter && Number.isFinite(this.settings.filter.depth)
        ? this.settings.filter.depth
        : 1;
    }

  },

  watch: {
    context() {
      this.onContextChanged();
    }
  }
}
</script>

<style lang="less">
.eva-repeater-header {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  padding: @eva-padding (@eva-padding * 2);

  .eva-repeater-header__list {
    .eva-btn {
      /*max-width: 350px;*/
    }
  }

  .eva-repeater-header__caption {
    margin: @eva-padding 0;
  }

  .eva-repeater-header__tools {
    height: @eva-header;
    align-items: center;
    gap: (@eva-padding * 2);
  }

  .eva-repeater-header__context {
    max-width : 200px;
  }

  .eva-repeater-header__icon {
    height: 40px;
    width: 40px;
    font-size: 40px;
  }
}
</style>
