<template>
  <div class="eva-video__header-clock ml-2" ref="evaClock"></div>
</template>

<script>
import moment from "moment";

export default {
  name: "eva-video-header-clock",
  methods: {
    setTime() {
      const clock = this.$refs.evaClock;
      setInterval(() => {
        clock.textContent = moment().format("HH:mm:ss");
      }, 100);
    },
  },
  async mounted() {
    this.setTime();
  },
};
</script>
<style lang="less">
.eva-video__header-clock {
  text-align: center;
}
</style>
