<template>
  <button
    class="eva-btn"
    :class="buttonClasses"
    :disabled="isDisable"
    :style="buttonStyles"
    v-tooltip="$eva.$tools.tooltipContent(currentTooltip)"
    @click.stop.prevent="onClick($event)"
    @mousedown.stop.prevent
  >
    <span v-if="hasIcon" class="eva-btn__icon mdi" :class="currentIcon"></span>
    <span v-if="!isIcon" class="eva-text-clipped">{{ currentLabel }}</span>
    <span v-if="showBadge" class="eva-btn--badge"></span>
    <eva-spacer v-if="hasAction"/>
    <a v-if="hasAction" class="eva-margin-2-left" @click.stop.prevent="onAction($event)">{{ command.action.header }}</a>
  </button>
</template>

<script>
export default {
  name: 'eva-btn',

  props: {
    type: {
      type: String
    },
    label: {
      type: String | Number
    },
    tooltip: {
      type: String
    },
    icon: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    badge: {
      type: Boolean
    },
    color: {
      type: String
    },
    command: {
      type: Object
    }
  },

  computed: {
    currentType() {
      let type = this.getProperty('type');
      if (type) {
        let parts = type.split('--');
        return parts[0];
      }
      let switchType = (this.getProperty('icon') ? '1' : '0') + (this.getProperty('label') ? '1' : '0');
      switch (switchType) {
        case '10':
          return 'icon';
        case '11':
          return 'icon-text';
        case '01':
          return 'text';
        default:
          return 'icon';
      }
    },
    isIcon() {
      return this.currentType === 'icon';
    },
    hasIcon() {
      return this.currentType && this.currentType.indexOf('icon') >= 0;
    },
    currentIcon() {
      return this.getProperty('icon');
    },
    currentLabel() {
      return this.$eva.$t(this.getProperty('label'));
    },
    buttonClasses() {
      let [ ,...modifiers] = (this.getProperty('type') || '').split('--');
      return modifiers.map((m) => `eva-btn--${m}`).join(' ');
    },
    buttonStyles() {
      return {
        color: this.getProperty('color')
      };
    },
    isDisable() {
      return this.getProperty('disabled') === true;
    },
    showBadge() {
      return this.getProperty('badge') === true;
    },
    currentTooltip() {
      return this.tooltip
          ? this.tooltip
          : (this.isIcon && this.currentLabel)
              ? this.currentLabel
              : null;
    },
    hasAction() {
      return this.command && this.command.action
    },
  },

  methods: {
    getProperty(name) {
      if (this.command && this.command[name] != null) {
        return this.command[name];
      }
      return this[name];
    },
    onClick(e) {
      if (!this.isDisable && !this.disabled && !this.command?.disabled) {
        this.$emit('click', e);
      }
    },
    onAction(e) {
      this.command.action.handler();
    }
  }
}
</script>

<style lang="less">
.eva-btn {
  position: relative;
  a {
    text-decoration: underline;
  }
  .eva-btn--badge {
    position: absolute;
    border-radius: 50%;
    background-color: #0260CF;
    /*background-color: #0260CF;*/
    top: 4px;
    right: 4px;
    width: 10px;
    height: 10px;
  }
  &[disabled] {
    .eva-btn--badge {
      background-color: #0260CF;
      /*background-color: #F7F8FF;*/
    }
  }
}
</style>
