<template>
  <div class="eva-map eva-background-0" :class="mapClasses">

    <div class="eva-map__canvas" :id="state.id" @dragover="onDragOver" @drop="onDrop"></div>

    <eva-map-common-panel v-if="commonPanel" :state="state" :hide-mapsource-views="hideMapsourceViews"/>

    <eva-map-draw-styles-panel top right v-if="!readonly && !!state.mapObjectId" :state="state"/>

    <eva-map-view-panel :state="state" :homeCommand="homeCommand"/>

    <eva-map-tools-panel bottom left v-if="pathPanel && state.mapObject && state.mapObject.path && state.mapObject.path.length" class="eva-map-path-panel">
      <template v-for="(pathItem, pathItemIndex) in state.mapObject.path">
        <a
            v-if="pathItem && pathItem.ref_mapsource && pathItem.ref_mapsource.id"
            :key="pathItem.id"
            v-tooltip="$eva.$tools.tooltipContent(pathItem.name)"
            class="eva-text-clipped"
            @click="state.initFromMapObject(pathItem.id)"
        >
          {{ pathItem.name }}
        </a>
        <span
            v-else
            class="eva-text-clipped"
            v-tooltip="$eva.$tools.tooltipContent(pathItem.name)"
            :style="{ flexShrink: pathItemIndex === state.mapObject.path.length - 1 ? 0 : 1 }"
        >
          {{ pathItem.name }}
        </span>
        <span v-if="pathItemIndex < state.mapObject.path.length - 1">/</span>
      </template>
    </eva-map-tools-panel>

    <span
        v-if="!!state.tooltip"
        class="eva-map__tooltip"
        :style="{ left: (state.tooltip.x + 35) + 'px', top: state.tooltip.y + 'px' }"
    >
      {{ state.tooltip.message }}
    </span>

    <span
        v-if="!!state.group"
        class="eva-map__group eva-background-3"
        :style="{ left: (state.group.x - 16) + 'px', top: (state.group.y + 32) + 'px' }"
    >
      <span
          v-for="feature in state.group.features"
          :style="{ backgroundColor: feature.color || feature.extraColor }"
          :class="isSelected(feature) ? 'eva-map__group--selected' : ''"
          v-tooltip="$eva.$tools.tooltipContent(feature.name)"
          @click.stop="onGroupClick(feature)"
          @contextmenu="showContextMenu(feature)"
      >
        <img :src="feature.src"/>
      </span>
    </span>

    <eva-progress v-if="state.isLoading"/>
  </div>
</template>

<script>
import EvaMapToolsPanel from "./tools/EvaMapToolsPanel";
import EvaMapCommonPanel from "./tools/EvaMapCommonPanel";
import EvaMapViewPanel from "./tools/EvaMapViewPanel";
import EvaMapDrawStylesPanel from "./tools/EvaMapDrawStylesPanel";
import createEvaMapState from "./EvaMapState";

export default {
  name: 'eva-map',

  model: {
    prop: 'selectedId',
    event: 'selectedId:update'
  },

  components: {
    EvaMapToolsPanel,
    EvaMapCommonPanel,
    EvaMapViewPanel,
    EvaMapDrawStylesPanel
  },

  props: {
    selectedId: {
      type: String
    },
    mapObjectId: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    blinkOnAlarm: {
      type: Boolean,
      default: false
    },
    commonPanel: {
      type: Boolean,
      default: true
    },
    pathPanel: {
      type: Boolean,
      default: true
    },
    homeCommand: {
      type: Object
    },
    hideMapsourceViews: {
      type: Boolean,
      default: false
    },
    hideGroups: {
      type: Boolean,
      default: false
    },
    isCustomObject: {
      type: Boolean,
      default: false,
    },
    incidentContextMenuOptions: {
      type: Object,
      default: () => ({
        show: false,
        create: false,
        remove: false,
      }),
    },
    incidentAdditionalSources: {
      type: Object,
      default: () => {},
    }
  },

  data() {
    return {
      state: createEvaMapState(
        this.$eva,
        this.readonly,
        this.blinkOnAlarm,
        this.hideGroups,
        this.isCustomObject,
        this.incidentContextMenuOptions,
        this.incidentAdditionalSources?.items,
        this
      ),
    };
  },

  computed: {
    mapClasses() {
      return {
        'eva-map--loading': this.state.isLoading,
        'eva-map--fullscreen': this.state.isFullScreen
      }
    }
  },

  watch: {
    mapObjectId: {
      async handler(value) {
        if (!value || this.disableInitFromMapObject) {
          return;
        }
        try {
          this.disableInitFromMapObject = true;
          await this.state.initFromMapObject(value);
        } finally {
          this.disableInitFromMapObject = false;
        }
      },
      immediate: true
    },
    selectedId: {
      handler(value) {
        if (this.state) {
          this.state.selectedMonitoringObject = value;
        }
      },
      immediate: true
    },
    'state.selectedMonitoringObject': {
      handler(value) {
        this.$emit('selectedId:update', this.state.selectedMonitoringObject);
      },
      immediate: true
    },
    state: {
      handler() {
        this.$emit('state:changed');
      },
      deep: true
    },
    'state.hasHome'() {
      this.$emit('state:changed');
    },
    'state.selectedMapObjects': {
      handler() {
        this.$emit('state:changed');
      },
      deep: true
    },
    'state.isLoading'(value, oldValue) {
      if (oldValue && !value) {
        this.$nextTick(() => this.$emit('initialized'))
      }
    },
    'state.hasAlarm'(value) {
      this.$emit('alarm', value);
    }
  },

  methods: {
    async reloadItem(id) {
      if (!this.state) {
        return;
      }
      await this.state.reloadMonitoringObject(id);
    },
    async reloadCustom() {
      if (!this.state) {
        return;
      }
      await this.state.reloadCustom();
    },
    removeFromMap(ids) {
      if (!this.state) {
        return;
      }
      this.state.removeFromMap(ids);
    },
    showObject({ id, color, additionalObjects = [] } ) {
      this.$set(this.state, 'selectedMonitoringObject', id);
      this.state.showOnMap(id, color);
      this.setExtraColorForAdditionalObjects(additionalObjects);
    },
    showCustomOnMap({ color, additionalObjects = [] }) {
      this.state.showCustomOnMap(color);
      this.setExtraColorForAdditionalObjects(additionalObjects);
    },
    setExtraColor({id, color}) {
      this.state.setExtraColor(id, color);
    },
    setExtraColorForAdditionalObjects(additionalObjects = []) {
      additionalObjects.forEach((monitoringObject) => {
        this.setExtraColor({ id: monitoringObject.id, color: monitoringObject.color });
      });
    },
    goTo(id) {
      this.state.showOnMap(id);
    },
    async removeMapObjects() {
      if (!this.state) {
        return;
      }
      await this.state.removeMapObjects();
    },
    onDragOver(e) {
      if (
          !this.readonly &&
          this.state.mapSourceId &&
          !this.state.isLoading &&
          this.state.selectedDrawStyle) {
        this.$eva.$dragndrops.allowDrop(e, {'eva-map-object': true});
      }
    },
    async onDrop(e) {
      if (!this.state || !this.state.mapSourceId || this.readonly || this.state.isLoading || !this.state.selectedDrawStyle) {
        return;
      }
      let data = this.$eva.$dragndrops.endDrop(e, {'eva-map-object': true});
      data = data && data['eva-map-object'];
      if (!data) {
        return;
      }
      await this.state.addMapObject(data.id, e.offsetX, e.offsetY);
      this.$emit('coords:changed', data.id);
    },
    onGroupClick(feature) {
      this.state.selectMapObject(feature.id);
    },
    isSelected(feature) {
      return this.state.selectedMapObjects.indexOf(feature.id) >= 0;
    },
    async showContextMenu(feature) {
      if (this.incidentContextMenuOptions.show) {
        await this.state.showContextMenu(feature);
      }
    }
  },

  async beforeDestroy() {
    await this.state.clear();
  }
}
</script>

<style lang="less">
.eva-map {
  width: 100%;
  height: 100%;
  position: relative;
  /*background-color: #E5F1FF;*/

  .eva-map-path-panel {
    .eva-map-tools-panel__inner {
      padding: @eva-padding;
    }
  }

  .eva-map__tooltip {
    position: absolute;
    padding: @eva-padding;
    gap: @eva-padding;
    border-radius: 6px;
    border: 1px solid #BDBDBD;
    background: #BDBDBD;
    color: #3E4C5D;
    box-shadow: 0px 3px 10px 0px #3E4C5D;
    z-index: 99;
  }

  .eva-map__group {
    position: absolute;
    display: flex;
    padding: @eva-padding * 1.5;
    gap: @eva-padding;
    border-radius: 6px;
    border: 1px solid #576D88;
    background-color: #3E4C5D;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.26);
    z-index: 100;
    flex-wrap: wrap;
    max-width: 300px;
    span {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 4px;
      border-radius: 16px;
      overflow: hidden;
      z-index: 1;
      img {
        width: 24px;
        height: 24px;
      }
      &:hover {
        cursor: pointer;
        outline: 4px solid #ACBBCB;
      }
      &.eva-map__group--selected {
        outline: 8px solid #ACBBCB;
      }
    }
  }

  .eva-map__canvas {
    width: 100%;
    height: 100%;
  }
  
  &.eva-map--fullscreen {
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 199;
  }

  &.eva-map--loading {
    .eva-map__canvas {
      opacity: 0;
    }
  }
}
</style>
