<template>
  <div v-show="!isHide" class="eva-file-attach-field">
    <div class="eva-file-attach-field-files">
      <eva-text v-if="fieldLabel" header :text="fieldLabel" />
      <div v-if="!preview">
        <template v-for="(file, index) in modelValue">
          <div v-if="!checkType(file.type, 'image')" class="eva-file-attach-field-files-file" :key="file.name">
            <div class="eva-file-attach-field-files-file-name eva-foreground-2">
              <div class="blockIcon">
                <span class="mdi mdi-file-document" />
              </div>
              <div v-if="!preview" class="blocktext">
                {{ `${file.name}` }}
              </div>
            </div>
            <div v-if="!fileDeleted" class="closeBtn" @click="deleteFile(index)">
              <span class="mdi mdi-close" />
            </div>
          </div>
        </template>
      </div>

      <eva-file-viewer v-if="preview" :dataModel="modelValue" :label="''" />

      <div class="eva-file-attach-field-files-images">
        <template v-for="(file, index) in modelValue">
          <div v-if="checkType(file.type, 'image')" class="eva-file-attach-field-files-file">
            <img :key="file.name" :ref="`file-${file.name}`" v-tooltip="$eva.$tools.tooltipContent(file.name)"
              @click="showImageDialog(file)" />
            <div v-if="!preview" class="eva-file-attach-field-files-file-delete-btn" @click="deleteFile(index)">
              <span class="mdi mdi-close" />
            </div>
          </div>
        </template>
      </div>
    </div>

    <eva-btn v-if="!preview" class="eva-file-attach-field-btn" type="text-icon--secondary"
      :label="$eva.$t(`$t.${$options.name}.attach`)" icon="mdi-paperclip" @click="attachFiles" />

    <input class="eva-display-none" ref="uploader" name="file" type="file" multiple :accept="acceptTypes"
      :size="fileSize" @change="upload($event.target.files)" />
  </div>
</template>

<script>
import EvaFileAttachFieldPreviewImageDialog from "./EvaFileAttachFieldPreviewImageDialog";

export default {
  name: "eva-file-attach-field",

  components: {
    EvaFileAttachFieldPreviewImageDialog,
  },

  data() {
    return {
      acceptTypes: "*",
      fileLimit: 10,
      fileSize: 104857600,
      filesSizeText: "100 МБ",
      fileDeleted: false,
    }; //104857600byte 100mb
  },

  mounted() {
    ["acceptTypes", "fileLimit", "fileSize", "fileDeleted"].forEach((key) => {
      if (this.settings[key]) {
        this.$set(this.$data, key, this.settings[key]);
      }
    });
    /*     if(this.modelValue?.length){
          this.$set(this.$data, 'files', this.modelValue);
        } */
  },

  computed: {
    getBtnType() {
      return this.settings &&
        this.settings.button_settings &&
        this.settings.button_settings.type
        ? this.settings.button_settings.type
        : "text";
    },
    getBtnIcon() {
      return this.settings &&
        this.settings.button_settings &&
        this.settings.button_settings.icon
        ? this.settings.button_settings.icon
        : "mdi-paperclip";
    },
  },

  methods: {
    attachFiles() {
      this.$refs.uploader.click();
    },
    checkType(fileType, checkType) {
      return fileType.includes(checkType);
    },
    getFileIcon(fileType) {
      return "mdi-file-document";
    },
    getFileSizeString(file) {
      let size = Number.parseFloat(file.size / 1024).toFixed(1);
      let sizeType = "Кб";

      if (size / 1024 > 1) {
        size = Number.parseFloat(size / 1024).toFixed(1);
        sizeType = "Мб";
      }
      return `${size} ${sizeType}`;
    },
    renderFile(file) {
      this.$nextTick(() => {
        if (file.type.includes("image")) {
          let fr = new FileReader();
          const img = this.$refs[`file-${file.name}`][0];
          fr.onload = function () {
            img.src = fr.result;
          };
          fr.readAsDataURL(file);
        }
      });
    },
    async upload(files) {
      if (!this.modelValue) {
        this.$set(this, "modelValue", []);
      }

      if (files.length + this.modelValue.length <= this.fileLimit) {
        for (let i = 0; i < files.length; ++i) {
          const file = files[i];

          if (file.size > this.fileSize) {
            await this.$eva.$boxes.notifyError(
              `Файл - «${file.name}» весит ${this.geSizeFile(
                files[i].size
              )}, а допустимо не более ${this.filesSizeText}`
            );
          } else {
            this.modelValue.push(file);
            this.renderFile(file);
          }
        }
      } else {
        await this.$eva.$boxes.notifyError(
          `Вы можете загрузить не более ${this.fileLimit} файлов`
        );
      }
    },

    geSizeFile(bytes) {
      const mb = 1024 * 1024,
        gb = 1024 * 1024 * 1024;

      switch (true) {
        case (bytes < 1024):
          return `${bytes} байт`;
        case ((bytes > 1024) && (bytes < mb)):
          return `${(bytes / 1024).toFixed(2)} Кб`;
        case ((bytes > mb) && (bytes < gb)):
          return `${(bytes / mb).toFixed(2)} Мб`;
        case (bytes >= gb):
          return `${(bytes / gb).toFixed(2)} Гб`;
      }
    },

    async downloadFile(item) {
      try {
        const { data } = await this.$eva.$http.getBlob(
          `/api/v1/fileservice/file/${item.uploaded_file_id}`,
          "GET"
        ),
          file = data,
          link = document.createElement("a");
        link.href = URL.createObjectURL(file);
        link.download = item.name;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (err) {
        console.error("err, ", err);
        if (err?.response && err.response?.status === 403) {
          this.$eva.$boxes.notifyError(
            "Сессия закрылась, обновите страницу или авторизируйтесь и попробуйте еще раз."
          );
        } else {
          this.$eva.$boxes.notifyError(
            `Произошла ошибка, попробуйте еще раз. ${err.response?.data?.usr_msg}`
          );
        }
      }
    },

    showFile(file) {
      if (file.type === "application/pdf") {
        window.open(URL.createObjectURL(file), "_blank");
      } else {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(file);
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    async showImageDialog(file) {
      await this.$eva.$boxes.show({
        type: "dialog",
        header: file.name,
        component: EvaFileAttachFieldPreviewImageDialog,
        componentProps: { image: file },
      });
    },

    deleteFile(index) {
      this.modelValue.splice(index, 1);
      this.modelValue.forEach((file) => {
        this.renderFile(file);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.eva-file-attach-field {
  .blockIcon {
    font-size: 20px;
    width: 35px;
  }

  .blocktext {
    width: calc(100% - 100px);
    cursor: pointer;
  }

  .sizeFile {
    width: 65px;
    text-align: end;
  }

  & &-btn {
    width: 100%;
    margin-top: 12px;
  }

  & &-files {
    display: flex;
    flex-direction: column;

    & .eva-file-attach-field-files-file {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 5px 0px;

      .closeBtn {
        font-size: 1.2rem;
      }

      .closeBtn:hover {
        cursor: pointer;
      }

      & .eva-file-attach-field-files-file-name {
        display: flex;
        align-items: center;
        width: calc(100% - 65px);
      }
    }

    & .eva-file-attach-field-files-images {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      & .eva-file-attach-field-files-file {
        width: 100px;
        min-height: 76px;
        position: relative;

        & .eva-file-attach-field-files-file-delete-btn {
          position: absolute;
          right: -5px;
          top: -5px;
          border-radius: 50%;
          background-color: #495a6f;
          width: 34px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.2rem;
        }

        & .eva-file-attach-field-files-file-delete-btn:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>

<locale lang="ru">
  {
    attach: 'Прикрепить файл',
    errors: {
      file_limit: 'Достигнуто максимальное количество прикрепленных файлов',
      too_large_file: 'Слишком большой размер файла (Больше 10Мб)',
    }
  }
</locale>
