
    import localesHash from '@eva/client/plugins/locales/LocalesHash';
    export default function (Component) {
      localesHash.push({ 
        lang: 'ru', 
        data: {
          [Component.options.name]: 
{
  values: {
    true: 'Да',
    false: 'Нет',
  }
}

        } 
      });
    }