<template>
  <eva-layout column scroll class="eva-map-filter">
    <eva-checkbox v-model="state.isShowNames" :label="$eva.$t('$t.eva-map-filter.showNames')"/>
    <div class="eva-border-bottom"></div>
    <eva-checkbox
      v-for="(item, index) in layers"
      :key="index"
      v-model="item.isVisible"
      :label="$eva.$t(item.name)"
    />
  </eva-layout>
</template>

<script>


export default {
  name: 'eva-map-filter',

  props: {
    state: {
      type: Object
    }
  },

  computed: {
    layers() {
      return this.state.layers && this.$eva.$tools.orderBy(this.state.layers, 'name') || [];
    }
  }
}
</script>

<style lang="less">
.eva-map-filter {
  max-height: 380px;
  padding: @eva-padding * 2;
}
</style>

<locale lang="ru">
{
  showNames: 'Показывать названия'
}
</locale>
