import evaApp from "@eva/client/app/index";

const COMPONENT_ROUTE_TRIM_LEFT = [
  'eva'
];
const COMPONENT_ROUTE_TRIM_RIGHT = [
  'widget'
];

const DEFAULT_ITEM_ICON = 'mdi-passport-biometric';
const DEFAULT_GROUP_ICON = 'mdi-folder-outline';

class EvaRouteManager {
  constructor(app) {
    this.app = app;
    this.items = []
  }

  route(path, component, props) {
    let settings = component
      ? {path, component, props}
      : {path: '*', redirect: path};
    if (this.app.router.addRoute) {
      this.app.router.addRoute(settings);
    } else {
      this.app.router.addRoutes([settings]);
    }
    return this;
  }

  componentRoute(component) {
    this.items.push({
      type: 'item',
      component
    });
    let path = this.componentRoutePath(component);
    return this.route(`/${path}`, component);
  }

  componentRouteGroups(groups) {
    let first = null;
    Object.keys(groups).forEach((name) => {
      if (groups[name].routes.length) {
        if (!first) {
          first = this.componentRoutePath(groups[name].routes[0]);
        }
        this.componentRouteGroup(groups[name].name, ...groups[name].routes)
      }
    });
    if (first) {
      this.route(`/${first}`);
    }
  }

  componentRouteGroup(name, ...components) {
    if (typeof name !== 'string') {
      components.splice(0, 0, name);
      name = null;
    }
    this.items.push({
      name,
      type: 'group',
      items: components.map((component) => {
        let path = this.componentRoutePath(component);
        this.route(`/${path}`, component);
        return {
          type: 'item',
          component
        }
      })
    });
    return this;
  }

  componentRoutePath(component) {
    let path = component.name;
    for (let i = 0; i < COMPONENT_ROUTE_TRIM_LEFT.length; i++) {
      let trim = COMPONENT_ROUTE_TRIM_LEFT[i];
      if (path.startsWith(trim)) {
        path = path.substring(trim.length);
      }
    }
    for (let i = 0; i < COMPONENT_ROUTE_TRIM_RIGHT.length; i++) {
      let trim = COMPONENT_ROUTE_TRIM_RIGHT[i];
      if (path.endsWith(trim)) {
        path = path.substring(0, path.length - trim.length);
      }
    }
    path = path.replace(/-/g, '');
    return path;
  }

  async getRouteLinks(options = {}) {
    if (!options.type) {
      options.type = 'dividers';
    }
    let result = [];
    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i];
      switch (item.type) {
        case 'item':
          await this.setRouteItemLink(result, item);
          break;
        case 'group':
          await this.setRouteGroupLinks(options, result, item);
          break;
      }
    }
    return result;
  }

  async setRouteItemLink(result, item = {}) {
    let { component } = item;
    if (!component) {
      return false;
    }
    const disabled = component.settings?.license
      ? !(await evaApp.$tools.checkLicense(component.settings?.license))
      : false;

    result.push({
      name: `$t.${component.name}.caption`,
      to: `/${this.componentRoutePath(component)}`,
      icon: component.settings && component.settings.icon || DEFAULT_ITEM_ICON,
      disabled,
      license_error_message:
        component?.settings && component?.settings?.license_error_message
        || 'Данный раздел недоступен',
    });
    return true;
  }

  async setRouteGroupLinks(options, result, item = {}) {
    let { items } = item;
    if (!items || !items.length) {
      return false;
    }
    switch (options.type) {
      case 'dividers':
        let index = result.length;
        for (let i = 0; i < items.length; i++) {
          await this.setRouteItemLink(result, items[i]);
        }
        if (index < result.length && index) {
          result.splice(index, 0, {
            divider: true
          });
          return true;
        } else {
          return false;
        }
      case 'groups':
        let group = {
          name: item.name,
          icon: item.icon || DEFAULT_GROUP_ICON,
          items: []
        }
        for (let i = 0; i < items.length; i++) {
          await this.setRouteItemLink(group.items, items[i]);
        }
        if (group.items.length) {
          result.push(group);
          return true;
        } else {
          return false;
        }
    }
    return false;
  }
}

export default EvaRouteManager;
