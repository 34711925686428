import Vue from "vue";
import VueI18n from "vue-i18n";
import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import LocalesHash from "./LocalesHash";

import "moment/locale/ru";

const LOCALES = [
  'ru',
  'en'
];
const DEFAULT_LOCALE = 'ru';

class LocalesEvaPlugin extends EvaPlugin {

  constructor(app) {
    super(app);

    this.initReactive({
      data: {
        current: null
      },
      watch: {
        current(value) {
          this.$eva.$locales._i18n.locale = value;
          this.$eva.$context.updateTitle();
        }
      }
    });
  }

  install() {
    Vue.use(VueI18n);

    let messages = {};
    for (let i = 0; i < LOCALES.length; i++) {
      messages[LOCALES[i]] = {};
    }

    this._i18n = new VueI18n({
      locale: DEFAULT_LOCALE,
      fallbackLocale: DEFAULT_LOCALE,
      messages: messages,
      silentTranslationWarn: true
    });

    this.app.$t = (...args) => this.translate(...args);
    this.app.$tl = (...args) => this.translateLocale(...args);
    this.app.$te = (...args) => this.exists(...args);
    this.app.$ta = (...args) => this.translateAny(...args);
    this.app.$tal = (...args) => this.translateAnyLast(...args);

    function wrap(func, all) {
      return function (...args) {
        for (let i = 0; i < args.length; i++) {
          if (!all && i > 0) {
            break;
          }
          args[i] = args[i].replace('$t.$cur.', `$t.${this.$options.name}.`);
        }
        return func(...args);
      }
    }

    Vue.prototype.$$t = wrap((...args) => this.translate(...args), false);
    Vue.prototype.$$tl = wrap((...args) => this.translateLocale(...args), false);
    Vue.prototype.$$te = wrap((...args) => this.exists(...args), false);
    Vue.prototype.$$ta = wrap((...args) => this.translateAny(...args), true);
    Vue.prototype.$$tal = wrap((...args) => this.translateAnyLast(...args), true);
  }

  async init() {

    LocalesHash.forEach(({ lang, data }) => this.register(lang, data));

    this.current = DEFAULT_LOCALE;
  }

  register(locale, messages) {
    this._i18n.mergeLocaleMessage(locale, messages);
  }

  translate(key, ...params) {
    let res = this.prepareArgs(key, ...params);
    if (res) {
      return this._i18n.t(res.key, res.params);
    } else {
      return key;
    }
  }

  translateLocale(key, locale, ...params) {
    let res = this.prepareArgs(key, ...params);
    if (res) {
      return this._i18n.t(res.key, locale, res.params);
    } else {
      return key;
    }
  }

  translateAny(...keys) {
    for (let i = 0; i < keys.length; i++) {
      if (this.exists(keys[i])) {
        return this.translate(keys[i]);
      }
    }
    return '';
  }

  translateAnyLast(...keys) {
    for (let i = 0; i < keys.length; i++) {
      if (this.exists(keys[i]) || i === keys.length - 1) {
        return this.translate(keys[i]);
      }
    }
    return '';
  }

  exists(key, ...params) {
    let res = this.prepareArgs(key, ...params);
    if (res) {
      return this._i18n.te(res.key, res.params);
    } else {
      return false;
    }
  }

  prepareArgs(key, ...params) {
    key = (this.app.$tools.resolveValue(key) || '').toString();
    if (key.startsWith('$t.')) {
      params = params.map((p) => this.app.$tools.resolveValue(p));
      return {
        key: key.substring(3),
        params: typeof params[0] === 'object' ? params[0] : [...params]
      };
    } else {
      return null;
    }
  }

  toggle() {
    let index = LOCALES.indexOf(this.current);
    if (index < 0 || (index + 1) >= LOCALES.length ) {
      this.current = LOCALES[0];
    } else {
      this.current = LOCALES[index + 1];
    }
  }
}

export default LocalesEvaPlugin;
